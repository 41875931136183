import { Form } from "antd";
import { ClockCircleFilled } from "@ant-design/icons";
import Input from "component/admin/input";
import Radio from "component/admin/radio";
import { ButtonOutline, ButtonSolid } from "component/button";
import RadioGroup from "component/admin/radio/RadioGroup";
import { PaymentStyled } from "./styled";
import { Link, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import useService from "hook/useService";
import axiosClient from "api/axiosClient";
import { toast } from "react-toastify";
import NumericInput from "component/NumberInput";
import SelectWithData from "component/SelectWithData";
import { PatternFormat } from "react-number-format";
import MESSAGES from "constant/messages";
import { IDistrict } from "constant/interfaces";
import REGEX from "constant/regex";
import { useWatch } from "antd/es/form/Form";
import ROUTES from "constant/routes";

interface IPaymentProps {
  isStaff: boolean
}

export default function Payment({ isStaff }: IPaymentProps) {
  const { id } = useParams();
  const [form] = Form.useForm();
  const changeable_id = useWatch("id", form);
  const district = Form.useWatch("district", form);
  const bank_info_id = useWatch("bank_info_id", form);
  const city = Form.useWatch("city", form);
  const [isEdit, setIsEdit] = useState(!Boolean(id));
  const service = useService();
  const payment_method = Form.useWatch("payment_method", form);
  const payer = Form.useWatch("payer", form);
  const [submitting, setSubmitting] = useState(false)

  const fetchInitialData = async () => {
    try {
      form.resetFields();
      const { data } = await axiosClient({
        method: "get",
        url: service.PAYMENTS,
        params: { patient_id: id },
      });
      form.setFieldsValue(data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async (formData: any) => {
    setSubmitting(true)
    formData.patient_id = id;
    try {
      const res = await axiosClient({
        method: formData.id ? "put" : "post",
        url: service.PAYMENTS,
        data: formData,
      });
      toast.success(res.data.success);
      setIsEdit(false);
      fetchInitialData();
    } catch (error) {
      toast.error("Error");
    } finally {
      setSubmitting(false)
    }
  };

  const getDistrictOptions = async () =>
    (await axiosClient.get(service.DISTRICTS)).data.districts.map(
      (item: IDistrict) => ({ label: item.name, value: item.name })
    );

  const getCityOptions = async () =>
    (
      await axiosClient.get(service.CITIES, {
        params: { district_name: district },
      })
    ).data.cities.map((item: IDistrict) => ({
      label: item.name,
      value: item.name,
    }));

  const getAddressOptions = async () =>
    (
      await axiosClient.get(service.ADDRESSES, {
        params: { city_name: city, district_name: district },
      })
    ).data.addresses.map((item: IDistrict) => ({
      label: item.name,
      value: item.name,
    }));

  const getZipCodeByAddress = async (address: string) => {
    if (city && district && address) {
      const { data } = await axiosClient.get(service.POST_CODE, {
        params: {
          district_name: district,
          city_name: city,
          address_name: address,
        },
      });
      form.setFieldValue("zipcode", data.post_code);
    }
  };

  const getAddressByZipCode = async (zipcode: string) => {
    try {
      if (zipcode.replace("_", "").length === 8) {
        const { data } = await axiosClient.get(service.INFO_ADDRESS_BY_CODE, {
          params: {
            code: zipcode,
          },
        });
        form.setFieldValue("district", data.info_address.district.name);
        form.setFieldValue("city", data.info_address.city.name);
        form.setFieldValue("street", data.info_address.address.name);
      }
    } catch (error) {}
  };

  const getBankOptions = async (query: string) =>
    (
      await axiosClient.get(service.BANK_INFOS, {
        params: {
          q: query,
        },
      })
    ).data.bank_infos.map((info: any) => ({
      label: info.bank_name_search,
      value: info.bank_name_search,
      id: info.id,
    }));

  const getBranchOptions = async (query: string) =>
    (
      await axiosClient.get(service.BANK_INFOS + "/search-branchs", {
        params: {
          q: query,
          bank_info_id: bank_info_id,
        },
      })
    ).data.bank_infos.map((info: any) => ({
      label: info.branch_name_search,
      value: info.branch_name_search,
      id: info.id,
    }));

  useEffect(() => {
    fetchInitialData();
  }, []);

  return (
    <PaymentStyled>
      {!isStaff && (
        <div className="flex justify-end" hidden={isEdit}>
          <ButtonSolid
            onClick={() => setIsEdit(true)}
            className="!px-[54px] !max-h-[35px]"
          >
            編集
          </ButtonSolid>
        </div>
      )}
      <Form
        form={form}
        className="max-w-[700px] mt-3"
        onFinish={handleSubmit}
        autoComplete="off"
        labelAlign="left"
        labelWrap
        disabled={!isEdit}
      >
        <Form.Item
          label="請求方法"
          name="payment_method"
          initialValue="collect_money"
        >
          <RadioGroup>
            <Radio value="collect_money">集金</Radio>
            <Radio value="transfer">振込</Radio>
            <Radio value="account_transfer">口座振替</Radio>
          </RadioGroup>
        </Form.Item>
        {payment_method === "account_transfer" && (
          <>
            <Form.Item
              label={
                <span>
                  顧客番号
                  <br />
                  (半角英数20桁)
                </span>
              }
              name="customer_number"
              rules={[
                { required: true, message: MESSAGES.requireField },
                {
                  pattern: REGEX.atLeastOneNonSpaceCharacter,
                  message: MESSAGES.requireField,
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="銀行名"
              name="bank_name"
              rules={[
                { required: true, message: MESSAGES.requireField },
                {
                  pattern: REGEX.atLeastOneNonSpaceCharacter,
                  message: MESSAGES.requireField,
                },
              ]}
            >
              <SelectWithData
                onChange={(_, option: any) => {
                  form.setFieldValue("bank_info_id", option.id);
                  form.setFieldValue("branch_name", undefined);
                  form.setFieldValue("branch_info_id", undefined);
                }}
                getOptions={getBankOptions}
                showSearch
                notFoundContent={null}
              />
            </Form.Item>
            <Form.Item name="bank_info_id" hidden>
              <div />
            </Form.Item>
            <Form.Item
              label="支店名"
              name="branch_name"
              rules={[
                { required: true, message: MESSAGES.requireField },
                {
                  pattern: REGEX.atLeastOneNonSpaceCharacter,
                  message: MESSAGES.requireField,
                },
              ]}
            >
              <SelectWithData
                onChange={(_, option: any) =>
                  form.setFieldValue("branch_info_id", option.id)
                }
                disabled={!bank_info_id || !isEdit}
                getOptionDepdendancy={[bank_info_id]}
                getOptions={getBranchOptions}
                showSearch
                notFoundContent={null}
              />
            </Form.Item>
            <Form.Item name="branch_info_id" hidden>
              <div />
            </Form.Item>
            <Form.Item
              label="口座種類"
              name="account_type"
              initialValue="ordinary_deposit"
            >
              <RadioGroup>
                <Radio value="ordinary_deposit">普通預金</Radio>
                <Radio value="current_account">当座預金</Radio>
                <Radio value="time_deposit"> 定期預金</Radio>
              </RadioGroup>
            </Form.Item>
            <Form.Item
              label="口座番号"
              name="account_number"
              rules={[{ required: true, message: MESSAGES.requireField }]}
            >
              <NumericInput />
            </Form.Item>
            <Form.Item
              label="口座名義(カナ)"
              name="account_name_kana"
              rules={[
                { required: true, message: MESSAGES.requireField },
                {
                  pattern: REGEX.atLeastOneNonSpaceCharacter,
                  message: MESSAGES.requireField,
                },
                {
                  pattern: REGEX.onlyKatakanaText,
                  message: MESSAGES.invalidKatakanaCharacter,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </>
        )}
        <Form.Item label="請求所の送付先" name="payer" initialValue="yourself">
          <RadioGroup>
            <Radio value="yourself"> 本人</Radio>
            <Radio value="other">本人以外</Radio>
          </RadioGroup>
        </Form.Item>
        {payer === "other" && (
          <>
            <Form.Item
              label="郵便番号"
              name="zipcode"
              rules={[
                { len: 8 },
                { required: true, message: MESSAGES.requireField },
                {
                  pattern: REGEX.zipcode,
                  message: MESSAGES.invalidPostCode,
                },
              ]}
            >
              <PatternFormat
                customInput={Input}
                format="###-####"
                mask="_"
                onChange={(e) => getAddressByZipCode(e.target.value)}
              />
            </Form.Item>
            <Form.Item
              name="district"
              label="都道府県"
              rules={[{ required: true, message: MESSAGES.requireField }]}
            >
              <SelectWithData
                onChange={() => {
                  form.setFieldValue("city", undefined);
                  form.setFieldValue("street", undefined);
                }}
                getOptions={getDistrictOptions}
                placeholder="選択してください"
              />
            </Form.Item>
            <Form.Item
              label="市区町村"
              name="city"
              rules={[{ required: true, message: MESSAGES.requireField }]}
            >
              <Input disabled={!district || !isEdit} />
            </Form.Item>
            <Form.Item
              label="町名・番地"
              name="street"
              rules={[{ required: true, message: MESSAGES.requireField }]}
            >
              <Input disabled={!district || !city || !isEdit} />
            </Form.Item>
            <Form.Item label="ビル・マンション名" name="building_name">
              <Input />
            </Form.Item>
          </>
        )}
        <Form.Item name="id" hidden>
          <div />
        </Form.Item>
      </Form>
      <div className="flex items-center justify-between">
        <div>
          {!isStaff && (
            <Link
              to={`${ROUTES.EDIT_HISTORY}?changeable_type=Payment&changeable_id=${changeable_id}`}
              hidden={!changeable_id}
            >
              <div className="flex items-center text-[12px] leading-[20px] gap-x-[6px]">
                <ClockCircleFilled />
                <span>履歴一覧</span>
              </div>
            </Link>
          )}
        </div>
        <div className="flex gap-x-[8px]" hidden={!isEdit}>
          <ButtonOutline
            onClick={() => {
              fetchInitialData();
              setIsEdit(false);
            }}
            className="!h-[35px] !px-[16px] !text-[14px] !leading-[24px]"
          >
            キャンセル
          </ButtonOutline>
          <ButtonSolid
            onClick={form.submit}
            className="!h-[35px] !px-[54px] !text-[14px] !leading-[24px]"
            isLoading={submitting}
          >
            保存
          </ButtonSolid>
        </div>
      </div>
    </PaymentStyled>
  );
}
