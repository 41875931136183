import { ReactNode, useEffect, useState } from "react";
import { Form, Input, Tabs } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { ButtonOutline, ButtonSolid } from "component/button";
import TableAdmin from "component/admin/table";
import type { ColumnsType, TableProps } from "antd/es/table";
import { Link, useNavigate } from "react-router-dom";
import ROUTES from "constant/routes";

import { InputStyled } from "./styled";
//import "./style.scss";
import axiosClient from "api/axiosClient";
import dayjs from "dayjs";
import useService from "hook/useService";
import { JP_DATE_FORMAT } from "constant/date";
import { IStaff } from "constant/interfaces";

const items = [
  {
    key: "active",
    label: "在籍中",
  },
  {
    key: "on_leave",
    label: "休職中",
  },
  {
    key: "resigned",
    label: "退職済",
  },
];

export const columns: ColumnsType<IStaff> = [
  {
    align: "center",
    title: "ID",
    dataIndex: "nurse_code",
    sorter: true,
    sortDirections: ["descend", "ascend"],
  },
  {
    align: "center",
    title: "氏名",
    sorter: true,
    dataIndex: "name",
    render: (value, record) => (
      <span>
        {record.family_name}
        <br />({record.name_kana})
      </span>
    ),
    className: "min-w-[250px] max-w-[250px]",
    ellipsis: true,
  },
  {
    align: "center",
    title: "携帯番号1",
    dataIndex: "telephone_number",
    sorter: true,
    className: "min-w-[200px] max-w-[200px]",
    ellipsis: true,
  },
  {
    align: "center",
    title: "生年月日",
    dataIndex: "birth_date",
    sorter: true,
    className: "min-w-[200px] max-w-[200px]",
    ellipsis: true,
    render: (value) => dayjs(value).format(JP_DATE_FORMAT),
  },
  {
    align: "center",
    title: "年齢",
    dataIndex: "age",
    sorter: true,
  },
  { align: "center", title: "性別", dataIndex: "sex", sorter: true },
  {
    align: "center",
    title: "",
    dataIndex: "action",
    width: 200,
    render: (_, record) => (
      <Link to={`${record.id}`}>
        <ButtonOutline className="!text-[12px] !bg-[#FFFFFF] !leading-[20px] !text-[#E07B2E] !px-[19px] !h-[23px]">
          詳細
        </ButtonOutline>
      </Link>
    ),
  },
];

export default function StaffList() {
  const [data, setData] = useState<IStaff[]>([]);
  const [status, setStatus] = useState<string>("active");
  const [form] = Form.useForm();
  const service = useService();
  const [loading, setLoading] = useState<boolean>(false);
  const [total, setTotal] = useState<number>(0);
  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await axiosClient({
        url: service.NURSING_STAFF,
        method: "get",
        params: { ...form.getFieldsValue(), status: [status] },
      });
      setData(res.data.nursing_staffs);
      setLoading(false);
      setTotal(res.data.total_items);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };
  useEffect(() => {
    form.resetFields();
    fetchData();
  }, [status]);
  return (
    <Form form={form} onFinish={fetchData}>
      <div className="page-title">介護スタッフ一覧</div>
      <div className="page-container">
        <Tabs items={items} onChange={setStatus} activeKey={status} />
        <div className="py-[16px] px-[25px] bg-[#F5F4F2]">
          <p className="text-[#000000] text-[12px] leading-[20px]">
            キーワード
          </p>
          <div className="flex gap-x-[21px] items-center">
            <Form.Item name="fullname" className="w-full mb-0">
              <InputStyled
                className="flex items-center customInp border-[#707070] focus:!text-transparent"
                placeholder="氏名、カナ"
                prefix={
                  <SearchOutlined className="text-[16px] text-[#2699FB] mr-[3px]" />
                }
              />
            </Form.Item>
            <ButtonOutline
              type="submit"
              onClick={() => {
                form.resetFields(["page"]);
              }}
              className=" !px-[63px] !bg-[#FFFFFF] !max-h-[25px]"
              _hover={{
                color: "brand.500]",
                bg: "brand.100",
              }}
            >
              検索
            </ButtonOutline>
          </div>
        </div>
        <div className="text-end mt-[19px] mb-[21px]">
          <Link to={`${ROUTES.CREATE}`}>
            <ButtonSolid>介護スタッフ登録</ButtonSolid>
          </Link>
        </div>
        <div>
          <TableAdmin
            form={form}
            data={data}
            columns={columns}
            total={total}
            loading={loading}
          />
        </div>
      </div>
    </Form>
  );
}
