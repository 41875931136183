import { ShiftType } from "page/ManageShiftStaff/type";
import { memo } from "react";
import { SHIFT_TYPES } from "constant";
import { background } from "@chakra-ui/react";
interface IProps {
  disabled: boolean;
  indexCol: number;
  row: number;
  date: string;
  nurseId: number;
  id?: number;
  shiftType?: ShiftType;
}

const ShiftCard = ({
  disabled,
  indexCol,
  row,
  date,
  nurseId,
  shiftType,
}: IProps) => {

  const style = shiftType
    ? { background: SHIFT_TYPES[shiftType].color, border: `1.5px solid ${SHIFT_TYPES[shiftType].border}` }
    : {};

  return (
    <div style={{ ...style }}>
      <div
        className={`w-full flex justify-center items-center flex-col h-24 select-none text-[#fff] ${
          disabled
            ? "cursor-not-allowed"
            : `staff_schedule staff_schedule_row${row} cursor-pointer`
        }`}
        data-indexcol={indexCol}
        data-row={row}
        data-date={date}
        data-nurseid={nurseId}
        data-shifttype={shiftType || ""}
      >
        {shiftType
          ? SHIFT_TYPES[shiftType].label.split("").map((x, index) => (
              <p className="select-none" key={index}>
                {x}
              </p>
            ))
          : ""}
      </div>
    </div>
  );
};

export default memo(ShiftCard);
