import { Text } from "@chakra-ui/react";
import { DatePicker, Form } from "antd";
import apiFactory from "api";
import { FlexRow, StyleTitle } from "component/StyledComponent";
import { ButtonOutline, ButtonSolid } from "component/button";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { IUpdateEvalueResponse } from "../_type";
import { OPTIONS, SelectOptions } from "../config";
import Styles from "./styled";
import { DATE_FORMAT, JP_DATEPICKER_FORMAT } from "constant/date";
import moment from "moment";
import dayjs from "dayjs";

const AssessmentSheet2Edit = (props: any) => {
  const { display, dataDetail, onClose } = props;
  const [form] = Form.useForm();

  //-- State
  const [isEdit, setIsEdit] = useState(false);
  const [submitting, setSubmitting] = useState(false)

  const handleSubmit = async (formData: any) => {
    setSubmitting(true)
    try {
      const res =
        await apiFactory.adminEvalueTwoApi.updateEvalue<IUpdateEvalueResponse | null>(
          {
            ...formData,
            created_date: formData.created_date
              ? formData.created_date.format(DATE_FORMAT)
              : dayjs().format(DATE_FORMAT),
          }
        );

      if (res && res.success) {
        toast.success(res.success);
        onClose(true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setSubmitting(false)
    }
  };

  useEffect(() => {
    if (display?.edit) {
      setIsEdit(true);
    } else {
      setIsEdit(false);
    }
  }, []);

  return (
    <Styles>
      <Form
        form={form}
        onFinish={handleSubmit}
        autoComplete="off"
        labelAlign="left"
        labelCol={{ flex: "110px" }}
        labelWrap
        disabled={!isEdit}
        scrollToFirstError={{ block: "center" }}
        initialValues={{
          ...dataDetail,
          created_date: dataDetail?.created_date
            ? dayjs(dataDetail?.created_date)
            : null,
        }}
      >
        <FlexRow className="justify-end">
          <ButtonSolid
            hidden={isEdit}
            width="137px"
            height="35px"
            fontSize={14}
            onClick={() => onClose()}
          >
            戻る
          </ButtonSolid>
        </FlexRow>
        <StyleTitle>■身体機能・状態</StyleTitle>
        <div className="text-left flex flex-wrap gap-x-10">
          <div className="w-[700px]">
            {/* --Meal-- */}
            <Text fontSize="14px" mb="3" fontWeight="bold">
              ◯食事
            </Text>
            <SelectOptions data={OPTIONS.staple_food} />
            <SelectOptions data={OPTIONS.side_dish} />
            <SelectOptions type="radio" data={OPTIONS.thoromi} />
            <SelectOptions type="radio" data={OPTIONS.assistance} />
            <SelectOptions data={OPTIONS.tool} />
            <SelectOptions data={OPTIONS.hobby_goods} />
            <SelectOptions type="radio" data={OPTIONS.allergy} />

            {/* --BodyParts-- */}
            <Text fontSize="14px" mb="3" fontWeight="bold">
              ◯各部位
            </Text>
            <SelectOptions data={OPTIONS.head} />
            <SelectOptions data={OPTIONS.upper_limbs} />
            <SelectOptions data={OPTIONS.waist} />
            <SelectOptions data={OPTIONS.hip_joint} />
            <SelectOptions data={OPTIONS.lower_limbs} />
            <SelectOptions type="radio" data={OPTIONS.eyesight} />
            <SelectOptions type="radio" data={OPTIONS.glasses} />
            <SelectOptions type="radio" data={OPTIONS.hearing} />
            <SelectOptions type="radio" data={OPTIONS.language} />

            {/* Mobility */}
            <Text fontSize="14px" mb="3" fontWeight="bold">
              ◯移動
            </Text>
            <SelectOptions data={OPTIONS.morphology} />
            <SelectOptions type="radio" data={OPTIONS.move} />
            <SelectOptions type="radio" data={OPTIONS.standing_position} />
            <SelectOptions type="radio" data={OPTIONS.seat} />
          </div>

          <div className="w-[700px]">
            {/* Bathing */}
            <Text fontSize="14px" mb="3" fontWeight="bold">
              ◯入浴
            </Text>
            <SelectOptions type="radio" data={OPTIONS.wash_body} />
            <SelectOptions type="radio" data={OPTIONS.hair_washing} />
            <SelectOptions
              type="radio"
              data={OPTIONS.attaching_and_detaching}
            />
            <SelectOptions type="radio" data={OPTIONS.plastic_surgery} />

            {/* Excretion */}
            <Text fontSize="14px" mb="3" fontWeight="bold">
              ◯排泄
            </Text>
            <SelectOptions data={OPTIONS.excretion_form} />
            <SelectOptions type="radio" data={OPTIONS.urge_to_urinate} />
            <SelectOptions type="radio" data={OPTIONS.convenience} />
            <SelectOptions type="radio" data={OPTIONS.incontinence} />

            {/* oralCavity */}
            <Text fontSize="14px" mb="3" fontWeight="bold">
              ◯口腔
            </Text>
            <SelectOptions type="radio" data={OPTIONS.chew} />
            <SelectOptions type="radio" data={OPTIONS.swallowing} />
            <SelectOptions data={OPTIONS.denture} />

            {/* Mental State */}
            <Text fontSize="14px" mb="3" fontWeight="bold">
              ◯精神状態
            </Text>
            <SelectOptions type="radio" data={OPTIONS.understanding} />
            <SelectOptions type="radio" data={OPTIONS.expression_of_meaning} />
            <SelectOptions type="radio" data={OPTIONS.understand} />
            <SelectOptions type="radio" data={OPTIONS.wander} />
            <SelectOptions type="radio" data={OPTIONS.violence} />
            <SelectOptions type="radio" data={OPTIONS.delusion} />
            <SelectOptions type="radio" data={OPTIONS.rejection} />

            {/* Medical care */}
            <Text fontSize="14px" mb="3" fontWeight="bold">
              ◯医療
            </Text>
            <SelectOptions type="radio" data={OPTIONS.treatment} />
            <SelectOptions type="radio" data={OPTIONS.dosage} />
            <Form.Item label="作成日" name="created_date">
              <DatePicker
                disabledDate={(date) => date.isAfter(Date.now())}
                format={JP_DATEPICKER_FORMAT}
                className="w-full"
                allowClear={true}
              />
            </Form.Item>
          </div>
        </div>
        <div className="flex items-center justify-end">
          {/* <div>
            <Link
              to={`${ROUTES.EDIT_HISTORY}?changeable_type=EvalueTwo&changeable_id=${changeable_id}`}
              hidden={!changeable_id}
            >
              <div className="flex items-center text-[12px] leading-[20px] gap-x-[6px]">
                <ClockCircleFilled />
                <span>履歴一覧</span>
              </div>
            </Link>
          </div> */}
          <div className="flex gap-x-[8px]" hidden={!isEdit}>
            <ButtonOutline
              onClick={() => {
                onClose();
              }}
              className="!h-[35px] !px-[16px] !text-[14px] !leading-[24px]"
            >
              キャンセル
            </ButtonOutline>
            <ButtonSolid
              onClick={form.submit}
              className="!h-[35px] !px-[54px] !text-[14px] !leading-[24px]"
              isLoading={submitting}
            >
              保存
            </ButtonSolid>
          </div>
        </div>
        <Form.Item name="id" hidden>
          <div />
        </Form.Item>
      </Form>
    </Styles>
  );
};

export default AssessmentSheet2Edit;
