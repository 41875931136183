import styled from "@emotion/styled";
const Styles = styled.div`
  .ant-row {
    display: flex;
  }
  .custom-row.ant-table-row {
    td {
      padding: 6px 16px;
    }
    th {
      padding: 18px 16px;
    }
  }
`;
export default Styles;
