import { useMemo } from "react";
import { Breadcrumb, Tabs, TabsProps } from "antd";
import styled from "@emotion/styled";
import { useLocation, useNavigate } from "react-router-dom";
import NotificationForm from "./NotificationForm";

const StyledTabs = styled(Tabs)`
  .ant-tabs-nav {
    &:before {
      content: none;
    }
  }
  .ant-tabs-content-holder {
  }
`;

const tabs: TabsProps["items"] = [
  {
    key: "1",
    label: "重要事項",
    children: <NotificationForm formType="important" />,
  },
  {
    key: "2",
    label: "申し送り事項",
    children: <NotificationForm formType="conveyed" />,
  },
];

const DEFAULT_TAB_KEY = "1";

const CreateNoti: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const queryParams = new URLSearchParams(location.search);

  const activeTab = useMemo(() => {
    if (queryParams.has("tab")) {
      return queryParams.get("tab");
    }

    return DEFAULT_TAB_KEY;
  }, [queryParams]);

  const handleChangeTab: TabsProps["onChange"] = (activeKey) => {
    navigate(`/staff/bulletin-board/create?tab=${activeKey}`);
  };

  return (
    <div className="bg-[#EEEEEE] px-[20px] py-[12px]">
      {/* <Breadcrumb>
        <Breadcrumb.Item>ホーム</Breadcrumb.Item>
        <Breadcrumb.Item>お知らせ</Breadcrumb.Item>
      </Breadcrumb> */}

      <div className="page-title">掲示板　親スレッド投稿</div>
      <div className="page-container">
        <div className="md:w-[490px]">
          <StyledTabs
            activeKey={activeTab || DEFAULT_TAB_KEY}
            items={tabs}
            onChange={handleChangeTab}
          />
        </div>
      </div>
    </div>
  );
};

export default CreateNoti;
