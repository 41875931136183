import { Button, Col, Form, Input, Row } from "antd"
import apiFactory from "api";
import { ButtonOutline, ButtonSolid } from "component/button"
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import './style.scss'
import MESSAGES from "constant/messages";

const ServiceDetail = ({ onChange, id, setServiceContentId, difference, setDifference }: any) => {
    const [form] = Form.useForm();
    const [submitting, setSubmitting] = useState(false)


    const [data, setData] = useState<
        {
            id: number | null,
            detail: string,
        }
    >({
        id: null,
        detail: '',
    })
    const onFinish = async (values: any) => {
        
        const body = {
            id: values.id,
            detail: values.detail.trim(),
        }
        try {
            setSubmitting(true)
            let result
            switch (difference.type) {
                case 'add':
                    result = await apiFactory.careRegistrationApi.createServiceContent(body)
                    break;
                case 'edit':
                    result = await apiFactory.careRegistrationApi.updateServiceContent(body)
                    break;
                default:
                    break;
            }
            if (result) {
                toast.success(result.success)
                switch (difference.type) {
                    case 'add':
                        setServiceContentId(null)
                        onChange()
                        break;
                    case 'edit':
                        setServiceContentId(id);
                        setDifference({
                            type: 'view',
                        });
                        break;
                    default:
                        break;
                }
            }
        } catch (error: any) {
          if (error && error.status === 422 && error.message) {
            if (error.message.detail) {
              form.setFields([
                {
                  name: "detail",
                  errors: [error.message.detail]
                }
              ]);
            }
          }
        } finally {
            setSubmitting(false)
        }
    };
    const onBack = () => {
        if (difference.type === 'edit') {
            setServiceContentId(id);
            setDifference({
                type: 'view',
            });
        } else {
            setServiceContentId(null)
            onChange()
        }

    }
    const fetchData = async () => {
        if (id) {
            try {
                const result = await apiFactory.careRegistrationApi.getServiceContentDetail(id)
                if (result) {
                    form.setFieldsValue({
                        id: result?.id,
                        detail: result?.detail
                    })
                }
            } catch (error: any) {
                toast.error(error?.message)
            }
        }
    }
    useEffect(() => {
        fetchData()
    }, [difference])
    return <div className='pt-[7px] service-detail'>
        {
            difference.type === 'view' &&
            <div className='text-end mb-[30px]'>
                <ButtonOutline className='!h-[35px] !px-[16px] !text-[14px] !leading-[24px] mr-[8px]'
                    onClick={onBack}>一覧へ戻る</ButtonOutline>
                <ButtonSolid className='!h-[35px] !px-[54px] !text-[14px] !leading-[24px]' onClick={() => setDifference({
                    type: 'edit'
                })}>編集</ButtonSolid>
            </div>
        }
        <Form
            initialValues={data}
            onFinish={onFinish}
            form={form}
        >
            <Row>
                <Col span={11}>

                    <Form.Item
                        label="サービス内容ID"
                        name="id"
                    >
                        <Input disabled />
                    </Form.Item>
                    <Form.Item
                        label="サービス内容"
                        name="detail"
                        rules={[
                            { required: true, message: MESSAGES.requireField },
                            { max: 255 },
                            ({ getFieldValue }) => ({
                                validator(rule, value) {
                                    if (value !== '' && value.trim() === '') {
                                        return Promise.reject('この項目は必須です')
                                    }
                                    return Promise.resolve()
                                }
                            })
                        ]}
                    >
                        <Input disabled={difference.type === 'view'} maxLength={255}/>
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={13} />
                <Col span={11}>
                    <div className='flex items-center justify-between'>
                        <div className='flex items-center text-[12px] leading-[20px] gap-x-[6px]'>
                        </div>
                        <div className='flex gap-x-[8px]'>
                            {difference.type !== 'view' &&
                                <ButtonOutline className='!h-[35px] !px-[16px] !text-[14px] !leading-[24px]' onClick={onBack}>キャンセル</ButtonOutline>
                            }
                            {
                                difference.type !== 'view' &&
                                <Button className="save-button" htmlType="submit" loading={submitting}>保存</Button>
                            }
                        </div>
                    </div>
                </Col>
            </Row>
        </Form>
    </div>

}
export {
    ServiceDetail
}
