import CustomerList from "page/Customer/CustomerList";
import CustomerDetail from "page/Customer/CustomerDetail";
import BasicInfo from "page/Customer/CustomerDetail/BasicInfo";
import CarePlan from "page/Customer/CustomerDetail/CarePlan";
import Expense from "page/Customer/CustomerDetail/Expense";
import CreateExpense from "page/Customer/CustomerDetail/Expense/Create";
import CreateInsuranceCard from "page/Customer/CustomerDetail/InsuranceCard/Create";
import ShiftPage from "page/Shift";
import Notice from "page/admin/Notice/Notice";
import { lazy } from "react";
import CareRecord from "page/Customer/CustomerDetail/CareRecord";
import ProfileDetail from "page/admin/Menu/Profile/ProfileDetail";
import CareService from "page/admin/Menu/CareService/CareService";
import FacilityList from "page/admin/Menu/Facility/FacilityList";
import FacilityDetail from "page/admin/Menu/Facility/FacilityDetail";
import ShiftRegistrationDetail from "page/admin/Menu/ShiftRegistration/ShiftRegistrationDetail";
import ShiftRegistrationList from "page/admin/Menu/ShiftRegistration/ShiftRegistrationList";
import CustomerHistoryList from "page/Customer/CustomerHistory/List";
import { ContactList } from "page/staff/Notice/Contact/ContactList";
import { ContactChat } from "page/staff/Notice/Contact/ContactChat";
import { PostList } from "page/staff/Notice/Board/PostList";
import { PostDetail } from "page/staff/Notice/Board/PostDetail";
import { CommentDetail } from "page/staff/Notice/Board/CommentDetail";

const MenuPage = lazy(() => import("./page/MenuPage"));
const LoginPage = lazy(() => import("./page/LoginPage"));
const HistoryCustomerNameList = lazy(
  () => import("./page/staff/custormer/HistoryCustomerNameList")
);
const HistoryCustomerList = lazy(
  () => import("./page/staff/custormer/HistoryCustomerList")
);
const HistoryRecordDaily = lazy(() => import("./page/HistoryRecordDaily"));
const RecordDailyMaster = lazy(() => import("./page/RecordDailyMaster"));
const HistoryRecordByDayPage = lazy(
  () => import("./page/Customer/CustomerHistory/Detail")
);
const CalendarPage = lazy(() => import("./page/CalendarPage"));
const VisitSchedulePage = lazy(() => import("./page/staff/VisitSchedulePage"));
const NextMonthShiftBefore = lazy(
  () => import("./page/staff/NextMonthShiftBefore")
);
const NextMonthShiftAfter = lazy(
  () => import("./page/staff/NextMonthShiftAfter")
);

const ControlTest = lazy(() => import("./page/admin"));

/*Chau Tran: Add Screen for Client - Start*/
const LoginClientPage = lazy(() => import("./page/LoginClientPage"));
const MenuClientPage = lazy(() => import("./page/MenuClientPage"));
const SearchPage = lazy(() => import("./page/client/SearchPage"));
const ClientHistoryListPage = lazy(
  () => import("./page/client/RecordHistory/ClientHistoryList")
);
const ClientRecordHistoryPage = lazy(
  () => import("./page/client/RecordHistory/RecordHistory")
);
const ClientVisitSchedulePage = lazy(
  () => import("./page/client/ClientVisitSchedulePage")
);
/*Chau Tran: Add Screen for Client - End*/

const StaffList = lazy(() => import("./page/admin/Staff/StaffList"));
const StaffChangeHistory = lazy(
  () => import("./page/admin/Staff/StaffChangeHistory")
);
const StaffDetail = lazy(() => import("./page/admin/Staff/StaffDetail"));
const RecordEachCustomer = lazy(
  () => import("./page/admin/RecordEachCustomer")
);
const ManageShift = lazy(() => import("./page/admin/ManageShift"));

export const STAFFPATH = {
  menu: "/menu",
  login: "/login-staff",
  recordByDay: "/record-by-day",
  recordHistoryDaily: "/record-history-daily",
  visitSchedule: "/visit-schedule",
  monthShiftBefore: "/month-shift-before",
  monthShiftAfter: "/month-shift-after",
  bulletinBoard: "/bulletin-board",
  bulletinBoardParentThread: "/bulletin-board-parent-thread",
  bulletinBoardContent: "/bulletin-board-content",
  bulletinBoardChildrenThread: "/bulletin-board-children-thread",
  individualContact: "/individual-contact",
  contactList: "/contact-list",
  contactChat: "/contact-chat",
  historyCustomerName: "/history-customer-name",
  historyCustomerList: "/history-customer-list",
  nursingCareRecord: "/nursing-care-record",
};

export const MASTERPATH = {
  customerDetail: "/customer-detail",
  calendar: "/calendar",
  login: "/login-admin",
  recordDailyAdmin: "/record-daily-admin",
  recordHistoryCustomer: "/record-history-customer",
  dashboardAdmin: "/dashboard",
  staffList: "/staff-list",
  staffChangeHistory: "/staff-edit-history",
  staffDetail: "/staff-detail",
  recordEachCustomer: "/record-for-each-customer",
  manageShift: "/manage-shift",
  /**
   * Profile menu
   */
  menuProfile: "/menu-profile",
  /**
   * Shift registration menu
   */
  shiftRegistrationList: "/menu-shift-registration-list",
  shiftRegistrationDetail: "/menu-shift-registration-detail/:id",
  // carePlanRegistration: "/care-plan-registration",
  /**
   * Care service menu
   * */
  careService: "/care-service",
  /**
   * Facility menu
   */
  facilityList: "/facility-registration-list",
  facilityDetail: "/facility-registration-detail/:id",

  notice: "/notice",
  customer: "/customer",
  shift: "/shift",
  manageCustomer: "/manage-customer",
};

/*Chau Tran: Add Screen for Client - Start*/
export const CLIENTPATH = {
  login: "/login-client",
  menu: "/menu-client",
  visitSchedule:
    "/visit-schedule-client" /*訪問スケジュール (/*日別, 週別, 月別*/,
  historyList:
    "/history-list" /*対応記録・履歴 (対応記録・履歴　お客様名様一覧)*/,
  history: "/history" /*対応記録・履歴　お客様名様*/,
  billingDepositHistory: "/billing-deposit-history" /*請求・入金履歴*/,
  search: "/search" /*検索*/,
};
/*Chau Tran: Add Screen for Client - End*/

const routes = [
  {
    path: STAFFPATH.menu,
    element: MenuPage,
    isProtect: false,
  },
  {
    path: STAFFPATH.login,
    element: LoginPage,
    isProtect: false,
  },
  {
    path: "/",
    element: LoginPage,
    isProtect: false,
  },
  {
    path: "/shift",
    element: ShiftPage,
  },
  {
    path: MASTERPATH.login,
    element: LoginPage,
    isProtect: false,
  },
  {
    path: STAFFPATH.nursingCareRecord,
    element: HistoryRecordByDayPage,
    isProtect: false,
  },
  {
    path: STAFFPATH.recordHistoryDaily,
    element: HistoryRecordDaily,
    isProtect: false,
  },
  {
    path: STAFFPATH.visitSchedule,
    element: VisitSchedulePage,
    isProtect: false,
  },
  {
    path: STAFFPATH.monthShiftBefore,
    element: NextMonthShiftBefore,
    isProtect: false,
  },
  {
    path: STAFFPATH.monthShiftAfter,
    element: NextMonthShiftAfter,
    isProtect: false,
  },
  {
    path: STAFFPATH.historyCustomerName,
    element: HistoryCustomerNameList,
    isProtect: false,
  },
  {
    path: STAFFPATH.historyCustomerList,
    element: HistoryCustomerList,
    isProtect: false,
  },
  {
    path: STAFFPATH.bulletinBoard,
    element: PostList,
    isProtect: false,
  },
  {
    path: STAFFPATH.bulletinBoardParentThread,
    element: PostDetail,
    isProtect: false,
  },
  {
    path: STAFFPATH.bulletinBoardContent,
    element: CommentDetail,
    isProtect: false,
  },
  {
    path: STAFFPATH.contactList,
    element: ContactList,
    isProtect: false,
  },
  {
    path: STAFFPATH.contactChat,
    element: ContactChat,
    isProtect: false,
  },

  {
    path: MASTERPATH.recordDailyAdmin,
    element: RecordDailyMaster,
    isProtect: false,
  },
  {
    path: MASTERPATH.calendar,
    element: CalendarPage,
    isProtect: false,
  },
  {
    path: MASTERPATH.recordHistoryCustomer,
    element: CustomerHistoryList,
    isProtect: false,
  },
  {
    path: MASTERPATH.dashboardAdmin,
    element: ControlTest,
    isProtect: false,
  },
  {
    path: MASTERPATH.staffList,
    element: StaffList,
    isProtect: false,
  },
  {
    path: MASTERPATH.staffChangeHistory,
    element: StaffChangeHistory,
    isProtect: false,
  },
  {
    path: MASTERPATH.staffDetail,
    element: StaffDetail,
    isProtect: false,
  },
  {
    path: MASTERPATH.recordEachCustomer,
    element: RecordEachCustomer,
    isProtect: false,
  },
  {
    path: MASTERPATH.manageShift,
    element: ManageShift,
    isProtect: false,
  },
  {
    path: MASTERPATH.menuProfile,
    element: ProfileDetail,
    isProtect: false,
  },
  {
    path: MASTERPATH.shiftRegistrationList,
    element: ShiftRegistrationList,
    isProtect: false,
  },
  {
    path: MASTERPATH.shiftRegistrationDetail,
    element: ShiftRegistrationDetail,
    isProtect: false,
  },
  {
    path: MASTERPATH.careService,
    element: CareService,
    isProtect: false,
  },
  {
    path: MASTERPATH.facilityList,
    element: FacilityList,
    isProtect: false,
  },
  {
    path: MASTERPATH.facilityDetail,
    element: FacilityDetail,
    isProtect: false,
  },
  {
    path: MASTERPATH.shift,
    element: ShiftPage,
    isProtect: false,
  },
  {
    path: MASTERPATH.notice,
    element: Notice,
    isProtect: false,
  },
  {
    path: MASTERPATH.customerDetail,
    element: CustomerDetail,
    isProtect: false,
  },
  {
    path: MASTERPATH.customerDetail.concat("/care-record"),
    element: CareRecord,
    isProtect: false,
  },
  {
    path: MASTERPATH.customerDetail.concat("/basic-information"),
    element: BasicInfo,
    isProtect: false,
  },
  {
    path: MASTERPATH.customerDetail.concat("/care-plan"),
    element: CarePlan,
    isProtect: false,
  },
  {
    path: MASTERPATH.customerDetail.concat("/expense"),
    element: Expense,
    isProtect: false,
  },
  {
    path: MASTERPATH.customerDetail.concat("/expense/create"),
    element: CreateExpense,
    isProtect: false,
  },
  {
    path: MASTERPATH.customerDetail.concat("/insurance-card"),
    element: CreateInsuranceCard,
    isProtect: false,
  },
  {
    path: MASTERPATH.customerDetail.concat("/insurance-card/create"),
    element: CreateInsuranceCard,
    isProtect: false,
  },
  {
    path: MASTERPATH.customer, 
    element: CustomerList,
    isProtect: false,
  },

  /*Chau Tran: Add Screen for Client - Start*/
  {
    path: CLIENTPATH.login,
    element: LoginClientPage,
    isProtect: false,
  },
  {
    path: CLIENTPATH.menu,
    element: MenuClientPage,
    isProtect: false,
  },
  {
    path: CLIENTPATH.search,
    element: SearchPage,
    isProtect: false,
  },
  {
    path: CLIENTPATH.historyList,
    element: ClientHistoryListPage,
    isProtect: false,
  },
  {
    path: CLIENTPATH.history,
    element: ClientRecordHistoryPage,
    isProtect: false,
  },
  {
    path: CLIENTPATH.visitSchedule,
    element: ClientVisitSchedulePage,
    isProtect: false,
  },
  /*Chau Tran: Add Screen for Client - End*/
];

export default routes;
