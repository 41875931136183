import React, { Dispatch, useEffect, useState } from "react";
import { DatePicker, Form, Input } from "antd";
import axiosClient from "api/axiosClient";
import useService from "hook/useService";
import { toast } from "react-toastify";
import {
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import dayjs from "dayjs";
import Styles from "./styled";
import { ButtonOutline, ButtonSolid } from "component/button";
import MESSAGES from "constant/messages";
import ROUTES from "constant/routes";
import { JP_DATEPICKER_FORMAT } from "constant/date";
import NumberInputFullWidth from "component/NumberInputFullWidth";
import NumberInputFormat from "component/NumberInputFormat";
import { forEach, get } from "lodash";
import { FORMAT } from "utils";
import { formatNumber } from 'utils/formats'
import { TypeStateNavigate } from '../../_type'
import useResponsive from "hook/useResponsive";

type TypeProps = {
  isStaff: boolean
} & TypeStateNavigate

const RecipientCertificationForm = ({inforNavigate, setInforNavigate, isStaff}: TypeProps) => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const recipientId = inforNavigate?.recipientId;

  const [isEdit, setIsEdit] = useState(Boolean(recipientId));
  const [disabled, setDisabled] = useState(Boolean(recipientId));
  const [submitting, setSubmitting] = useState(false)

  const {isMobile, isTablet} = useResponsive()
  const service = useService();

  const handleSubmit = async (formData: any) => {
    const dataSubmit = {...formData}
    dataSubmit.patient_id = id
    const keyFieldDate = [
      "service_end_date",
      "contract_date",
      "applicable_start_date",
    ];
    forEach(keyFieldDate, key => {
      const date = dataSubmit[key]
      dataSubmit[key] = dayjs(date).format(FORMAT)
    })
    if(dataSubmit.monthly_cost_limit) {
      dataSubmit.monthly_cost_limit = dataSubmit.monthly_cost_limit.replaceAll(',','')
    }
    setSubmitting(true)
    try {
      const res = await axiosClient({
        method: isEdit ? "put" : "post",
        url: `${service.RECIPIENTS}${recipientId ? `/${recipientId}` : ""}`,
        data: dataSubmit,
      });
      toast.success(res.data.success);
      setInforNavigate(null);
    } catch (error) {
      toast.error(get(error,'error',''));
    } finally {
      setSubmitting(false)
    }
  };

  const fetchData = async () => {
    try {
      const { data } = await axiosClient({
        method: "get",
        url: `${service.RECIPIENTS}/${recipientId}`,
      });
      form.setFieldsValue(data);
      data.contract_date &&
        form.setFieldValue("contract_date", dayjs(data.contract_date));
      data.applicable_start_date &&
        form.setFieldValue(
          "applicable_start_date",
          dayjs(data.applicable_start_date)
        );
      data.service_end_date &&
        form.setFieldValue("service_end_date", dayjs(data.service_end_date));
      if(data.monthly_cost_limit) {
        form.setFieldValue("monthly_cost_limit", formatNumber(data.monthly_cost_limit));
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (isEdit) fetchData();
  }, [isEdit]);

  return (
    <Styles className={`relative ${isStaff && (isMobile || isTablet)? "mb-14" : "mb-20"}`}>
      <div className="font-bold flex items-center text-xl border-b border-[#d9d9d9] border-solid pb-4 mb-5 mt-10 ">
        <div className="mr-2 h-[5px] w-[5px] bg-black rounded-[100%]"></div>
        <span>受給者証情報</span>
      </div>
      <Form
        disabled={disabled}
        form={form}
        onFinish={handleSubmit}
        autoComplete="off"
        scrollToFirstError={{ block: "center" }}
        layout={`${isStaff && isMobile ? "vertical" : "horizontal"}`}
      >
        <div className="max-w-[700px] w-full">
          <Form.Item
            label="事業者記入欄番号"
            name="business_code"
            rules={[
              {
                required: true,
                message: MESSAGES.requireField,
              },
            ]}
          >
            <Input autoComplete="off" placeholder="入力してください" />
          </Form.Item>

          <Form.Item
            label="受給者番号"
            name="recipient_code"
            rules={[
              {
                required: true,
                message: MESSAGES.requireField,
              },
            ]}
          >
            <Input autoComplete="off" placeholder="入力してください" />
          </Form.Item>

          <Form.Item
            label="サービス内容"
            name="service_content"
            rules={[
              {
                required: true,
                message: MESSAGES.requireField,
              },
            ]}
          >
            <Input autoComplete="off" placeholder="入力してください" />
          </Form.Item>
          <Form.Item
            label="契約支給量"
            name="contract_allocation_amount"
            rules={[
              {
                required: true,
                message: MESSAGES.requireField,
              },
            ]}
          >
            <Input autoComplete="off" placeholder="入力してください" />
          </Form.Item>

          <Form.Item
            label="契約日"
            name="contract_date"
            rules={[
              {
                required: true,
                message: MESSAGES.requireField,
              },
            ]}
          >
            <DatePicker
              //disabledDate={(date) => date.isAfter(Date.now())}
              format={JP_DATEPICKER_FORMAT}
              className="w-full"
              placeholder="選択してください"
              allowClear={false}
            />
          </Form.Item>

          <Form.Item
            className="gap-4 service_end_date"
            label={
              <div className="text-left">
                当該契約支給量による{isStaff && (isMobile) ? <></> : <br/>}サービス提供終了日
              </div>
            }
            name="service_end_date"
            rules={[
              {
                required: true,
                message: MESSAGES.requireField,
              },
            ]}
          >
            <DatePicker
              // disabledDate={(date) => date.isAfter(Date.now())}
              format={JP_DATEPICKER_FORMAT}
              className="w-full"
              placeholder="選択してください"
              allowClear={false}
            />
          </Form.Item>

          <Form.Item
            label="適用開始年月日"
            name="applicable_start_date"
            rules={[
              {
                required: true,
                message: MESSAGES.requireField,
              },
            ]}
          >
            <DatePicker
              //disabledDate={(date) => date.isAfter(Date.now())}
              format={JP_DATEPICKER_FORMAT}
              className="w-full"
              placeholder="選択してください"
              allowClear={false}
            />
          </Form.Item>

          <div className="flex">
            <Form.Item
              label="利用者負担割合(原則)"
              name="user_burden_percentage"
              rules={[
                {
                  required: true,
                  message: MESSAGES.requireField,
                },
              ]}
            >
              <NumberInputFullWidth
                autoComplete="off"
                placeholder="入力してください"
                className="!w-[158px]"
              />
            </Form.Item>
            <span className={`ml-2 ${isStaff && isMobile ? "mt-8" : "ml-2 mt-1"}`} >割</span>
          </div>
          <div className="flex ">
            <Form.Item
              label="負担上限月額"
              name="monthly_cost_limit"
              rules={[
                {
                  required: true,
                  message: MESSAGES.requireField,
                },
              ]}
            >
              <NumberInputFormat
                autoComplete="off"
                placeholder="入力してください"
                className="w-[158px]"
              />
            </Form.Item>
            <span className={`ml-2 ${isStaff && isMobile ? "mt-8" : "ml-2 mt-1"}`} >円</span>
          </div>
        </div>
      </Form>

      <>
        <div className={`h-20 bg-[#eeeeee] absolute -bottom-20 -right-5 ${isStaff && (isMobile || isTablet) ? "w-[calc(100%+100px)]" : "w-[calc(100%+40px)]"}` }></div>
        <div className={`flex gap-x-[8px] fixed  bg-white w-full right-0 p-4 justify-end ${isStaff && (isMobile || isTablet) ? "bottom-10" : "bottom-0"}`}>
          <ButtonOutline
            onClick={() => {
              setIsEdit(false);
              setInforNavigate(null);
            }}
            className="!h-[35px] !px-[16px] !text-[14px] !leading-[24px]"
          >
            キャンセル
          </ButtonOutline>
          {!isStaff && (
            <ButtonSolid
              onClick={() => (disabled ? setDisabled(false) : form.submit())}
              className="!h-[35px] !px-[54px] !text-[14px] !leading-[24px]"
              isLoading={submitting}
            >
              {disabled ? "編集" : "保存"}
            </ButtonSolid>
          )}
        </div>
      </>
    </Styles>
  );
};

export default RecipientCertificationForm;
