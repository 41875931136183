import { Dispatch, SetStateAction, memo, useState } from "react";
import TextArea from "antd/es/input/TextArea";
import { ButtonSolid } from "component/button";
import useResponsive from "hook/useResponsive";

interface IChatBoxProps {
  content: string;
  onSendMessge: (setSubmitting: Dispatch<SetStateAction<boolean>>) => void;
  setContent: Dispatch<SetStateAction<string>>;
}

const ChatBox = (props: IChatBoxProps) => {
  const { content, setContent, onSendMessge } = props;
  const { isMobile } = useResponsive();
  const [submitting, setSubmitting] = useState(false)
  return (
    <div>
      <div
        className="flex max-md:absolute left-0 bottom-0 h-[70px] md:h-[100px] items-center px-[16px] md:px-[30px] w-full gap-x-[10px] md:max-w-[70%] mx-auto bg-white"
        style={{
          boxShadow: isMobile
            ? "rgba(0, 0, 0, 0.2) 0px -1px 10px 0px"
            : "unset",
        }}
      >
        <TextArea
          placeholder="コメント"
          className="max-md:flex-1 max-md:max-h-[36px] !rounded-md border !border-[#d9d9d9] !resize-none"
          value={content}
          onChange={(e) => setContent(e?.target?.value)}
          rows={3}
        />

        <ButtonSolid
          className="!h-[36px] !px-[16px] !text-[14px] !leading-[24px]"
          disabled={content?.trim() === ""}
          onClick={() =>onSendMessge(setSubmitting)}
          isLoading={submitting}
        >
          送信
        </ButtonSolid>
      </div>
    </div>
  );
};

export default memo(ChatBox);
