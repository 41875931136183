import { Form, Radio, DatePicker, Input } from "antd";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ClockCircleFilled } from "@ant-design/icons";
import { ButtonOutline, ButtonSolid } from "component/button";
import { StaffDetailStyled } from "./styled";
import { useEffect, useState } from "react";
import axiosClient from "api/axiosClient";
import dayjs from "dayjs";
import React from "react";
import useService from "hook/useService";
import { toast } from "react-toastify";
import REGEX from "constant/regex";
import MESSAGES from "constant/messages";
import { IContact, IDegree, IDistrict } from "constant/interfaces";
import SelectWithData from "component/SelectWithData";
import ROUTES from "constant/routes";
import { DATE_FORMAT_2, JP_DATEPICKER_FORMAT } from "constant/date";
import NumericInput from "component/NumberInput";
import { PatternFormat } from "react-number-format";
import InputPassword from "component/admin/input/InputPassword";
import Minus from "assets/minus.png";
import { Button } from "@chakra-ui/react";

const defaultContactRelative: Partial<IContact> = {
  address: "",
  cellphone_number: "",
  telephone_number: "",
  name: "",
  relationship: "",
};

const defaultDegree: Partial<IDegree> = {
  expired_date: "",
  name: "",
};

export default function StaffDetail() {
  const { id } = useParams();
  const service = useService();
  const [form] = Form.useForm();
  const [staffName, setStaffName] = React.useState("");
  const district = Form.useWatch("district", form);
  const city = Form.useWatch("city", form);
  const navigate = useNavigate();
  const [isEdit, setIsEdit] = useState<boolean>(!Boolean(id));
  const [submitting, setSubmitting] = useState(false)
  const onFinishFailed = (errorInfo: any) => {
    // console.log("Failed:", errorInfo);
  };

  const fetchStaffData = async () => {
    try {
      const { data } = await axiosClient({
        method: "get",
        url: `${service.NURSING_STAFF}/${id}`,
      });
      setStaffName(data.family_name);
      const { degrees, birth_date, join_date, ...rest } = data;
      form.setFieldsValue(rest);
      birth_date && form.setFieldValue("birth_date", dayjs(birth_date));
      join_date && form.setFieldValue("join_date", dayjs(join_date));
      if (degrees && degrees.length > 0) {
        const convertedDegrees = degrees.map((item: IDegree) => ({
          ...item,
          expired_date: item.expired_date ? dayjs(item.expired_date) : "",
        }));
        form.setFieldValue("degrees", convertedDegrees);
      }
    } catch (error) {
      console.error(error);
    } 
  };

  const handleSubmit = async (formData: any) => {
    formData.contact_relatives_attributes = formData.contact_relatives;
    formData.degrees_attributes = formData.degrees
      .filter((item: IDegree) => item.name || item.expired_date)
      .map((item: IDegree) => {
        if (item.id) {
          return {
            id: item.id,
            name: item.name,
            expired_date: item.expired_date,
          };
        } else {
          return {
            name: item.name,
            expired_date: item.expired_date,
          };
        }
      });

    formData.password = formData.password || undefined;
    delete formData.contact_relatives;
    delete formData.degrees;
    formData.birth_date = formData.birth_date?.format(DATE_FORMAT_2);
    formData.join_date = formData.join_date?.format(DATE_FORMAT_2);

    try {
      setSubmitting(true)
      const res = await axiosClient({
        method: id ? "put" : "post",
        url: `${service.NURSING_STAFF}${id ? `/${id}` : ""}`,
        data: formData,
      });
      setIsEdit(false);
      toast.success(res.data.success);
      if (!id) {
        navigate(`/${ROUTES.ADMIN}/${ROUTES.STAFF}`);
      } else {
        fetchStaffData();
      }
    } catch (error) {
      toast.error(error as string);
    } finally {
      setSubmitting(false)
    }
  };

  const getDistrictOptions = async () =>
    (await axiosClient.get(service.DISTRICTS)).data.districts.map(
      (item: IDistrict) => ({ label: item.name, value: item.name })
    );

  const getCityOptions = async () =>
    (
      await axiosClient.get(service.CITIES, {
        params: { district_name: district },
      })
    ).data.cities.map((item: IDistrict) => ({
      label: item.name,
      value: item.name,
    }));

  const getAddressOptions = async () =>
    (
      await axiosClient.get(service.ADDRESSES, {
        params: { city_name: city, district_name: district },
      })
    ).data.addresses.map((item: IDistrict) => ({
      label: item.name,
      value: item.name,
    }));

  const getZipCodeByAddress = async (address: string) => {
    if (city && district && address) {
      const { data } = await axiosClient.get(service.POST_CODE, {
        params: {
          district_name: district,
          city_name: city,
          address_name: address,
        },
      });
      form.setFieldValue("zipcode", data.post_code);
    }
  };

  const getAddressByZipCode = async (zipcode: string) => {
    try {
      if (zipcode.replace("_", "").length === 8) {
        const { data } = await axiosClient.get(service.INFO_ADDRESS_BY_CODE, {
          params: {
            code: zipcode,
          },
        });
        form.setFieldValue("district", data.info_address.district.name);
        form.setFieldValue("city", data.info_address.city.name);
        form.setFieldValue("street", data.info_address.address.name);
      }
    } catch (error) {}
  };

  const handleAddDegree = () => {
    let fields = form.getFieldValue("degrees");
    fields.push(defaultDegree);
    form.setFieldValue("degrees", fields);
  };

  const handleRemoveDegree = (indexToRemove: number) => {
    let fields = form.getFieldValue("degrees");
    let newFields = fields.filter(
      (item: IDegree, index: number) => index !== indexToRemove
    );
    form.setFieldValue("degrees", newFields);
  };

  useEffect(() => {
    if (id) fetchStaffData();
  }, [id]);

  return (
    <StaffDetailStyled>
      <div className="page-title">
        {id ? staffName + " 様" : "介護スタッフ登録"}
      </div>
      <div className="page-container">
        <div className="text-end mb-[30px]">
          <ButtonSolid
            hidden={isEdit}
            onClick={() => setIsEdit(true)}
            className="!h-[35px] !px-[54px] !text-[14px] !leading-[24px]"
          >
            編集
          </ButtonSolid>
        </div>
        <Form
          disabled={!isEdit}
          form={form}
          className="max-w-[800px]"
          onFinish={handleSubmit}
          onFinishFailed={onFinishFailed}
          initialValues={{
            contact_relatives: [defaultContactRelative, defaultContactRelative],
            degrees: [defaultDegree],
          }}
          autoComplete="off"
          scrollToFirstError={{ block: "center" }}
        >
          <Form.Item label="スタッフID" name="nurse_code">
            <Input disabled />
          </Form.Item>

          <Form.Item
            label="パスワード"
            name="password"
            initialValue={undefined}
            rules={[
              {
                required: !id,
                message: MESSAGES.requireField,
              },
              {
                pattern: REGEX.passwordStaff,
                min: 8,
                message: MESSAGES.containTextNumber,
              },
              // {
              //   min: 8,
              //   message: MESSAGES.min6max15character,
              // },
            ]}
          >
            <InputPassword
              placeholder="************"
              visibilityToggle={false}
            />
          </Form.Item>
          <div className="flex gap-x-[16px]">
            <Form.Item
              label="氏名"
              name="last_name"
              rules={[
                { required: true, message: MESSAGES.requireField },
                {
                  pattern: REGEX.atLeastOneNonSpaceCharacter,
                  message: MESSAGES.requireField,
                },
                {
                  pattern: REGEX.onlyText,
                  message: MESSAGES.onlyText,
                },
              ]}
            >
              <Input placeholder="姓" />
            </Form.Item>
            <Form.Item
              label=""
              name="first_name"
              rules={[
                { required: true, message: MESSAGES.requireField },
                {
                  pattern: REGEX.atLeastOneNonSpaceCharacter,
                  message: MESSAGES.requireField,
                },
                {
                  pattern: REGEX.onlyText,
                  message: MESSAGES.onlyText,
                },
              ]}
            >
              <Input placeholder="名" />
            </Form.Item>
          </div>
          <div className="flex gap-x-[16px]">
            <Form.Item
              label="氏名(カナ)"
              name="last_name_kana"
              rules={[
                { required: true, message: MESSAGES.requireField },
                {
                  pattern: REGEX.atLeastOneNonSpaceCharacter,
                  message: MESSAGES.requireField,
                },
                {
                  pattern: REGEX.onlyKatakanaText,
                  message: MESSAGES.invalidKatakanaCharacter,
                },
              ]}
            >
              <Input placeholder="姓(カナ)" />
            </Form.Item>
            <Form.Item
              label=""
              name="first_name_kana"
              rules={[
                { required: true, message: MESSAGES.requireField },
                {
                  pattern: REGEX.atLeastOneNonSpaceCharacter,
                  message: MESSAGES.requireField,
                },
                {
                  pattern: REGEX.onlyKatakanaText,
                  message: MESSAGES.invalidKatakanaCharacter,
                },
              ]}
            >
              <Input placeholder="名(カナ)" />
            </Form.Item>
          </div>
          <Form.Item label="性別" name="sex" initialValue="男">
            <Radio.Group>
              <Radio value="男"> 男</Radio>
              <Radio value="女"> 女 </Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label="生年月日"
            name="birth_date"
            rules={[
              { required: true, message: MESSAGES.requireField },
              {
                pattern: REGEX.atLeastOneNonSpaceCharacter,
                message: MESSAGES.requireField,
              },
            ]}
          >
            <DatePicker
              disabledDate={(date) => date.isAfter(Date.now())}
              format={JP_DATEPICKER_FORMAT}
              className="w-full"
              placeholder="選択してください"
              // defaultValue={dayjs(new Date())}
              allowClear={false}
            />
          </Form.Item>
          <Form.Item
            label="郵便番号"
            name="zipcode"
            rules={[
              { len: 8 },
              { required: true, message: MESSAGES.requireField },
              {
                pattern: REGEX.atLeastOneNonSpaceCharacter,
                message: MESSAGES.requireField,
              },
              {
                pattern: REGEX.zipcode,
                message: MESSAGES.invalidPostCode,
              },
            ]}
          >
            <PatternFormat
              customInput={Input}
              format="###-####"
              mask="_"
              onChange={(e) => getAddressByZipCode(e.target.value)}
            />
          </Form.Item>
          <Form.Item
            name="district"
            label="都道府県"
            rules={[
              { required: true, message: MESSAGES.requireField },
              {
                pattern: REGEX.atLeastOneNonSpaceCharacter,
                message: MESSAGES.requireField,
              },
            ]}
          >
            <SelectWithData
              onChange={() => {
                form.setFieldValue("city", undefined);
                form.setFieldValue("street", undefined);
              }}
              getOptions={getDistrictOptions}
              placeholder="選択してください"
            />
          </Form.Item>
          <Form.Item
            label="市区町村"
            name="city"
            rules={[{ required: true, message: MESSAGES.requireField }]}
          >
            <Input disabled={!district || !isEdit} />
          </Form.Item>
          <Form.Item
            label="町名・番地"
            name="street"
            rules={[{ required: true, message: MESSAGES.requireField }]}
          >
            <Input disabled={!district || !city || !isEdit} />
          </Form.Item>
          <Form.Item
            label="ビル・マンション名"
            name="building_name"
            rules={[
              // { required: true, message: MESSAGES.requireField },
              // {
              //   pattern: REGEX.atLeastOneNonSpaceCharacter,
              //   message: MESSAGES.requireField,
              // },
              {
                pattern: REGEX.onlyTextAndNumber,
                message: MESSAGES.invalidCharacter,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="携帯番号1"
            name="telephone_number"
            rules={[
              {
                min: 10,
                max: 20,
                message: MESSAGES.min10max20number,
              },
              { required: true, message: MESSAGES.requireField },
              {
                pattern: REGEX.atLeastOneNonSpaceCharacter,
                message: MESSAGES.requireField,
              },
            ]}
          >
            <NumericInput />
          </Form.Item>
          <Form.Item
            label="携帯番号2"
            name="cellphone_number"
            rules={[
              {
                min: 10,
                max: 20,
                message: MESSAGES.min10max20number,
              },
              // { required: true, message: MESSAGES.requireField },
              // {
              //   pattern: REGEX.atLeastOneNonSpaceCharacter,
              //   message: MESSAGES.requireField,
              // },
            ]}
          >
            <NumericInput />
          </Form.Item>
          <Form.Item
            label="マイナンバー"
            name="my_number"
            rules={[
              {
                min: 10,
                max: 25,
                message: MESSAGES.min10max25number,
              },
              // { required: true, message: MESSAGES.requireField },
              // {
              //   pattern: REGEX.atLeastOneNonSpaceCharacter,
              //   message: MESSAGES.requireField,
              // },
            ]}
          >
            <NumericInput maxLength={25} />
          </Form.Item>

          <Form.List name="contact_relatives">
            {(fields) =>
              fields.map((field, index) => (
                <React.Fragment key={field.key}>
                  <p className="text-[#000000] text-[16px] leading-[27px] mb-4 border-b border-solid border-[#000000] w-[101%]">
                    ■緊急連絡先 {index === 0 ? "①" : "②"}
                  </p>
                  <Form.Item
                    label="氏名"
                    name={[field.name, "name"]}
                    rules={[
                      {
                        pattern: REGEX.onlyText,
                        message: MESSAGES.onlyText,
                      },
                    ]}
                  >
                    <Input name="name" />
                  </Form.Item>
                  <Form.Item
                    label="続柄"
                    name={[field.name, "relationship"]}
                    rules={[
                      {
                        pattern: REGEX.onlyText,
                        message: MESSAGES.onlyText,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="住所"
                    name={[field.name, "address"]}
                    rules={[
                      {
                        pattern: REGEX.address,
                        message: MESSAGES.invalidCharacter,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="携帯番号1"
                    name={[field.name, "telephone_number"]}
                    rules={[
                      {
                        min: 10,
                        max: 20,
                        message: MESSAGES.min10max20number,
                      },
                    ]}
                  >
                    <NumericInput maxLength={20} />
                  </Form.Item>
                  <Form.Item
                    label="携帯番号2"
                    name={[field.name, "cellphone_number"]}
                    rules={[
                      {
                        min: 10,
                        max: 20,
                        message: MESSAGES.min10max20number,
                      },
                    ]}
                  >
                    <NumericInput maxLength={20} />
                  </Form.Item>
                </React.Fragment>
              ))
            }
          </Form.List>

          <div className="flex justify-between border-b border-solid border-[#000000] w-[101%] mb-4 pb-2">
            <p className="text-[#000000] text-[16px]">■保有資格</p>
            {isEdit && (
              <ButtonSolid
                className="!h-[30px] !px-[24px] !text-[14px]"
                onClick={handleAddDegree}
              >
                追加
              </ButtonSolid>
            )}
          </div>
          <Form.List name="degrees">
            {(fields) => {
              return fields.map((fieldItem, fieldIndex) => {
                return (
                  <div className="relative" key={fieldItem.key}>
                    <Form.Item hidden name={[fieldItem.name, "id"]}>
                      <div />
                    </Form.Item>

                    <Form.Item label="資格名" name={[fieldItem.name, "name"]}>
                      <Input />
                    </Form.Item>
                    <Form.Item
                      label="取得年月日"
                      name={[fieldItem.name, "expired_date"]}
                      style={{ marginBottom: "0px !important" }}
                    >
                      <DatePicker
                        placeholder="選択してください"
                        format={JP_DATEPICKER_FORMAT}
                        className="w-full"
                      />
                    </Form.Item>
                    {isEdit && fields.length > 1 && (
                      <Button
                        variant="outline"
                        transform="scale(0.75)"
                        borderRadius="50%"
                        width="40px"
                        height="40px"
                        p="0px"
                        border="1px solid #000"
                        position="absolute"
                        left="100%"
                        top={0}
                        onClick={() => handleRemoveDegree(fieldIndex)}
                      >
                        <img
                          src={Minus}
                          alt="decrease"
                          style={{ height: "20px", width: "20px" }}
                        />
                      </Button>
                    )}
                  </div>
                );
              });
            }}
          </Form.List>
          {/* <Form.Item hidden name={["degree", "id"]}>
            <div />
          </Form.Item>

          <Form.Item label="資格名" name={["degree", "name"]}>
            <Input />
          </Form.Item>
          <Form.Item label="取得年月日" name={["degree", "expired_date"]}>
            <DatePicker
              placeholder="選択してください"
              format={JP_DATEPICKER_FORMAT}
              className="w-full"
            />
          </Form.Item> */}

          <Form.Item label="在籍状況" name="status" initialValue="active">
            <Radio.Group>
              <Radio value="active">在籍中</Radio>
              <Radio value="on_leave">休職中</Radio>
              <Radio value="resigned">退職済</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item label="入社日" name="join_date">
            <DatePicker
              placeholder="選択してください"
              format={JP_DATEPICKER_FORMAT}
              className="w-full"
            />
          </Form.Item>
        </Form>
        <div className="flex items-center justify-between">
          <div>
            <Link
              to={`${ROUTES.EDIT_HISTORY}?changeable_type=NursingStaff&changeable_id=${id}`}
              hidden={!id}
            >
              <div className="flex items-center text-[12px] leading-[20px] gap-x-[6px]">
                <ClockCircleFilled />
                <span>履歴一覧</span>
              </div>
            </Link>
          </div>
          <div className="flex gap-x-[8px]" hidden={!isEdit}>
            <ButtonOutline
              onClick={() => {
                setIsEdit(false);
                if (!id) {
                  navigate(-1);
                } else {
                  fetchStaffData();
                }
              }}
              className="!h-[35px] !px-[16px] !text-[14px] !leading-[24px]"
            >
              キャンセル
            </ButtonOutline>
            <ButtonSolid
              onClick={form.submit}
              className="!h-[35px] !px-[54px] !text-[14px] !leading-[24px]"
              isLoading={submitting}
            >
              保存
            </ButtonSolid>
          </div>
        </div>
      </div>
    </StaffDetailStyled>
  );
}
