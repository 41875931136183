import React from "react";

const XMask = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        d="M10.9275 0.191111L6.00001 5.11861L1.07251 0.191111C0.953999 0.0826603 0.798212 0.0241163 0.637606 0.0276749C0.477 0.0312335 0.323959 0.0966205 0.210366 0.210214C0.0967719 0.323808 0.0313849 0.476848 0.0278263 0.637455C0.0242678 0.798061 0.0828118 0.953848 0.191263 1.07236L5.11626 5.99986L0.190013 10.9261C0.129828 10.9835 0.0817171 11.0523 0.0485074 11.1285C0.0152977 11.2047 -0.00234056 11.2868 -0.00337013 11.3699C-0.00439971 11.4531 0.0112001 11.5356 0.042512 11.6126C0.073824 11.6896 0.120216 11.7596 0.178962 11.8184C0.237707 11.8772 0.307621 11.9237 0.384592 11.9551C0.461563 11.9866 0.54404 12.0023 0.627172 12.0014C0.710305 12.0005 0.792417 11.9829 0.868681 11.9498C0.944945 11.9167 1.01382 11.8687 1.07126 11.8086L6.00001 6.88236L10.9275 11.8099C11.046 11.9183 11.2018 11.9769 11.3624 11.9733C11.523 11.9697 11.6761 11.9044 11.7897 11.7908C11.9033 11.6772 11.9686 11.5241 11.9722 11.3635C11.9758 11.2029 11.9172 11.0471 11.8088 10.9286L6.88126 6.00111L11.8088 1.07236C11.8689 1.01501 11.9171 0.946196 11.9503 0.86998C11.9835 0.793763 12.0011 0.711676 12.0021 0.628545C12.0032 0.545413 11.9876 0.462915 11.9563 0.385899C11.925 0.308883 11.8786 0.238904 11.8198 0.180075C11.7611 0.121246 11.6912 0.0747547 11.6142 0.0433336C11.5372 0.0119126 11.4547 -0.00380392 11.3716 -0.00289217C11.2885 -0.00198043 11.2064 0.0155413 11.1301 0.0486429C11.0538 0.0817445 10.985 0.129758 10.9275 0.189861V0.191111Z"
        fill="#262626"
      />
    </svg>
  );
};

export default XMask;
