import type { DragEndEvent } from "@dnd-kit/core";
import { DndContext } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  SortableContext,
  arrayMove,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import React, { useEffect, useMemo, useState } from "react";
import { Button, Col, Select, Table, Row } from "antd";
import type { ColumnsType } from "antd/es/table";
import apiFactory from "api";
import { ButtonOutline, ButtonSolid } from "component/button";
import ROUTES from "constant/routes";
import { useNavigate } from "react-router-dom";
import { MenuOutlined } from "@ant-design/icons";
import { PositionAndStock } from "api/admin/facilityRegistration";
import { toast } from "react-toastify";
import { NumericFormat } from "react-number-format";

interface DataType {
  key: React.Key;
  id: string;
  serviceName: string;
  unitPrice: string;
  stockQuantity: string;
  stockChange: string;
}

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  "data-row-key": string;
}

const RowMovement = ({ children, ...props }: RowProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props["data-row-key"],
  });

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
    transition,
    ...(isDragging
      ? {
          position: "relative",
          zIndex: 0,
        }
      : {}),
  };

  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children.map(children, (child) => {
        if ((child as React.ReactElement).key === "sort") {
          return React.cloneElement(child as React.ReactElement, {
            children: (
              <MenuOutlined
                ref={setActivatorNodeRef}
                style={{ touchAction: "none", cursor: "move" }}
                {...listeners}
              />
            ),
          });
        }
        return child;
      })}
    </tr>
  );
};

const StockChange = ({ record, dataList, setDataList, originList }: any) => {
  const [operator, setOperator] = useState("+");
  const [changeValue, setChangeValue] = useState(0);

  const onChangeOperator = (e: any) => {
    const arr = dataList.map((item: any) => {
      if (item?.id === record?.id) {
        item.operators = e;
      }
      return item;
    });    
    setDataList(arr);
    setOperator(e);
    setChangeValue(0);
  };
  const onChange = async (e: any) => {
    const arr = dataList.map((item: any) => {
      if (item?.id === record?.id) {
        item.change = e;
      }
      return item;
    });
    setDataList(arr);
    setChangeValue(e);
    let value = 0;
    if (operator === "-") {
      value = Number(record?.stockQuantity) - Number(e);
    } else {
      value = Number(record?.stockQuantity) + Number(e);
    }
    const index = dataList.findIndex((e: any) => e.id === record.id);
    if (index > -1) {
      dataList[index].stockQuantityChange = value;
      dataList[index].amend = true;
      setDataList([...dataList]);
    }
  };
  const stockQuantityOptions = useMemo(() => {
    let change = 0;
    if (!operator) return [];
    if (operator === "+") {
      change = 100 - Number(record?.stockQuantity && record?.stockQuantity) + 1;
    }
    if (operator === "-") {
      change = Number(record?.stockQuantity) + 1;
    }

    return Array.from(Array(Number(change))?.keys())?.map((e: any) => ({
      value: e?.toString(),
      label: e?.toString(),
    }));
  }, [record, operator]);
  useEffect(() => {
    setOperator("+");
    setChangeValue(0);
  }, [originList]);

  return (
    <div className="stock-change-item">
      <Select
        value={operator}
        style={{ width: 70 }}
        options={[
          { value: "+", label: "+" },
          { value: "-", label: "-" },
        ]}
        onChange={onChangeOperator}
      />
      <Select
        // value={stockQuantity}
        value={changeValue}
        disabled={operator === ""}
        style={{ width: 70 }}
        options={stockQuantityOptions}
        onChange={onChange}
      />
    </div>
  );
};

const swapElements = (array: any, setDataList: any, active: any, over: any) => {
  const activeIndex = array.findIndex((i: any) => i.key === active.id);
  const overIndex = array.findIndex((i: any) => i.key === over?.id);
  const overSubtractActive = Number(overIndex) - Number(activeIndex);
  let temp = array[activeIndex];
  if (Number(overIndex) - Number(activeIndex) === 1) {
    array[activeIndex] = {
      ...array[overIndex],
      position: array[activeIndex].position,
      amend: true,
    };
    array[overIndex] = {
      ...temp,
      position: array[overIndex].position,
      amend: true,
    };
  } else if (Number(activeIndex) - Number(overIndex) === 1) {
    array[activeIndex] = {
      ...array[overIndex],
      position: array[activeIndex].position,
      amend: true,
    };
    array[overIndex] = {
      ...temp,
      position: array[overIndex].position,
      amend: true,
    };
  } else if (Number(overIndex) - Number(activeIndex) > 1) {
    array[activeIndex] = {
      ...array[activeIndex + 1],
      position: array[activeIndex].position,
      amend: true,
    };
    for (let i = 1; i < overSubtractActive; i++) {
      if (Number(overSubtractActive) <= 2) {
        array[activeIndex + i] = {
          ...array[overIndex],
          position: array[activeIndex + i].position,
          amend: true,
        };
      } else {
        array[activeIndex + i] = {
          ...array[Number(i) === 1 ? activeIndex + i + 1 : activeIndex + i + 1],
          position: array[activeIndex + i].position,
          amend: true,
        };
      }
    }
    array[overIndex] = {
      ...temp,
      position: array[overIndex].position,
      amend: true,
    };
  } else if (Number(overIndex) - Number(activeIndex) < 1) {
    if (Number(activeIndex - overIndex) <= 2) {
      array[overIndex + 1] = {
        ...array[overIndex],
        position: array[overIndex + 1].position,
        amend: true,
      };

      array[overIndex] = {
        ...array[activeIndex],
        position: array[overIndex].position,
        amend: true,
      };
      array[activeIndex] = {
        ...array[activeIndex - 1],
        position: array[activeIndex].position,
        amend: true,
      };
      // array[overIndex + 1] = {
      //   ...array[overIndex],
      //   position: array[overIndex + 1].position,
      //   amend: true,
      // };
    } else {
      for (let i = 1; i < activeIndex - overIndex; i++) {}
    }
  }
};

const DragTable = ({ title, dataList, setDataList, originList }: any) => {
  const navigate = useNavigate();
  const columns = useMemo<ColumnsType<DataType>>(
    () => [
      {
        key: "sort",
        width: "50px",
        align: "center",
      },
      {
        title: "ID",
        dataIndex: "id",
        width: "50px",
      },
      {
        title: "サービス名称",
        dataIndex: "serviceName",
        render: (text) => {
          if (text?.length > 30) {
            return text.slice(0, 30) + " ...";
          }
          return text;
        },
        width: "320px",
      },
      {
        title: "単価",
        dataIndex: "unitPrice",
        render: (_, record) => {
          return (
            <NumericFormat
              className="bg-[initial]"
              disabled
              value={record?.unitPrice}
              thousandsGroupStyle="wan"
              thousandSeparator=","
              decimalScale={2}
            />
          );
        },
        width: "200px",
      },
      {
        title: "在庫数",
        dataIndex: "stockQuantity",
        render: (_, record) => {
          if (record?.stockQuantity !== "管理しない") {
            return (
              <NumericFormat
                className="bg-[initial]"
                disabled
                value={record?.stockQuantity}
                thousandsGroupStyle="wan"
                thousandSeparator=","
                decimalScale={2}
              />
            );
          } else {
            return <></>;
          }
        },
        width: "200px",
      },
      {
        title: "在庫変更",
        dataIndex: "stockChange",
        render: (_, record) => {
          if (record?.stockQuantity !== "管理しない") {
            return (
              <>
                <StockChange
                  record={record}
                  dataList={dataList.length > 0 && dataList}
                  setDataList={setDataList}
                  originList={originList}
                />
              </>
            );
          } else {
            return <></>;
          }
        },
        width: "200px",
      },
      {
        title: "",
        dataIndex: "action",
        align: "right",
        render: (_, record) => (
          <ButtonOutline
            className="!text-[12px] !bg-[#FFFFFF] !leading-[20px] !text-[#E07B2E] !px-[19px] !h-[23px]"
            onClick={() => navigate(`/admin/facility/${record.id}`)}
          >
            詳細
          </ButtonOutline>
        ),
        width: "120px",
      },
    ],
    [dataList]
  );
  const onDragEnd = async ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      // swapElements(dataList, setDataList, active, over);
      // setDataList([...dataList]);
      setDataList((prev: any) => {
        const activeIndex = prev.findIndex((i: any) => i.key === active.id);
        const overIndex = prev.findIndex((i: any) => i.key === over?.id);
        return arrayMove(prev, activeIndex, overIndex);
      });
    }
  };

  return (
    <div className="facility-list mb-10">
      <div>{title}</div>
      <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
        <SortableContext
          // rowKey array
          items={dataList?.map((i: any) => i.key)}
          strategy={verticalListSortingStrategy}
        >
          <Table
            bordered
            showSorterTooltip={{ title: "" }}
            components={{
              body: {
                row: RowMovement,
              },
            }}
            rowKey="key"
            columns={columns}
            dataSource={dataList}
            pagination={false}
            rowClassName={(record, index) => {
              if (index % 2 !== 0) {
                return "bg-[#f5f4f2]";
              }
              return "";
            }}
            // pagination={{
            //   defaultPageSize: 10,
            //   pageSizeOptions: [5, 10],
            //   pageSize: limit,
            //   position: ['bottomCenter'],
            //   style: { display: 'none' },
            // }}
          />
        </SortableContext>
      </DndContext>
      {/* <div className="flex items-center justify-end wrapper-pagination my-[15px]">
      <Pagination
        current={page}
        onChange={setPage}
        total={totalItems}
        showSizeChanger
        showQuickJumper
        showTotal={(total) => `${(page - 1) * limit} ~ ${(page - 1) * limit + dataList?.length}件(全${total}件中)`}
        onShowSizeChange={(_, size) => {
          setLimit(size)
        }}
        pageSizeOptions={[10, 20, 50, 100]}
      />
    </div> */}
    </div>
  );
};
const FacilityList: React.FC = () => {
  const navigate = useNavigate();
  const [originList, setOriginList] = useState([]);
  const [equipmentList, setEquipmentList] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  const [potoroList, setPotoroList] = useState([]);
  const [submitting, setSubmitting] = useState(false)

  const onSave = async () => {
    setSubmitting(true)
    const body: PositionAndStock = {
      equipment_services: [
        {
          category: "equipment",
          records: equipmentList
            // .filter((e: any) => e.amend)
            .map((e: any, idx) => ({
              id: e.id,
              position: idx,
              stock_quantity: e.stockQuantityChange,
              change: e?.change,
              operators: e?.operators ? e?.operators : "+",
            })),
        },
        {
          category: "service",
          records: serviceList
            // .filter((e: any) => e.amend)
            .map((e: any, idx) => ({
              id: e.id,
              position: idx,
              stock_quantity: e.stockQuantity,
              change: e?.change,
              operators: e?.operators ? e?.operators : "+",
            })),
        },
        {
          category: "potoro",
          records: potoroList
            // .filter((e: any) => e.amend)
            .map((e: any, idx) => ({
              id: e.id,
              position: idx,
              stock_quantity: e.stockQuantity,
              change: e?.change,
              operators: e?.operators ? e?.operators : "+",
            })),
        },
      ],
    };
    const result = await apiFactory.facilityApi.updatePositionAndStock(body);
    if (result?.success) {
      fetchData();
      toast.success(result?.success);
    } else {
      toast.error("Error !");
    }
    setSubmitting(false)
  };

  const fetchData = async () => {
    const result = await apiFactory.facilityApi.getList({}, "");
    let equipments = result?.serialized_equipment_services.find(
      (e: any) => e?.equipment
    );

    if (equipments) {
      equipments = equipments?.equipment?.map((e: any) => ({
        key: `${e.position}`,
        id: e.id,
        displayName: e.display_name,
        serviceName: e.service_name,
        unitPrice: e.unit_price,
        stockQuantity: e.stock_quantity,
        stockChange: e.unit_price,
        position: e.position,
        category: "equipment",
        stockQuantityChange: e.stock_quantity,
        amend: false,
      }));
    } else {
      equipments = [];
    }

    let services = result?.serialized_equipment_services.find(
      (e: any) => e?.service
    );
    if (services) {
      services = services?.service?.map((e: any) => ({
        key: `${e.id}`,
        id: e.id,
        displayName: e.display_name,
        serviceName: e.service_name,
        unitPrice: e.unit_price,
        stockQuantity: e.stock_quantity,
        stockChange: e.unit_price,
        position: e.position,
        category: "service",
        amend: false,
      }));
    } else {
      services = [];
    }

    let potoros = result?.serialized_equipment_services.find(
      (e: any) => e?.potoro
    );
    if (potoros) {
      potoros = potoros?.potoro?.map((e: any) => ({
        key: `${e.id}`,
        id: e.id,
        displayName: e.display_name,
        serviceName: e.service_name,
        unitPrice: e.unit_price,
        stockQuantity: e.stock_quantity,
        stockChange: e.unit_price,
        position: e.position,
        category: "potoro",
        amend: false,
      }));
    } else {
      potoros = [];
    }
    setEquipmentList(equipments);
    setServiceList(services);
    setPotoroList(potoros);
    setOriginList(result?.serialized_equipment_services);
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <>
      <div className="page-title">管理メニュー　備品・サービス登録</div>
      <div className="page-container facility-registration-detail">
        <div className="pt-[7px]">
          <div className="text-end mb-[30px]">
            <ButtonSolid
              className="!h-[35px] !px-[54px] !text-[14px] !leading-[24px]"
              onClick={() =>
                navigate(`/${ROUTES.ADMIN}/${ROUTES.FACILITY_REGISTRATION_ADD}`)
              }
            >
              新規追加
            </ButtonSolid>
          </div>
          <DragTable
            title={"■備品　一覧"}
            dataList={equipmentList}
            setDataList={setEquipmentList}
            originList={originList}
          />
          <DragTable
            title={"請求用サービス　一覧"}
            dataList={serviceList}
            setDataList={setServiceList}
            originList={originList}
          />
          <DragTable
            title={"■ぽとろ　一覧"}
            dataList={potoroList}
            setDataList={setPotoroList}
            originList={originList}
          />
          <Row>
            <Col span={13} />
            <Col span={11}>
              <div className="flex items-center justify-between">
                <div className="flex items-center text-[12px] leading-[20px] gap-x-[6px]"></div>
                <div className="flex gap-x-[8px]">
                  <ButtonOutline
                    className="!h-[35px] !px-[16px] !text-[14px] !leading-[24px]"
                    onClick={fetchData}
                  >
                    キャンセル
                  </ButtonOutline>
                  <Button className="save-button" onClick={onSave} loading={submitting}>
                    保存
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default FacilityList;
