import { Button, Checkbox, Form, Input, Select, Spin } from "antd";
import { Option } from "antd/es/mentions";
import apiFactory from "api";
import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "store";
import "./style.scss";
import CustomPagination from "component/CustomPagination";
import {
  DEFAULT_PAGE,
  DEFAULT_PAGE_OPTIONS,
  MAX_QUERY_PER_PAGE,
} from "constant/apiRequest";
import { Flex } from "@chakra-ui/react";
import useResponsive from "hook/useResponsive";
import { debounce } from "lodash";
import { ETargetType } from "enums/contact";
import { ButtonSolid } from "component/button";

interface IUser {
  label?: string;
  value?: number;
  isAdmin?: boolean;
}

const DEFAULT_PAGE_SIZE = 10;
const SEPERATOR = "_";

const AsyncSelect = ({ chooseUser, setUsersSelect }: any) => {
  const [limit, setLimit] = useState(DEFAULT_PAGE_SIZE);
  const [page, setPage] = useState(DEFAULT_PAGE);
  const [totalItems, setTotalItems] = useState<number>(0);
  // const [currentTotal, setCurrentTotal] = useState(0)
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<Array<any>>([]);
  const { isMobile } = useResponsive();

  const fetchUserList = async () => {
    const data = await apiFactory.staffNursingApi.getList({
      per: limit,
      page: page,
      is_send_message: true,
    });
    if (data) {
      const users = [
        ...data?.nursing_staffs?.map((item: IUser) => ({
          ...item,
          isAdmin: false,
        })),
        ...data?.user_admins?.map((item: IUser) => ({
          ...item,
          isAdmin: true,
        })),
      ];

      setUsers(
        users?.map((e: any) => (
          <Option
            key={e.id}
            value={`${e?.user_code || e?.nurse_code}_${e?.id}_${e?.isAdmin}`}
          >
            {e?.family_name || e?.user_name || ""}
          </Option>
        ))
      );
      setTotalItems(data.total_items);
    }
  };

  const handleScroll = async (event: React.UIEvent<HTMLElement>) => {
    if (
      event.currentTarget.scrollTop + event.currentTarget.clientHeight >=
        event.currentTarget.scrollHeight &&
      page * limit < totalItems &&
      !loading
    ) {
      users.push(
        <Option key={"loading"} value={"loading"} disabled>
          <Spin className="absolute left-[50%]" />
        </Option>
      );
      setUsers([...users]);
      setLoading(true);
      setTimeout(async () => {
        const data = await apiFactory.nursingApi.getList({
          per: limit,
          page: page + 1,
        });
        if (data) {
          users.pop();
          const newUsers = users.concat(
            data.nursing_staffs.map((e: any) => (
              <Option key={e.id} value={e.id}>
                {e.family_name}
              </Option>
            ))
          );
          setUsersSelect(newUsers.map((item) => item?.props));
          setUsers(newUsers);
          setPage(page + 1);
          setTotalItems(data.total_items);
        }
        setLoading(false);
      }, 500);
    }
  };

  useEffect(() => {
    fetchUserList();
  }, []);

  return (
    <Select
      style={{
        width: isMobile ? 140 : 180,
      }}
      onChange={(e: any) => chooseUser(e)}
      onPopupScroll={handleScroll}
      placeholder="スタッフ名"
      className="[&_.ant-select-selector]:!border-[#d9d9d9] [&_.ant-select-selector]:!rounded-[4px]"
    >
      {users}
    </Select>
  );
};

const ContactList = ({ setPart, setContactId, setContactName }: any) => {
  //--State
  const navigate = useNavigate();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [contacts, setContacts] = useState<[]>([]);
  const [usersSelect, setUsersSelect] = useState<Array<IUser>>([]);
  const [choosedUser, setChoosedUser] = useState<{
    id: number;
    isAdmin?: boolean;
  }>({ id: 0 });
  const [chatSearch, setChatSearch] = useState("");
  const [unread, setUnread] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [form] = Form.useForm();
  const { id: userId } = useSelector((state: RootState) => state.user);
  const [submitting, setSubmitting] = useState(false)

  //-- Get list contacted
  const fetchContactList = async () => {
    const data = await apiFactory.staffContactApi.getContactList({
      per: limit,
      page: page,
      unread: unread,
      search: chatSearch,
    });
    if (data) {
      setContacts(data.rooms);
      setTotalItems(data?.total_items);
    }
  };

  const handleSearch = debounce(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      setLimit(DEFAULT_PAGE_SIZE);
      setPage(DEFAULT_PAGE);
      const data = await apiFactory.staffContactApi.getContactList({
        per: limit,
        page: page,
        unread: unread,
        search: event.target.value,
      });
      if (data) {
        setContacts(data.rooms);
        setTotalItems(data?.total_items);
      }
    },
    400
  );

  const fetchUserList = async () => {
    const data = await apiFactory.staffNursingApi.getList({
      per: limit,
      page: page,
      is_send_message: true,
    });
    if (data) {
      const users = [
        ...data?.nursing_staffs,
        ...data?.user_admins?.map((item: IUser) => ({
          ...item,
          isAdmin: true,
        })),
      ];

      setUsersSelect(
        users?.map((item) => ({
          label: item?.family_name || item?.user_name,
          value: item?.id,
          isAdmin: !!item?.isAdmin,
        }))
      );
    }
  };

  const chooseUser = (e: any) => {
    setChoosedUser({
      ...choosedUser,
      id: parseInt(e?.split(SEPERATOR)?.[1]),
      isAdmin: JSON.parse(e?.split(SEPERATOR)?.[2]) ?? false,
    });
  };

  const handleCreateNewRoom = async () => {
    try {
      setSubmitting(true)
      if (choosedUser.id !== 0) {
        const { user_admins } = await apiFactory.staffNursingApi.getList({
          per: MAX_QUERY_PER_PAGE,
          page: 1,
          is_send_message: true,
        });

        const isAdmin = user_admins
          ?.map((user: any) => user?.id)
          ?.includes(choosedUser);

        const res = await apiFactory.staffContactApi.createRoom({
          target_id: choosedUser?.id,
          target_type: choosedUser?.isAdmin
            ? ETargetType.USER_ADMIN
            : ETargetType.NURSING_STAFF,
        });

        if (res) {
          const userName = isAdmin
            ? res?.source_id === userId
              ? res?.target?.user_name
              : res?.source?.user_name
            : res.source_id === userId
            ? res?.target?.family_name
            : res?.source?.family_name;

          navigate(`/staff/contact-chat/${res?.id}`, {
            state: {
              title: userName,
            },
          });
        }
      } else {
        setErrorMsg("この項目は必須です");
      }
    } catch (err) {
      console.error({ err });
    } finally {
      setSubmitting(false)
    }
  };

  const onRead = (e: any) => {
    setUnread(e.target.checked);
    fetchContactList();
  };

  const numberOfPages = Math.ceil(Number(totalItems) / limit);

  const handlePrevPage = () => {
    if (page <= 1) {
      return;
    }

    setPage((prev) => prev - 1);
  };

  const handleNextPage = () => {
    if (page >= numberOfPages) {
      return;
    }

    setPage((prev) => prev + 1);
  };

  const renderListContact = () => {
    return (
      <div>
        {contacts.map((e: any, index: number) => {
          const roomName =
            e.source_id === userId
              ? e.target?.user_name || e?.target?.family_name
              : e.source?.user_name || e?.source?.family_name;

          return (
            <div
              className="text-[14px] border-t border-top-[#d5d5d5] py-[12px] cursor-pointer"
              key={e.id || index}
            >
              {e.is_unread ? (
                <div className="h-[12px] w-[12px] bg-black rounded-[50%] inline-block mr-[16px]" />
              ) : (
                <div className="white-dot" />
              )}

              <span
                onClick={() => {
                  apiFactory.staffContactApi.readMessage(e?.id);
                  navigate(`/staff/contact-chat/${e.id}`, {
                    state: {
                      title: roomName,
                    },
                  });
                }}
              >
                <span className="font-[700] text-[16px]"> {roomName}</span>
                &nbsp;
                <span className="text-[14px]">{e.lastest_send_at}</span>
              </span>
            </div>
          );
        })}
      </div>
    );
  };

  const handleChangePageSize = (_: number, pageSize: number) => {
    setLimit(pageSize);
  };

  const handleChangePage = (page: number) => {
    setPage(page);
  };

  useEffect(() => {
    fetchContactList();
    fetchUserList();
  }, [unread, limit, page]);

  return (
    <div className="lg:px-[24px] lg:py-[40px]">
      <div className="page-container text-left contact-list">
        <div className="text-end mt-[19px] mb-[26px] flex justify-between flex-wrap items-center">
          <Fragment>
            <Flex className="gap-x-[21px] max-md:w-full">
              <Form.Item
                name="searchKeyword"
                className="w-[calc(100vw-40px)] lg:w-[240px]"
              >
                <Input
                  className="border !border-[#d9d9d9] !rounded-[4px]"
                  placeholder="内容を入力して検索する"
                  value={chatSearch}
                  onChange={handleSearch}
                />
              </Form.Item>

              <Checkbox
                className="flex items-center max-md:hidden"
                onChange={(e) => {
                  setLimit(DEFAULT_PAGE_SIZE);
                  setPage(DEFAULT_PAGE);
                  setUnread(e.target.checked);
                }}
              >
                未読のみ
              </Checkbox>
            </Flex>
          </Fragment>

          <Flex className="items-center gap-x-[12px] max-md:justify-between max-md:w-full max-md:mt-[12px]">
            <Checkbox
              className="flex items-center md:hidden whitespace-nowrap"
              onChange={(e) => {
                setLimit(10);
                setPage(1);
                setUnread(e.target.checked);
              }}
            >
              未読のみ
            </Checkbox>

            <Form.Item label="" name="user" className="relative">
              <AsyncSelect
                setUsersSelect={setUsersSelect}
                chooseUser={chooseUser}
              />
              <p className="text-[red] m-0  absolute bottom-[-22px] right-0">
                {errorMsg && errorMsg}
              </p>
            </Form.Item>
            <ButtonSolid
              className="new-contact-button"
              onClick={handleCreateNewRoom}
              isLoading={submitting}
            >
              新規連絡
            </ButtonSolid>
          </Flex>
        </div>

        <Fragment>
          <div className="flex justify-end">
            {contacts.length > 0 && (
              <div className="mb-[16px] max-md:w-[100%] text-sm">
                <CustomPagination
                  current={page}
                  perPage={limit}
                  total={totalItems}
                  pageSizeOptions={DEFAULT_PAGE_OPTIONS}
                  setCurrent = {setPage}
                  setPerPage={setLimit}
                 // showSizeChanger
                  // onNext={handleNextPage}
                  // onPrev={handlePrevPage}
                  //onShowSizeChange={handleChangePageSize}
                 // className="px-0"
                  //onChange={handleChangePage}
                />
              </div>
            )}
          </div>

          {renderListContact()}
        </Fragment>
      </div>
    </div>
  );
};
export { ContactList };
