import { Button, Checkbox, Form, Input, Select, TableProps } from "antd";
import nursingCareRecordApi from "api/admin/nursingCareRecord";
import { FlexRow } from "component/StyledComponent";
import TableAdmin from "component/admin/table";
import RecordHistoryCustomerPDFForm from "component/form/RecordHistoryCustomerPDFForm";
import { DATE_FORMAT_2, TIME_FORMAT } from "constant/date";
import ROUTES from "constant/routes";
import dayjs from "dayjs";
import "dayjs/locale/ja";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import usePrint from "hook/usePrint";
import moment from "moment";
import "moment-timezone";
import "moment/locale/ja";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams, useLocation, createSearchParams } from "react-router-dom";
import { RootState } from "store";
import { getCookie } from "utils";
import { TIMEJAPAN } from "utils/constant";
import StyleFilterHistory from "../styled";
import "../table.scss";
import dataFields from "./_config";
import { ButtonSolid } from "component/button";
import _ from "lodash";
import { CARE_PLAN_TYPE_OPTIONS as data } from "constant"
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault(TIMEJAPAN);

export const getTypeCarePlan = (type: string) => {
  switch (type) {
    case "介護":
      return "NursingCarePlan";
    case "障害":
      return "DisabilityCarePlan";
    default:
      return null;
  }
};

export const getSortType = (value: any) => {
  switch (value) {
    case 1:
      return "ascend";
    case 2:
      return "descend";
    default:
      return null;
  }
};

export const getTypeDivision = (value: string) => {
  switch (value) {
    case "normal":
      return "介護";
    case "disability":
      return "障害";
    default:
      return "normal";
  }
};

export const getTypeCarePlanJP = (value: string) => {
  switch (value) {
    case "NursingCarePlan":
      return "介護";
    case "DisabilityCarePlan":
      return "障害";
    default:
      return "介護";
  }
};

const getCellValue = (
  value = "--",
  unit = "",
  key?: keyof typeof dataFields
) => {
  return (
    <>
      {key && value !== "--" && value
        ? dataFields[key].options.find((item) => item.value === value)?.label
        : value
        ? value
        : "--"}
      {value && value !== "--" && unit ? ` ${unit}` : ""}
    </>
  );
};

const hasEnoughData = (record: any) => {
  return (
    record?.nursing_care_history?.complexion ||
    record?.nursing_care_history?.sweating ||
    record?.nursing_care_history?.temperature ||
    record?.nursing_care_history?.blood_pressure ||
    record?.nursing_care_history?.hydration?.toString() ||
    record?.nursing_care_history?.full_body_bath ||
    record?.nursing_care_history?.nurse?.family_name
  );
};

const TableRecordCustomer = ({ dateparam }: any) => {
  const { id } = useParams();
  const [value, setValue] = useState("");
  const [dataTable, setDataTable]: any = useState([]);
  const [dataPrint, setDataPrint]: any = useState(null);
  const { ref, handlePrint } = usePrint(true);
  const navigate = useNavigate();
  const idToken = getCookie("access_token");
  const user = useSelector((state: RootState) => state.user);
  const [total, setTotal] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [filter, setFilter] = useState({
    patient_id: id,
    start_date: dateparam ? moment(dateparam).format(DATE_FORMAT_2) : "",
    end_date: dateparam ? moment(dateparam).format(DATE_FORMAT_2) : "",
    care_plan_type: "",
    tab: "patient",
    nurse_id: "",
    page: 1,
    per: 10,
    sortKey: "",
    order: "",
  });
  const [isReadyPrint, setIsReadyPrint] = useState(false)
  const location = useLocation()

  const [form] = Form.useForm();

  const getRecordCustomer =
    //  useCallback(
    async (args?: any, isPrint = false) => {
      let _filter = { ...filter, is_print: isPrint, ...(args && args) };
      setFilter(_filter);
      setLoading(true);

      if (!_filter.care_plan_type) delete _filter.care_plan_type;

      try {
        const res = await nursingCareRecordApi.getNursingCareHistory(
          idToken,
          _filter,
          user?.role
        );
        setLoading(false);
        if (isPrint && res) {
          setDataPrint(res.serialized_nursing_care_histories);
          return;
        }

        if (_filter.care_plan_type) {
          setValue(
            data.find((item) => item.value === _filter.care_plan_type)
              ?.content || ""
          );
        }

        if (res) {
          setTotal(res.total_items);
          setDataTable(res.serialized_nursing_care_histories);
        }
      } catch (error) {
        setLoading(false);
      }
    };

  useEffect(() => {
    getRecordCustomer({
      start_date: dateparam ? moment(dateparam).format(DATE_FORMAT_2) : "",
      end_date: dateparam ? moment(dateparam).format(DATE_FORMAT_2) : "",
    });
  }, []);

  useEffect(() => {
    moment.locale("ja");
  }, []);

  useEffect(() => {
    if (dataPrint && isReadyPrint) {
      handlePrint();
      setDataPrint(null);
    }
  }, [dataPrint, isReadyPrint]);

  const columns: TableProps<any>["columns"] = [
    {
      title: "日付",
      dataIndex: "date",
      render: (value) => (
        <span className="whitespace-break-spaces">
          {moment(value).format("YYYY/MM/DD\n (ddd)")}
        </span>
      ),
      sorter: true,
    },
    {
      title: "時間",
      dataIndex: "time-range",
      render: (_, record) => (
        <>{record.time_range}</>
      ),
      sorter: true,
    },
    {
      title: "区分",
      render: (_, record) => (
        <>{getTypeCarePlanJP(record?.scheduleable?.care_plan_type)}</>
      ),
    },
    {
      title: "顔色",
      render: (_, record) => (
        <>
          {getCellValue(
            record?.nursing_care_history?.complexion,
            "",
            "complexion"
          )}
        </>
      ),
    },
    {
      title: "発汗",
      render: (_, record) => (
        <>
          {getCellValue(record?.nursing_care_history?.sweating, "", "sweating")}
        </>
      ),
    },
    {
      title: "体温",
      render: (_, record) => (
        <>{getCellValue(record?.nursing_care_history?.temperature, "℃")}</>
      ),
    },
    {
      title: "血圧",
      render: (_, record) => (
        <>{getCellValue(record?.nursing_care_history?.blood_pressure)}</>
      ),
    },
    {
      title: "排尿",
      render: (_, record) => (
        <>
          {getCellValue(
            record?.nursing_care_history?.urination?.toString() || "0",
            "回"
          )}
        </>
      ),
    },
    {
      title: "排便",
      render: (_, record) => (
        <>
          {getCellValue(
            record?.nursing_care_history?.defecation?.toString() || "0",
            "回"
          )}
        </>
      ),
    },
    {
      title: "水分",
      render: (_, record) => (
        <>
          {getCellValue(
            record?.nursing_care_history?.hydration?.toString(),
            "cc"
          )}
        </>
      ),
    },
    {
      title: "全身浴",
      render: (_, record) => (
        <>{getCellValue(record?.nursing_care_history?.full_body_bath)}</>
      ),
    },
    {
      title: "担当者",
      render: (_, record) => (
        <>{getCellValue(record?.nursing_care_history?.nurse?.family_name)}</>
      ),
    },
    {
      title: "",
      render: (_, item) => (
        <div>
          {hasEnoughData(item) ? (
            <Button
              shape="round"
              className="border-primary text-primary"
              onClick={() =>
                navigate({
                  pathname: `/${ROUTES.ADMIN}/${ROUTES.CUSTOMER}/${ROUTES.CREATE_RECORD_DAILY}/${item?.scheduleable?.patient_id}`,
                  search: createSearchParams({
                    schedule_date_id: item?.id,
                    date: moment(item?.date).format(DATE_FORMAT_2),
                    start_time: item.nursing_care_history.start_time_format,
                    end_time: item.nursing_care_history.end_time_format,
                    type: "edit",
                    patient: item?.scheduleable?.patient?.family_name,
                    //staff_id: item?.nursing_care_history?.nurse?.id,
                    redirectPath: location.pathname + location.search,
                    isHistory: "true"
                  }).toString(),
                })
              }
            >
              登録済
            </Button>
          ) : (
            <Button
              onClick={() =>
                navigate({
                  pathname: `/${ROUTES.ADMIN}/${ROUTES.CUSTOMER}/${ROUTES.CREATE_RECORD_DAILY}/${item?.scheduleable?.patient_id}`,
                  search: createSearchParams({
                    schedule_date_id: item?.id,
                    date: moment(item?.date).format(DATE_FORMAT_2),
                    start_time: item.nursing_care_history.start_time_format,
                    end_time: item.nursing_care_history.end_time_format,
                    scheduleable: item?.scheduleable.id,
                    type: "edit",
                    division: item?.scheduleable?.care_plan_type,
                    patient: item?.scheduleable?.patient?.family_name,
                    redirectPath: location.pathname + location.search,
                  }).toString(),
                })
              }
              shape="round"
              type="primary"
              className="mr-2"
            >
              登録
            </Button>
          )}
          <Button
            className="ml-2"
            onClick={() => setDataPrint([item])}
            shape="round"
            type="primary"
          >
            出力
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div className="w-full bg-white mb-6">
      {dataPrint && (
        <RecordHistoryCustomerPDFForm
          ref={ref}
          setIsReadyPrint={setIsReadyPrint}
          dataPrint={dataPrint ? dataPrint : []}
        />
      )}

      <FlexRow className="justify-between mb-4 mt-3 text-xs">
        <StyleFilterHistory>
          <Form
            autoComplete="off"
            className="flex gap-2 flex-wrap"
            onFinish={getRecordCustomer}
          >
            <Form.Item className="min-w-[173px]" name={"care_plan_type"}>
              <Select
                placeholder="区分"
                onChange={(value) =>
                  setFilter((prev) => ({ ...prev, care_plan_type: value }))
                }
                allowClear
                options={data.map((item) => ({
                  label: item.content,
                  value: item.value,
                }))}
              />
            </Form.Item>

            <Form.Item name={"no_care_history"} valuePropName="checked">
              <Checkbox>未記入</Checkbox>
            </Form.Item>

            <Button htmlType="submit" type="primary">
              検索
            </Button>
          </Form>
        </StyleFilterHistory>

        {value && (
          <ButtonSolid
            fontSize="14px"
            width="101px"
            height="35px"
            onClick={() => getRecordCustomer({}, true)}
          >
            PDF保存
          </ButtonSolid>
        )}
      </FlexRow>
      <Form form={form} onFinish={getRecordCustomer}>
        <TableAdmin
          form={form}
          data={dataTable}
          columns={columns}
          loading={loading}
          total={total}
          bordered={false}
        />
      </Form>
    </div>
  );
};

export default TableRecordCustomer;
