import BaseApi from "api/baseApi";
import { objectToFormData } from "utils/data";
import { STAFF_EVALUE_ONES } from "../apiConstant";
import { IEvalueOne } from "types/admin";

type IEVALUE_PAGINATION = {
  page?: number | string;
  per_page?: number | string;
};

class evalueApi extends BaseApi {
  getEvalue<T>(params: IEVALUE_PAGINATION & { patient_id: number | string }) {
    return this.get<T>(STAFF_EVALUE_ONES, params);
  }

  getEvalueDetail<T>(id: number | string) {
    return this.get<T>(STAFF_EVALUE_ONES + `/${id}`);
  }
}

export default evalueApi;
