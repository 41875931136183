import React, { useEffect } from "react";
import { Form, FormInstance } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Text } from "@chakra-ui/react";

interface Props {
  total: number;
  form: FormInstance;
  perInit?: number;
  pageInit?: number;
}
const SimplePagination: React.FC<Props> = ({
  total,
  form,
  perInit = 10,
  pageInit = 1,
}) => {
  const page = Form.useWatch("page", form) || pageInit;
  const per = Form.useWatch("per", form) || perInit;
  const numberOfPages = Math.ceil(total / per);

  const handleNextPage = () => {
    if (page === numberOfPages) return;
    form.setFieldValue("page", page + 1);
    form.submit();
  };
  const handlePrevPage = () => {
    if (page === 1) return;
    form.setFieldValue("page", page - 1);
    form.submit();
  };

  return (
    <div className="flex justify-end">
      <Text color="#000000D9">
        {(page - 1) * per + 1} ~ {page * per}件(全{total}件中)
      </Text>

      <div className="flex ml-8">
        <div
          style={{
            color: page === 1 ? "#666666" : "black",
          }}
          className="w-12 text-center cursor-pointer"
          onClick={handlePrevPage}
        >
          <LeftOutlined />
        </div>

        <div
          className="w-12 text-center cursor-pointer"
          style={{
            color: page === numberOfPages ? "#666666" : "black",
          }}
          onClick={handleNextPage}
        >
          <RightOutlined />
        </div>
      </div>

      {form && (
        <>
          <Form.Item hidden name="sortKey" children={<div />} />
          <Form.Item
            hidden
            initialValue={pageInit}
            name="page"
            children={<div />}
          />
          <Form.Item
            hidden
            initialValue={perInit}
            name="per"
            children={<div />}
          />
          <Form.Item hidden name="order" children={<div />} />
        </>
      )}
    </div>
  );
};

export default SimplePagination;
