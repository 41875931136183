import styled from "@emotion/styled";
const Styles = styled.div`
  .ant-row {
    display: flex;
  }

.ant-form-item.mini_mobile .ant-form-item-label > label {
  min-width: 50px;
}
`;
export default Styles;
