import styled from "@emotion/styled";
import { Table } from "antd";

const TableStyled = styled(Table)`
  .ant-table-container {
    border-inline-start: 1px solid #010a021f !important;
    border-top: 1px solid #010a021f !important;
  }

  tr td,
  tr th {
    border-color: #010a021f !important;
    padding: 0 !important;
  }
`;

export default TableStyled;
