export const formatNumber = (val: string) => {
  return val.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const formatNumberFullWidthToHalfWidth = (str: string): string =>
  str.replace(/[０-９]/g, (char) => 
      String.fromCharCode(char.charCodeAt(0) - 0xFEE0)
  );

export const extractNumbers = (str: string): string => {
   // Convert full-width numbers (e.g. '５') to half-width (e.g. '5')

  // Assuming you already have a logic to extract numbers after the conversion
  return str.replace(/[^０-９.-] | [^0-9.-]]/g, ''); // Adjust regex based on your needs
}