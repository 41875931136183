import React from "react";

const CircleWarning = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 73 71"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M36.5002 65.9168C53.2988 65.9168 66.9168 52.2988 66.9168 35.5002C66.9168 18.7015 53.2988 5.0835 36.5002 5.0835C19.7015 5.0835 6.0835 18.7015 6.0835 35.5002C6.0835 52.2988 19.7015 65.9168 36.5002 65.9168Z"
        stroke="#D83535"
        stroke-width="3.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M36.5 47.6667V35.5"
        stroke="#D83535"
        stroke-width="3.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M36.5 23.3335H36.5304"
        stroke="#D83535"
        stroke-width="3.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default CircleWarning;
