import { Button, DatePicker, Form, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import apiFactory from "api";
import { ButtonSolid } from "component/button";
import {
  DATE_FORMAT_2,
  JP_DATEPICKER_FORMAT,
  disablePastDates,
} from "constant/date";
import MESSAGES from "constant/messages";
import REGEX from "constant/regex";
import dayjs, { Dayjs } from "dayjs";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { notificationCategoryOptions } from "utils/notification";
import { useQuery } from "react-query";
import { IPatient } from "constant/interfaces";
import ROUTES from "constant/routes";
import { isString } from "lodash";
import { MAX_QUERY_PER_PAGE } from "constant/apiRequest";

interface INotificationFormProps {
  formType: "important" | "conveyed";
}

interface IFormValues {
  patient_id: number;
  deployment_date: Dayjs;
  notification_category_id: number;
  content: string;
}

const NotificationForm: React.FC<INotificationFormProps> = (props) => {
  const { formType } = props;
  const [form] = Form.useForm<IFormValues>();
  const navigate = useNavigate();
  const [submitting, setSubmitting] = useState(false)

  const { data: patientsData } = useQuery<{ patients: IPatient[] }, Error>(
    ["patients"],
    () => apiFactory.patientStaffApi.getList({ per: MAX_QUERY_PER_PAGE })
  );

  const patientOptions = useMemo(() => {
    return patientsData?.patients?.map((item: IPatient) => ({
      label: item?.first_name + " " + item?.last_name,
      value: item?.id,
    }));
  }, [patientsData]);

  const handleCreateNotification = async (formValues: IFormValues) => {
    const deploymentDate = dayjs(formValues?.deployment_date).format(
      DATE_FORMAT_2
    );

    try {
      setSubmitting(true)
      const result = await apiFactory.staffNoticeApi.createNotice({
        ...formValues,
        deployment_date: deploymentDate,
      });
      if (result) {
        isString(result.success) && toast.success(result.success);
        navigate("/staff/" + ROUTES.BULLETINBOARD);
      }
    } catch (error) {
      console.error({ error });
    } finally {
      setSubmitting(false)
    }
  };

  const handleCreateNotificationGroup = async (formValues: IFormValues) => {
    try {
      setSubmitting(true)
      const result = await apiFactory.staffNoticeApi.createNoticeGroup({
        notification_category_id: formValues?.notification_category_id!,
        content: formValues?.content,
      });
      if (result) {
        isString(result.success) && toast.success(result.success);
        navigate("/staff/" + ROUTES.BULLETINBOARD);
      }
    } catch (error) {
      console.error({ error });
    } finally {
      setSubmitting(false)
    }
  };

  const handleSubmitData = async (formValues: IFormValues) => {
    if (formType === "important") {
      await handleCreateNotification(formValues);

      return;
    }

    await handleCreateNotificationGroup(formValues);
  };

  if (formType === "conveyed") {
    return (
      <Form form={form} onFinish={handleSubmitData}>
        <Form.Item
          label="カテゴリー"
          name="notification_category_id"
          rules={[{ required: true, message: MESSAGES.requireField }]}
        >
          <Select
            options={notificationCategoryOptions}
            allowClear
            placeholder="選択してください"
          />
        </Form.Item>
        <Form.Item
          label="内容"
          name="content"
          rules={[{ required: true, message: MESSAGES.requireField }]}
        >
          <TextArea />
        </Form.Item>

        <div className="flex items-center justify-end">
          <Button
            className="!h-[35px] !px-[16px] !text-[14px] !leading-[24px] w-[160px]"
            onClick={() => navigate("/staff/bulletin-board")}
          >
            キャンセル
          </Button>
          <ButtonSolid
            type="submit"
            className="!h-[35px] !px-[54px] !text-[14px] !leading-[24px] w-[160px] ml-3"
            isLoading={submitting}
          >
            保存
          </ButtonSolid>
        </div>
      </Form>
    );
  }

  return (
    <Form form={form} onFinish={handleSubmitData}>
      <Form.Item
        label="お客様名"
        name="patient_id"
        rules={[{ required: true, message: MESSAGES.requireField }]}
      >
        <Select
          options={patientOptions}
          allowClear
          placeholder="選択してください"
        />
      </Form.Item>
      <Form.Item
        label="展開日"
        name="deployment_date"
        rules={[
          { required: true, message: MESSAGES.requireField },
          {
            pattern: REGEX.atLeastOneNonSpaceCharacter,
            message: MESSAGES.requireField,
          },
        ]}
      >
        <DatePicker
          format={JP_DATEPICKER_FORMAT}
          className="w-full"
          placeholder="選択してください"
          disabledDate={disablePastDates}
        />
      </Form.Item>
      <Form.Item
        label="内容"
        name="content"
        rules={[{ required: true, message: MESSAGES.requireField }]}
      >
        <TextArea />
      </Form.Item>

      <div className="flex items-center justify-end">
        <Button
          className="!h-[35px] !px-[16px] !text-[14px] !leading-[24px] w-[160px]"
          onClick={() => navigate("/staff/bulletin-board")}
        >
          キャンセル
        </Button>
        <ButtonSolid
          type="submit"
          className="!h-[35px] !px-[54px] !text-[14px] !leading-[24px] w-[160px] ml-3"
          isLoading={submitting}
        >
          保存
        </ButtonSolid>
      </div>
    </Form>
  );
};

export default NotificationForm;
