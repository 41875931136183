// page 60 https://xd.adobe.com/view/ef189247-c8fd-4c31-b643-d4151912ea88-ba4b/screen/e3e5f7bf-e88b-4d3d-b07b-741ff9c0a215/specs/
import { Button, Tab } from "@chakra-ui/react";
import { Checkbox, Form, Input, Modal, Spin, Table } from "antd";
import { ColumnType } from "antd/es/table";
import Column from "antd/es/table/Column";
import Plus from "assets/plus.png";
import Minus from "assets/minus.png";
import { NumericFormat } from "react-number-format";
import { ButtonOutline, ButtonSolid } from "component/button";
import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useNavigate, useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import useService from "hook/useService";
import axiosClient from "api/axiosClient";
import { IPatient } from "constant/interfaces";
import { toast } from "react-toastify";
import ROUTES from "constant/routes";
import MESSAGES from "constant/messages";

interface IPopupState {
  rowIndex: number;
  type: "bill-upload" | "bill-delete" | "bill-preview";
  files?: File[];
  error?: string;
}
interface IReceipt {
  id?: number;
  price?: number | string;
  files?: File[];
  previewURL?: string;
  initialFileData: any;
}
export default function ReceiptRegister() {
  const [isAllowUpdate, setIsAllowUpdate] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const service = useService();
  const [searchParams] = useSearchParams();
  const month = dayjs(searchParams.get("month") || Date.now());
  const patient_id = searchParams.get("patient_id");
  const [patientData, setPatientData] = useState<IPatient>();
  const [form] = Form.useForm();
  const receipts: IReceipt[] = Form.useWatch("receipts", form);
  const totalAmount = receipts?.reduce((a, b) => a + +(b?.price || 0), 0);
  const [popupState, setPopupState] = React.useState<IPopupState | undefined>();
  const [deletedRowIds, setDeletedRowIds] = useState<number[]>([]);
  const [deletedFileIds, setDeletedFileIds] = useState<number[]>([]);
  const [submitting, setSubmitting] = useState(false)

  const onDrop = (files: File[]) => {
    if (!files[0])
      setPopupState(
        (prev) =>
          prev && {
            ...prev,
            files: undefined,
            error: MESSAGES.invalidFileFormat,
          }
      );
    else if (files[0].size > 1000000000)
      setPopupState(
        (prev) =>
          prev && {
            ...prev,
            files: undefined,
            error: MESSAGES.invalidFileCapacity_1GB,
          }
      );
    else
      setPopupState(
        (prev) => prev && { ...prev, files: files, error: undefined }
      );
  };
  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    accept: {
      "image/*": [],
    },
    onError: (err) => console.log(err),
    multiple: false,
  });
  const handleAddFile = () => {
    if (!popupState) return;
    form.setFieldValue(
      ["receipts", popupState.rowIndex, "files"],
      popupState.files
    );
    if (popupState.files)
      form.setFieldValue(
        ["receipts", popupState.rowIndex, "previewURL"],
        URL.createObjectURL(popupState.files[0])
      );
    setPopupState(undefined);
  };
  const handleRemoveFile = () => {
    if (!popupState) return;
    if (receipts[popupState.rowIndex].initialFileData?.[0]?.id)
      setDeletedFileIds((prev) => [
        ...prev,
        receipts[popupState.rowIndex].initialFileData?.[0]?.id,
      ]);
    form.setFieldValue(["receipts", popupState.rowIndex, "files"], undefined);
    form.setFieldValue(
      ["receipts", popupState.rowIndex, "previewURL"],
      undefined
    );
    setPopupState(undefined);
  };
  const fetchInitialData = async () => {
    setIsLoading(true);
    setDeletedFileIds([]);
    setDeletedRowIds([]);
    const { data } = await axiosClient({
      url: service.RECEIPTS + "/by_patient",
      params: {
        patient_id,
        year_month: month.format("YYYY/MM"),
      },
    });
    setIsLoading(false);
    setPatientData(data.patient);
    setIsAllowUpdate(!data.registered);
    form.setFieldValue("id", data.id);
    form.setFieldValue("registered", data.registered);
    setIsAllowUpdate(!data.registered);
    if (data.receipts?.length)
      form.setFieldValue(
        "receipts",
        data.receipts.map((item: any) => ({
          ...item,
          previewURL: item.files?.[0]?.file_path,
          files: [],
          initialFileData: item.files,
        }))
      );
  };
  const handleSubmit = async (formData: any) => {
    const data = {
      patient_id,
      registered: formData.registered,
      year_month: month.format("YYYY/MM"),
      receipts: formData.receipts.map((i: IReceipt) => ({
        price: i.price,
        receipt_id: i.id,
        files: i.files?.[0],
      })),
      deleted_ids: deletedRowIds,
      image_deleted_ids: deletedFileIds,
    };
    try {
      setSubmitting(true)
      const res = await axiosClient({
        url: service.RECEIPTS + (formData.id ? `/${formData.id}` : ""),
        method: formData.id ? "put" : "post",
        data,
        headers: { "Content-Type": "multipart/form-data" },
      });
      toast.success(res.data.success);
      navigate(`/${ROUTES.ADMIN}/${ROUTES.PAYMENT}`);
    } catch (error) {
      toast.error((error as string).toString());
    } finally {
      setSubmitting(false)
    }
  };
  useEffect(() => {
    fetchInitialData();
  }, []);
  return (
    <Spin spinning={isLoading}>
      <Form
        form={form}
        onFinish={handleSubmit}
        initialValues={{ receipts: [{}] }}
      >
        <div className="page-title">
          立替レシート登録 {month.format("YYYY/MM")}&nbsp;
          {patientData?.family_name || ""} 様
        </div>
        <div className="page-container">
          <Form.Item name="id" hidden>
            <div />
          </Form.Item>
          <Form.List name="receipts">
            {(field, { add, remove }) => (
              <>
                <Table
                  bordered
                  className="with-color-row"
                  dataSource={field}
                  pagination={false}
                  scroll={{ x: true }}
                  summary={() => (
                    <Table.Summary>
                      <Table.Summary.Row>
                        <Table.Summary.Cell index={0}>
                          合計金額
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={1} colSpan={1}>
                          <NumericFormat
                            customInput={Input}
                            addonAfter="円"
                            value={totalAmount}
                            readOnly
                          />
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={1} colSpan={2} />
                      </Table.Summary.Row>
                    </Table.Summary>
                  )}
                >
                  <Column
                    className="min-w-[100px]"
                    width={200}
                    title="項目名"
                    render={(value: any, record, index) => (
                      <>
                        レシート{index + 1}
                        <Form.Item name={["receipts", index, "id"]} hidden>
                          <div />
                        </Form.Item>
                      </>
                    )}
                  />
                  <Column
                    className="min-w-[200px]"
                    width={300}
                    title="利用金額"
                    render={(value: any, record, index) => (
                      <Form.Item name={[index, "price"]} className="mb-0 ">
                        <NumericFormat
                          readOnly={!isAllowUpdate}
                          customInput={Input}
                          addonAfter="円"
                        />
                      </Form.Item>
                    )}
                  />
                  {isAllowUpdate && (
                    <Column
                      className="min-w-[250px]"
                      title="レシート画像"
                      render={(value: any, record: any, index) =>
                        receipts?.[index]?.previewURL ? (
                          <div className="flex gap-5">
                            <ButtonSolid
                              onClick={() =>
                                setPopupState({
                                  rowIndex: index,
                                  type: "bill-delete",
                                })
                              }
                            >
                              画像削除
                            </ButtonSolid>
                            <ButtonOutline
                              onClick={() =>
                                setPopupState({
                                  rowIndex: index,
                                  type: "bill-preview",
                                })
                              }
                            >
                              プレビュー
                            </ButtonOutline>
                          </div>
                        ) : (
                          <ButtonOutline
                            hidden={!isAllowUpdate}
                            onClick={() =>
                              setPopupState({
                                rowIndex: index,
                                type: "bill-upload",
                              })
                            }
                          >
                            画像登録
                          </ButtonOutline>
                        )
                      }
                    />
                  )}
                  <Column
                    fixed="right"
                    align="right"
                    render={(value, record, index) =>
                      isAllowUpdate && (
                        <div className="flex justify-end">
                          <Button
                            onClick={() => add(undefined, index + 1)}
                            variant="outline"
                            transform="scale(0.75)"
                            borderRadius="50%"
                            width="40px"
                            height="40px"
                            p="0px"
                            border="1px solid #000"
                          >
                            <img
                              src={Plus}
                              alt=""
                              style={{ height: "20px", width: "20px" }}
                            />
                          </Button>
                          <Button
                            // onClick={() => field.length > 1 && remove(index)}
                            onClick={() => {
                              if (field.length <= 1) return;
                              remove(index);
                              if (receipts[index].id)
                                setDeletedRowIds((prev) => [
                                  ...prev,
                                  receipts[index].id as number,
                                ]);
                            }}
                            variant="outline"
                            transform="scale(0.75)"
                            borderRadius="50%"
                            width="40px"
                            height="40px"
                            p="0px"
                            border="1px solid #000"
                          >
                            <img
                              src={Minus}
                              alt=""
                              style={{ height: "20px", width: "20px" }}
                            />
                          </Button>
                        </div>
                      )
                    }
                  />
                </Table>
              </>
            )}
          </Form.List>
          <div className="text-right mt-10">
            <Form.Item
              hidden={!isAllowUpdate}
              noStyle
              name="registered"
              valuePropName="checked"
              initialValue={false}
            >
              <Checkbox>「登録済」にする</Checkbox>
            </Form.Item>
            <div className="flex justify-end gap-x-[8px] mt-3">
              <ButtonOutline
                onClick={() => navigate(-1)}
                className="!h-[35px] !px-[16px] !text-[14px] !leading-[24px]"
              >
                キャンセル
              </ButtonOutline>
              <ButtonSolid
                hidden={!isAllowUpdate}
                onClick={form.submit}
                className="!h-[35px] !px-[54px] !text-[14px] !leading-[24px]"
                isLoading={submitting}
              >
                登録
              </ButtonSolid>
            </div>
          </div>
        </div>
        {/* upload modal */}
        <Modal
          centered
          className="font-bold"
          title="画像登録"
          open={popupState?.type === "bill-upload"}
          okButtonProps={{
            hidden: !popupState?.files?.length,
          }}
          okText="保存"
          onOk={handleAddFile}
          cancelButtonProps={{ hidden: true }}
          onCancel={() => setPopupState(undefined)}
        >
          <div
            className="text-center"
            {...getRootProps()}
            onClick={(e) => e.preventDefault()}
          >
            <input {...getInputProps()} />
            {popupState?.files ? (
              <p>{popupState.files[0]?.name}</p>
            ) : (
              <>
                <p className="text-[16px]">画像ファイルをドロップ </p>
                <p>または</p>
              </>
            )}
            <ButtonOutline m={5} onClick={open}>
              {popupState?.files ? "別のファイルを選択" : "ファイルを選択"}
            </ButtonOutline>
            <p>JPEG・PNG・GIF形式に対応しています。</p>
            <p className="text-[red]">{popupState?.error}</p>
          </div>
        </Modal>
        {/* preview modal */}
        <Modal
          centered
          open={popupState?.type === "bill-preview"}
          cancelButtonProps={{ hidden: true }}
          okButtonProps={{ hidden: true }}
          onCancel={() => setPopupState(undefined)}
          closeIcon={false}
        >
          <img
            className="mt-2"
            width="100%"
            src={popupState && receipts[popupState?.rowIndex]?.previewURL}
          />
        </Modal>
        {/* delete modal */}
        <Modal
          title="警告"
          centered
          open={popupState?.type === "bill-delete"}
          okText="はい"
          cancelText="いいえ"
          onOk={handleRemoveFile}
          onCancel={() => setPopupState(undefined)}
        >
          <p>削除しますか。</p>
        </Modal>
      </Form>
    </Spin>
  );
}
