import DailyForm from "component/form/DailyForm";
import useResponsive from "hook/useResponsive";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";

const NursingCareDetail = () => {
  const [searchParams] = useSearchParams();
  const [canEdit, setCanEdit] = useState<boolean>(
    !JSON.parse(searchParams.get("isHistory") || "false")
  );
  const { isMobile } = useResponsive();

  const isHistory = JSON.parse(searchParams.get("isHistory") || "");

  return (
    <div className="px-[20px]">
      <div className={`page-title h-20 bg-white shadow-md pr-10 lg:w-full left-0 pl-[29px] lg:left-52 md:bg-light-default w-full h-16 opacity-100 fixed z-10 py-6 page-title-fixed top-6 z-[1] truncate ${isMobile ? '!text-[20px]' : ''}` }>{`${searchParams.get("patient") || ""} - ${
        searchParams.get("seriveName") || ""
      }`}</div>

      <div className="bg-white mt-20">
        <DailyForm
          wrapperClassName="mb-[70px] pb-[10px]"
          footerClassName="max-lg:bottom-[46px]"
          showServiceName={false}
          canEdit={canEdit}
          setCanEdit={setCanEdit}
          isHistory={isHistory}
          isStaff
        />
      </div>
    </div>
  );
};

export default NursingCareDetail;
