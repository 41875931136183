import { PinMap } from "assets/icons";
import { ButtonOutline } from "component/button";
import { Link } from "react-router-dom";
import { IPatient } from "constant/interfaces";
import { formatJapanDate } from "constant/date";

export default function MobileItemList(props: IPatient) {
  return (
    <div className="flex px-4 py-3 items-center justify-between border-b border-[#00000026]">
      <div>
        {props.insurance_card?.care_level && (
          <div className="text-[#999FAA] text-xs font-normal mb-1">
            {props.insurance_card?.care_level}
          </div>
        )}
        <div className="font-bold text-sm mb-1">
          {props.family_name}({props.name_kana})
        </div>
        <div className="text-[#434343] text-xs font-normal mb-1 flex items-center">
          <span>{formatJapanDate(props.birth_date)}</span>
          <div className="w-[1px] h-6 mx-3 bg-[#E8EAEE]"></div>
          <span>年齢: {props.age}</span>
        </div>
        <div className="flex items-center">
          <div className="mr-2 text-[#EA7E2C]">
            <PinMap />
          </div>
          <div className="text-sm text-[#434343] ">
            {props.district + props.city}
          </div>
        </div>
      </div>
      <div>
        <Link to={props.id.toString()}>
          <ButtonOutline className="!text-[12px] !bg-[#FFFFFF] !leading-[20px] !text-[#E07B2E] !px-[19px] !h-[23px]">
            詳細
          </ButtonOutline>
        </Link>
      </div>
    </div>
  );
}

