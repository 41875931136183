/* eslint-disable no-restricted-globals */
import { Button, Checkbox, Col, Form, Row, Select } from "antd";
import apiFactory from "api";
import Input from "component/admin/input";
import { ButtonOutline, ButtonSolid } from "component/button";
import MESSAGES from "constant/messages";
import ROUTES from "constant/routes";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "./style.scss";
import { NumericFormat } from "react-number-format";

export default function FacilityDetail({ difference }: any) {
  const navigate = useNavigate();
  const { id } = useParams();
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false)

  const [data, setData] = useState<{
    id: "";
    displayName: string;
    serviceName: string;
    category: string;
    unitPrice: number;
    unitPriceSetting: false;
    stockQuantity: string;
  }>({
    id: "",
    displayName: "",
    serviceName: "",
    category: "",
    unitPrice: 0,
    unitPriceSetting: false,
    stockQuantity: "管理しない",
  });

  const UnitPriceSetting = useCallback(
    ({ value, onChange }: any) => {
      const onSwitchUnitPriceSetting = (e: any) => {
        onChange?.({ ...value, unitPriceSetting: e.target.checked });
      };

      const onChangeUnitPrice = (e: any) => {
        onChange?.({ ...value, unitPrice: e.target.value });
      };

      return (
        <>
          <Checkbox
            checked={value?.unitPriceSetting}
            disabled={difference.type === "view"}
            onChange={onSwitchUnitPriceSetting}
          >
            する
          </Checkbox>
          <NumericFormat
            onKeyPress={(event: any) => {
              if (!/[0-9.]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            value={value?.unitPrice}
            disabled={difference.type === "view" || !value?.unitPriceSetting}
            className="ml-[10px] w-[30%]"
            onChange={onChangeUnitPrice}
            customInput={Input}
            thousandsGroupStyle="wan"
            thousandSeparator=","
            decimalScale={2}
          />
          <span className="ml-[10px] text-[#b8b8b8]">円</span>
        </>
      );
    },
    [difference]
  );

  const onFinish = async (values: any) => {
    let unitPrice = values.price?.unitPrice;
    if (typeof values.price?.unitPrice !== "number") {
      unitPrice = Number(values.price?.unitPrice?.replaceAll(",", ""));
    }
    const body = {
      id: Number(id),
      display_name: values.displayName.trim(),
      service_name: values.serviceName.trim(),
      category: values.category,
      stock_quantity: values.stockQuantity,
      unit_price: values?.price?.unitPriceSetting ? unitPrice : null,
      unit_price_setting: values?.price?.unitPriceSetting,
    };
    try {
      setSubmitting(true)
      let result;
      switch (difference.type) {
        case "add":
          result = await apiFactory.facilityApi.create(body);
          break;
        case "edit":
          result = await apiFactory.facilityApi.update(body);
          break;
        default:
          break;
      }
      if (result) {
        if (result) {
          toast.success(result.success);
          switch (difference.type) {
            case "add":
              navigate(`/${ROUTES.ADMIN}/${ROUTES.FACILITY_REGISTRATION_LIST}`);
              break;
            case "edit":
              navigate(`/${ROUTES.ADMIN}/facility/${id}`);
              break;
            default:
              break;
          }
        }
      }
    } catch (error: any) {
      toast.error(error?.message?.start_time);
    } finally {
      setSubmitting(false)
    }
  };

  const fetchData = async () => {
    if (id) {
      try {
        const result = await apiFactory.facilityApi.getDetail(id);
        if (result) {
          form.setFieldsValue({
            id: result?.id,
            displayName: result?.display_name,
            serviceName: result?.service_name,
            category: result?.category,
            price: {
              unitPrice: result?.unit_price,
              unitPriceSetting: result?.unit_price_setting,
            },
            stockQuantity: result?.stock_quantity,
          });
        }
      } catch (error: any) {
        toast.error(error?.message);
      }
    }
  };
  const facilityOptions = [
    {
      value: "equipment",
      label: "備品",
    },
    {
      value: "service",
      label: "サービス",
    },
    {
      value: "potoro",
      label: "ぽとろ",
    },
  ];

  const stockQuantityOptions = useMemo(
    () =>
      Array.from(Array(102).keys()).map((e) => {
        if (e === 0) {
          return {
            value: "管理しない",
            label: "管理しない",
          };
        } else {
          return {
            value: (e - 1).toString(),
            label: (e - 1).toString(),
          };
        }
      }),
    []
  );

  useEffect(() => {
    fetchData();
  }, [difference]);

  return (
    <div className="facility-registration-detail">
      <div className="page-title">{`管理メニュー　備品・サービス登録 ${difference?.title}`}</div>
      <div className="page-container">
        {difference.type === "view" && (
          <div className="text-end mb-[30px]">
            <ButtonOutline
              className="!h-[35px] !px-[16px] !text-[14px] !leading-[24px] mr-[8px]"
              onClick={() =>
                navigate(
                  `/${ROUTES.ADMIN}/${ROUTES.FACILITY_REGISTRATION_LIST}`
                )
              }
            >
              一覧へ戻る
            </ButtonOutline>

            <ButtonSolid
              className="!h-[35px] !px-[54px] !text-[14px] !leading-[24px]"
              onClick={() => navigate(`/admin/facility/edit/${id}`)}
            >
              編集
            </ButtonSolid>
          </div>
        )}
        <Form initialValues={data} onFinish={onFinish} form={form}>
          <Row>
            <Col span={11}>
              <Form.Item
                label="サービス名称"
                name="serviceName"
                rules={[
                  { required: true, message: MESSAGES.requireField },
                  { max: 255 },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (value !== "" && value.trim() === "") {
                        return Promise.reject("この項目は必須です");
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Input disabled={difference.type === "view"} />
              </Form.Item>
              <Form.Item
                label="表示名"
                name="displayName"
                rules={[
                  { required: true, message: MESSAGES.requireField },
                  { max: 255 },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (value !== "" && value.trim() === "") {
                        return Promise.reject("この項目は必須です");
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Input disabled={difference.type === "view"} />
              </Form.Item>
              <Form.Item
                label="カテゴリ"
                name="category"
                rules={[{ required: true, message: MESSAGES.requireField }]}
              >
                <Select
                  options={facilityOptions}
                  disabled={difference.type === "view"}
                />
              </Form.Item>
              <Form.Item
                label="単価の設定"
                name="price"
                rules={[
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (value?.unitPriceSetting && !value?.unitPrice) {
                        return Promise.reject(MESSAGES.requireField);
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <UnitPriceSetting />
              </Form.Item>
              <Form.Item label="在庫数" name="stockQuantity">
                <Select
                  options={stockQuantityOptions}
                  disabled={difference.type === "view"}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={13} />
            <Col span={11}>
              <div className="flex items-center justify-between">
                <div className="flex items-center text-[12px] leading-[20px] gap-x-[6px]"></div>
                <div className="flex gap-x-[8px]">
                  {difference.type !== "view" && (
                    <ButtonOutline
                      className="!h-[35px] !px-[16px] !text-[14px] !leading-[24px]"
                      onClick={() => {
                        if (difference.type === "edit") {
                          navigate(`/admin/facility/${id}`);
                        } else {
                          navigate(
                            `/${ROUTES.ADMIN}/${ROUTES.FACILITY_REGISTRATION_LIST}`
                          );
                        }
                      }}
                    >
                      キャンセル
                    </ButtonOutline>
                  )}
                  {difference.type !== "view" && (
                    <Button className="save-button" htmlType="submit" loading={submitting}>
                      保存
                    </Button>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
}
