import React  from "react";
import { Input, InputProps,  } from "antd";
import { formatNumber, formatNumberFullWidthToHalfWidth } from 'utils/formats'

interface IProps extends InputProps {
  type?: "math" | "text";
  onChange?: (value: any) => void;
}
const NumberInputWithFormat: React.FC<IProps> = ({onChange, ...restProps}: IProps) => {

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    const numericValue = inputValue.replace(/[^0-9０-９]/g, "");
    onChange && onChange(numericValue)
  };
  const handleBlur = () => {
    onChange?.(formatNumber(formatNumberFullWidthToHalfWidth(restProps?.value as string || '')));
  };

  return (
    <Input
      {...restProps}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  );
};

export default NumberInputWithFormat;
