import { Button, Col, Form, Input, Row } from "antd";
import apiFactory from "api";
import { ButtonOutline, ButtonSolid } from "component/button";
import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import "./style.scss";
import MESSAGES from "constant/messages";
import { NumericFormat } from "react-number-format";

const CareEnhanceDetail = ({
  onChange,
  id,
  setServiceId,
  difference,
  setDifference,
}: any) => {
  const [form] = Form.useForm();
  const [errorMeassage, setErrorsMeassage] = useState("");
  const [submitting, setSubmitting] = useState(false)

  const [data, setData] = useState<{
    id: number | null;
    name: string;
    rate: number | null;
  }>({
    id: null,
    name: "",
    rate: null,
  });
  const onFinish = async (values: any) => {
    let rate = values?.rate;
    if (typeof values?.rate !== "number") {
      rate = Number(values?.rate?.replaceAll(",", ""));
    }
    const body = {
      id: values.id,
      name: values.name.trim(),
      rate: rate,
      patient_type: "normal",
    };
    try {
      setSubmitting(true)
      setErrorsMeassage("");
      let result;
      switch (difference.type) {
        case "add":
          result = await apiFactory.careRegistrationApi.createTreatment(body);

          break;
        case "edit":
          result = await apiFactory.careRegistrationApi.updateTreatment(body);

          break;
        default:
          break;
      }
      if (result) {
        toast.success(result.success);
        switch (difference.type) {
          case "add":
            setServiceId(null);
            onChange();
            break;
          case "edit":
            setServiceId(id);
            setDifference({
              type: "view",
            });
            break;
          default:
            break;
        }
      }
    } catch (error: any) {
      // if (Object.keys(error?.message).find((e) => e === "name")) {
      //   setErrorsMeassage(error?.message["name"]);
      //   return;
      // }
      // toast.error("Action failed");
      if (error && error.status === 422 && error.message) {
        if (error.message.name) {
          form.setFields([
            {
              name: "name",
              errors: [error.message.name],
            },
          ]);
        }
      }
    } finally {
      setSubmitting(false)
    }
  };

  const onBack = () => {
    if (difference.type === "edit") {
      setServiceId(id);
      setDifference({
        type: "view",
      });
    } else {
      setServiceId(null);
      onChange();
    }
  };
  const fetchData = async () => {
    if (id) {
      try {
        const result = await apiFactory.careRegistrationApi.getTreatmentDetail(
          id
        );
        if (result) {
          form.setFieldsValue({
            id: result?.id,
            name: result?.name,
            rate: result?.rate,
          });
        }
      } catch (error: any) {
        toast.error(error?.message);
      }
    }
  };
  const PercentageInput = useCallback(
    ({ value, onChange }: any) => {
      const onChangeInput = (e: any) => {
        onChange?.(e.target.value);
      };

      return (
        <>
          <div className="flex flex-row items-center">
            <div>計画単位数 ✕</div>
            <NumericFormat
              onKeyPress={(event: any) => {
                if (!/[0-9.]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              className="max-w-[100px] mx-[10px]"
              value={value}
              onChange={onChangeInput}
              disabled={difference.type === "view"}
              customInput={Input}
              thousandsGroupStyle="wan"
              thousandSeparator=","
            />
            <div>%</div>
          </div>
        </>
      );
    },
    [difference]
  );
  useEffect(() => {
    fetchData();
  }, [difference]);

  return (
    <div className="pt-[7px] care-enhance-detail">
      {difference.type === "view" && (
        <div className="text-end mb-[30px]">
          <ButtonOutline
            className="!h-[35px] !px-[16px] !text-[14px] !leading-[24px] mr-[8px]"
            onClick={onBack}
          >
            一覧へ戻る
          </ButtonOutline>
          <ButtonSolid
            className="!h-[35px] !px-[54px] !text-[14px] !leading-[24px]"
            onClick={() =>
              setDifference({
                type: "edit",
              })
            }
          >
            編集
          </ButtonSolid>
        </div>
      )}
      <Form initialValues={data} onFinish={onFinish} form={form}>
        <Row>
          <Col span={11}>
            <Form.Item label="ID" name="id">
              <Input disabled />
            </Form.Item>
            <Form.Item
              label="名称"
              name="name"
              rules={[
                { required: true, message: MESSAGES.requireField },
                { max: 255 },
                {
                  validator: (_, value) => {
                    if (errorMeassage && value !== "") {
                      return Promise.reject("すでに存在しています");
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input disabled={difference.type === "view"} maxLength={255} onChange={() => setErrorsMeassage("")}/>
            </Form.Item>
            <Form.Item
              label="計算式"
              name="rate"
              rules={[{ required: true , message: MESSAGES.requireField }]}
            >
              <PercentageInput />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={13} />
          <Col span={11}>
            <div className="flex items-center justify-between">
              <div className="flex items-center text-[12px] leading-[20px] gap-x-[6px]"></div>
              <div className="flex gap-x-[8px]">
                {difference.type !== "view" && (
                  <ButtonOutline
                    className="!h-[35px] !px-[16px] !text-[14px] !leading-[24px]"
                    onClick={onBack}
                  >
                    キャンセル
                  </ButtonOutline>
                )}
                {difference.type !== "view" && (
                  <Button className="save-button" htmlType="submit" loading={submitting}>
                    保存
                  </Button>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
export { CareEnhanceDetail };
