import { Button, Form, Modal } from "antd";
import type { ColumnsType } from "antd/es/table";
import apiFactory from "api";
import warningIcon from "assets/iconWarning.png";
import collapseHeader from "assets/svg/collapse-header.svg";
import deleteIcon from "assets/svg/delete.svg";
import detailIcon from "assets/svg/detail.svg";
import editIcon from "assets/svg/edit.svg";
import openHeader from "assets/svg/open-header.svg";
import TableAdmin from "component/admin/table";
import { ButtonSolid } from "component/button";
import { DATE_FORMAT_2, JP_DATE_FORMAT } from "constant/date";
import dayjs from "dayjs";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AssessmentSheet2Create from "./AssesmentSheet2Create";
import AssessmentSheet2Edit from "./AssesmentSheet2Edit";
import { IEvalueTwosResponse, IUpdateEvalueResponse } from "./_type";
import Styles from "./styled";
import { IEvalueTwo } from "types/admin";
import { convertToPlainObject } from "utils/data";
import { OPTIONS } from "./config";
import _ from "lodash";
import { toast } from "react-toastify";
import { Copy } from "assets/icons";

interface CollapsedColumns {
  [key: string]: boolean;
}

function getCellValue(
  fieldName: keyof typeof OPTIONS,
  txtValue: string,
  optValue: string
) {
  let _result = "";
  const _config = OPTIONS[fieldName];

  if (optValue) {
    const _options = _config.options;
    const _listValue = optValue.split(",");

    _listValue.forEach((value, index) => {
      const _foundValue = _options.find((item) => item.value === value);
      if (_foundValue) {
        if (index < _listValue.length - 1) {
          _result += `${_foundValue.label}, `;
        } else {
          _result += `${_foundValue.label}`;
        }
      }
    });
  }

  if (txtValue) {
    return (
      <>
        {_.truncate(_result, {
          length:
            _result.length > 20 && _result.length < 23
              ? _result.length + 3
              : 23,
        })}
        <br />
        {_.truncate(txtValue, {
          length:
            _result.length > 20 && _result.length < 23
              ? _result.length + 3
              : 23,
        })}
      </>
    );
  }

  return (
    <>
      {_.truncate(_result, {
        length:
          _result.length > 20 && _result.length < 23 ? _result.length + 3 : 23,
      })}
    </>
  );
}
interface IAssessmentSheet2Props {
  isStaff: boolean;
}

const AssessmentSheet2 = ({ isStaff }: IAssessmentSheet2Props) => {
  const { id: patientId } = useParams();

  const [form] = Form.useForm();
  const evalueTwoApi = isStaff
    ? apiFactory.staffEvalueTwoApi
    : apiFactory.adminEvalueTwoApi;
  //--- State
  const [total, setTotal] = useState<number>(0);
  const [data, setData] = useState<IEvalueTwo[]>([]);
  const [dataDetail, setDataDetail] = useState({});
  const [display, setDisplay] = useState<{
    list: boolean;
    detail: boolean;
    edit: boolean;
    create: boolean;
    delete: boolean | string;
  }>({
    list: true,
    detail: false,
    edit: false,
    create: false,
    delete: false,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [collapsedColumns, setCollapsedColumns] = useState<CollapsedColumns>(
    {}
  );

  const toggleCollapse = (columnKey: any) => {
    setCollapsedColumns((prev: any) => ({
      ...prev,
      [columnKey]: !prev[columnKey],
    }));
  };

  const renderCollapsedTitle = (collapsed: any, title: string) => {
    return collapsed ? <div style={{ width: "10px" }}></div> : title;
  };

  //--- Open edit
  const openEdit = (record: any) => {
    setDisplay({
      list: false,
      edit: true,
      create: false,
      detail: false,
      delete: false,
    });
    setDataDetail(
      convertToPlainObject(
        record,
        (key) =>
          key.endsWith("_opt") &&
          (OPTIONS as any)[key.split("_opt")[0]]?.type === "checkbox"
      )
    );
  };

  //--- Open detail
  const openDetail = (record: any) => {
    setDisplay({
      list: false,
      edit: false,
      create: false,
      detail: true,
      delete: false,
    });
    setDataDetail(
      convertToPlainObject(
        record,
        (key) =>
          key.endsWith("_opt") &&
          (OPTIONS as any)[key.split("_opt")[0]]?.type === "checkbox"
      )
    );
  };

  //--- Open delete
  const openDelete = (record: IEvalueTwo) => {
    setDisplay({
      list: false,
      edit: false,
      create: false,
      detail: false,
      delete: record.id.toString(),
    });
  };

  //--- Open create
  const openCreate = () => {
    setDisplay({
      list: false,
      edit: false,
      create: true,
      detail: false,
      delete: false,
    });
  };

  //--- Close modal
  const onClose = (isRefresh?: boolean) => {
    setDisplay({
      list: true,
      edit: false,
      create: false,
      detail: false,
      delete: false,
    });

    if (isRefresh) {
      getEvalue();
    }
  };

  const handleDuplicateRecord = async (record: IEvalueTwo) => {
    if (!patientId) return;
    try {
      const { id, ...requestBody } = record;

      const res =
        await apiFactory.adminEvalueTwoApi.createEvalue<IUpdateEvalueResponse | null>(
          {
            patient_id: patientId!,
            ...requestBody,
          }
        );

      if (res && res.success) {
        toast.success(res.success);
        onClose(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const columns: ColumnsType<IEvalueTwo> = [
    {
      align: "center",
      title: "作成日",
      dataIndex: "created_date",
      sorter: true,
      render: (value, record) =>
        value
          ? dayjs(value).format(JP_DATE_FORMAT)
          : dayjs(record.created_at).format(JP_DATE_FORMAT),
    },
    {
      align: "center",
      title: (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          食事
          <img
            onClick={() => toggleCollapse("meal")}
            src={collapsedColumns["meal"] ? openHeader : collapseHeader}
            alt=""
            style={{
              marginLeft: "10px",
              width: "20px",
              height: "20px",
              cursor: "pointer",
            }}
          />
        </div>
      ),
      dataIndex: "meal",
      children: [
        {
          align: "center",
          className: collapsedColumns["meal"] ? "collapsed-column" : "",
          title: renderCollapsedTitle(collapsedColumns["meal"], "形態・主食"),
          width: collapsedColumns["meal"] ? 5 : undefined,
          dataIndex: "staple_food",
          render: (_, record) =>
            collapsedColumns["meal"]
              ? null
              : getCellValue(
                  "staple_food",
                  record.staple_food_txt,
                  record.staple_food_opt
                ),
        },
        {
          align: "center",
          className: collapsedColumns["meal"] ? "collapsed-column" : "",
          title: renderCollapsedTitle(collapsedColumns["meal"], "形態・副食"),
          width: collapsedColumns["meal"] ? 5 : undefined,
          dataIndex: "side_dish",
          render: (_, record) =>
            collapsedColumns["meal"]
              ? null
              : getCellValue(
                  "side_dish",
                  record.side_dish_txt,
                  record.side_dish_opt
                ),
        },
        {
          align: "center",
          className: collapsedColumns["meal"] ? "collapsed-column" : "",
          title: renderCollapsedTitle(collapsedColumns["meal"], "形態・トロミ"),
          width: collapsedColumns["meal"] ? 5 : undefined,
          dataIndex: "thoromi",
          render: (_, record) =>
            collapsedColumns["meal"]
              ? null
              : getCellValue("thoromi", record.thoromi_txt, record.thoromi_opt),
        },
        {
          align: "center",
          className: collapsedColumns["meal"] ? "collapsed-column" : "",
          title: renderCollapsedTitle(collapsedColumns["meal"], "介助"),
          width: collapsedColumns["meal"] ? 5 : undefined,
          dataIndex: "assistance",
          render: (_, record) =>
            collapsedColumns["meal"]
              ? null
              : getCellValue(
                  "assistance",
                  record.assistance_txt,
                  record.assistance_opt
                ),
        },
        {
          align: "center",
          className: collapsedColumns["meal"] ? "collapsed-column" : "",
          title: renderCollapsedTitle(collapsedColumns["meal"], "道具"),
          width: collapsedColumns["meal"] ? 5 : undefined,
          dataIndex: "tool",
          render: (_, record) =>
            collapsedColumns["meal"]
              ? null
              : getCellValue("tool", record.tool_txt, record.tool_opt),
        },
        {
          align: "center",
          className: collapsedColumns["meal"] ? "collapsed-column" : "",
          title: renderCollapsedTitle(collapsedColumns["meal"], "嗜好品"),
          width: collapsedColumns["meal"] ? 5 : undefined,
          render: (_, record) =>
            collapsedColumns["meal"]
              ? null
              : getCellValue(
                  "hobby_goods",
                  record.hobby_goods_txt,
                  record.hobby_goods_opt
                ),
        },
        {
          align: "center",
          className: collapsedColumns["meal"] ? "collapsed-column" : "",
          title: renderCollapsedTitle(collapsedColumns["meal"], "アレルギー"),
          width: collapsedColumns["meal"] ? 5 : undefined,
          render: (_, record) =>
            collapsedColumns["meal"]
              ? null
              : getCellValue("allergy", record.allergy_txt, record.allergy_opt),
        },
      ],
    },
    {
      align: "center",
      title: (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          各部位
          <img
            onClick={() => toggleCollapse("bodyParts")}
            src={collapsedColumns["bodyParts"] ? openHeader : collapseHeader}
            alt=""
            style={{
              marginLeft: "10px",
              width: "20px",
              height: "20px",
              cursor: "pointer",
            }}
          />
        </div>
      ),
      dataIndex: "bodyParts",
      children: [
        {
          align: "center",
          className: collapsedColumns["bodyParts"] ? "collapsed-column" : "",
          title: renderCollapsedTitle(collapsedColumns["bodyParts"], "首"),
          width: collapsedColumns["bodyParts"] ? 5 : undefined,
          render: (_, record) =>
            collapsedColumns["bodyParts"]
              ? null
              : getCellValue("head", record.head_txt, record.head_opt),
        },
        {
          align: "center",
          className: collapsedColumns["bodyParts"] ? "collapsed-column" : "",
          title: renderCollapsedTitle(collapsedColumns["bodyParts"], "上肢"),
          width: collapsedColumns["bodyParts"] ? 5 : undefined,
          render: (_, record) =>
            collapsedColumns["bodyParts"]
              ? null
              : getCellValue(
                  "upper_limbs",
                  record.upper_limbs_txt,
                  record.upper_limbs_opt
                ),
        },
        {
          align: "center",
          className: collapsedColumns["bodyParts"] ? "collapsed-column" : "",
          title: renderCollapsedTitle(collapsedColumns["bodyParts"], "腰"),
          width: collapsedColumns["bodyParts"] ? 5 : undefined,
          render: (_, record) =>
            collapsedColumns["bodyParts"]
              ? null
              : getCellValue("waist", record.waist_txt, record.waist_opt),
        },
        {
          align: "center",
          className: collapsedColumns["bodyParts"] ? "collapsed-column" : "",
          title: renderCollapsedTitle(collapsedColumns["bodyParts"], "股関節"),
          width: collapsedColumns["bodyParts"] ? 5 : undefined,
          render: (_, record) =>
            collapsedColumns["bodyParts"]
              ? null
              : getCellValue(
                  "hip_joint",
                  record.hip_joint_txt,
                  record.hip_joint_opt
                ),
        },
        {
          align: "center",
          className: collapsedColumns["bodyParts"] ? "collapsed-column" : "",
          title: renderCollapsedTitle(collapsedColumns["bodyParts"], "下肢"),
          width: collapsedColumns["bodyParts"] ? 5 : undefined,
          render: (_, record) =>
            collapsedColumns["bodyParts"]
              ? null
              : getCellValue(
                  "lower_limbs",
                  record.lower_limbs_txt,
                  record.lower_limbs_opt
                ),
        },
        {
          align: "center",
          className: collapsedColumns["bodyParts"] ? "collapsed-column" : "",
          title: renderCollapsedTitle(collapsedColumns["bodyParts"], "視力"),
          width: collapsedColumns["bodyParts"] ? 5 : undefined,
          render: (_, record) =>
            collapsedColumns["bodyParts"]
              ? null
              : getCellValue(
                  "eyesight",
                  record.eyesight_txt,
                  record.eyesight_opt
                ),
        },
        {
          align: "center",
          className: collapsedColumns["bodyParts"] ? "collapsed-column" : "",
          title: renderCollapsedTitle(collapsedColumns["bodyParts"], "眼鏡"),
          width: collapsedColumns["bodyParts"] ? 5 : undefined,
          render: (_, record) =>
            collapsedColumns["bodyParts"]
              ? null
              : getCellValue("glasses", record.glasses_txt, record.glasses_opt),
        },
        {
          align: "center",
          className: collapsedColumns["bodyParts"] ? "collapsed-column" : "",
          title: renderCollapsedTitle(collapsedColumns["bodyParts"], "聴力"),
          width: collapsedColumns["bodyParts"] ? 5 : undefined,
          render: (_, record) =>
            collapsedColumns["bodyParts"]
              ? null
              : getCellValue("hearing", record.hearing_txt, record.hearing_opt),
        },
        {
          align: "center",
          className: collapsedColumns["bodyParts"] ? "collapsed-column" : "",
          title: renderCollapsedTitle(collapsedColumns["bodyParts"], "言語"),
          width: collapsedColumns["bodyParts"] ? 5 : undefined,
          render: (_, record) =>
            collapsedColumns["bodyParts"]
              ? null
              : getCellValue(
                  "language",
                  record.language_txt,
                  record.language_opt
                ),
        },
      ],
    },
    {
      align: "center",
      title: (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          移動
          <img
            onClick={() => toggleCollapse("mobility")}
            src={collapsedColumns["mobility"] ? openHeader : collapseHeader}
            alt=""
            style={{
              marginLeft: "10px",
              width: "20px",
              height: "20px",
              cursor: "pointer",
            }}
          />
        </div>
      ),
      dataIndex: "mobility",
      children: [
        {
          align: "center",
          className: collapsedColumns["mobility"] ? "collapsed-column" : "",
          title: renderCollapsedTitle(collapsedColumns["mobility"], "形態"),
          width: collapsedColumns["mobility"] ? 5 : undefined,
          render: (_, record) =>
            collapsedColumns["mobility"]
              ? null
              : getCellValue(
                  "morphology",
                  record.morphology_txt,
                  record.morphology_opt
                ),
        },
        {
          align: "center",
          className: collapsedColumns["mobility"] ? "collapsed-column" : "",
          title: renderCollapsedTitle(collapsedColumns["mobility"], "移動"),
          width: collapsedColumns["mobility"] ? 5 : undefined,
          render: (_, record) =>
            collapsedColumns["mobility"]
              ? null
              : getCellValue("move", record.move_txt, record.move_opt),
        },
        {
          align: "center",
          className: collapsedColumns["mobility"] ? "collapsed-column" : "",
          title: renderCollapsedTitle(collapsedColumns["mobility"], "立位"),
          width: collapsedColumns["mobility"] ? 5 : undefined,
          render: (_, record) =>
            collapsedColumns["mobility"]
              ? null
              : getCellValue(
                  "standing_position",
                  record.standing_position_txt,
                  record.standing_position_opt
                ),
        },
        {
          align: "center",
          className: collapsedColumns["mobility"] ? "collapsed-column" : "",
          title: renderCollapsedTitle(collapsedColumns["mobility"], "座位"),
          width: collapsedColumns["mobility"] ? 5 : undefined,
          render: (_, record) =>
            collapsedColumns["mobility"]
              ? null
              : getCellValue("seat", record.seat_txt, record.seat_opt),
        },
      ],
    },
    {
      align: "center",
      title: (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          入浴
          <img
            onClick={() => toggleCollapse("bathing")}
            src={collapsedColumns["bathing"] ? openHeader : collapseHeader}
            alt=""
            style={{
              marginLeft: "10px",
              width: "20px",
              height: "20px",
              cursor: "pointer",
            }}
          />
        </div>
      ),
      dataIndex: "bathing",
      children: [
        {
          align: "center",
          className: collapsedColumns["bathing"] ? "collapsed-column" : "",
          title: renderCollapsedTitle(collapsedColumns["bathing"], "洗身"),
          width: collapsedColumns["bathing"] ? 5 : undefined,
          render: (_, record) =>
            collapsedColumns["bathing"]
              ? null
              : getCellValue(
                  "wash_body",
                  record.wash_body_txt,
                  record.wash_body_opt
                ),
        },
        {
          align: "center",
          className: collapsedColumns["bathing"] ? "collapsed-column" : "",
          title: renderCollapsedTitle(collapsedColumns["bathing"], "洗髪"),
          width: collapsedColumns["bathing"] ? 5 : undefined,
          render: (_, record) =>
            collapsedColumns["bathing"]
              ? null
              : getCellValue(
                  "hair_washing",
                  record.hair_washing_txt,
                  record.hair_washing_opt
                ),
        },
        {
          align: "center",
          className: collapsedColumns["bathing"] ? "collapsed-column" : "",
          title: renderCollapsedTitle(collapsedColumns["bathing"], "着脱"),
          width: collapsedColumns["bathing"] ? 5 : undefined,
          render: (_, record) =>
            collapsedColumns["bathing"]
              ? null
              : getCellValue(
                  "attaching_and_detaching",
                  record.attaching_and_detaching_txt,
                  record.attaching_and_detaching_opt
                ),
        },
        {
          align: "center",
          className: collapsedColumns["bathing"] ? "collapsed-column" : "",
          title: renderCollapsedTitle(collapsedColumns["bathing"], "整容"),
          width: collapsedColumns["bathing"] ? 5 : undefined,
          render: (_, record) =>
            collapsedColumns["bathing"]
              ? null
              : getCellValue(
                  "plastic_surgery",
                  record.plastic_surgery_txt,
                  record.plastic_surgery_opt
                ),
        },
      ],
    },
    {
      align: "center",
      title: (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          排泄
          <img
            onClick={() => toggleCollapse("excretion")}
            src={collapsedColumns["excretion"] ? openHeader : collapseHeader}
            alt=""
            style={{
              marginLeft: "10px",
              width: "20px",
              height: "20px",
              cursor: "pointer",
            }}
          />
        </div>
      ),
      dataIndex: "excretion",
      children: [
        {
          align: "center",
          className: collapsedColumns["excretion"] ? "collapsed-column" : "",
          title: renderCollapsedTitle(collapsedColumns["excretion"], "形態"),
          width: collapsedColumns["excretion"] ? 5 : undefined,
          render: (_, record) =>
            collapsedColumns["excretion"]
              ? null
              : getCellValue(
                  "excretion_form",
                  record.excretion_form_txt,
                  record.excretion_form_opt
                ),
        },
        {
          align: "center",
          className: collapsedColumns["excretion"] ? "collapsed-column" : "",
          title: renderCollapsedTitle(collapsedColumns["excretion"], "尿意"),
          width: collapsedColumns["excretion"] ? 5 : undefined,
          render: (_, record) =>
            collapsedColumns["excretion"]
              ? null
              : getCellValue(
                  "urge_to_urinate",
                  record.urge_to_urinate_txt,
                  record.urge_to_urinate_opt
                ),
        },
        {
          align: "center",
          className: collapsedColumns["excretion"] ? "collapsed-column" : "",
          title: renderCollapsedTitle(collapsedColumns["excretion"], "便意"),
          width: collapsedColumns["excretion"] ? 5 : undefined,
          render: (_, record) =>
            collapsedColumns["excretion"]
              ? null
              : getCellValue(
                  "convenience",
                  record.convenience_txt,
                  record.convenience_opt
                ),
        },
        {
          align: "center",
          className: collapsedColumns["excretion"] ? "collapsed-column" : "",
          title: renderCollapsedTitle(collapsedColumns["excretion"], "失禁"),
          width: collapsedColumns["excretion"] ? 5 : undefined,
          render: (_, record) =>
            collapsedColumns["excretion"]
              ? null
              : getCellValue(
                  "incontinence",
                  record.incontinence_txt,
                  record.incontinence_opt
                ),
        },
      ],
    },
    {
      align: "center",
      title: (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          口腔
          <img
            onClick={() => toggleCollapse("oralCavity")}
            src={collapsedColumns["oralCavity"] ? openHeader : collapseHeader}
            alt=""
            style={{
              marginLeft: "10px",
              width: "20px",
              height: "20px",
              cursor: "pointer",
            }}
          />
        </div>
      ),
      dataIndex: "oralCavity",
      children: [
        {
          align: "center",
          className: collapsedColumns["oralCavity"] ? "collapsed-column" : "",
          title: renderCollapsedTitle(collapsedColumns["oralCavity"], "咀嚼"),
          width: collapsedColumns["oralCavity"] ? 5 : undefined,
          render: (_, record) =>
            collapsedColumns["oralCavity"]
              ? null
              : getCellValue("chew", record.chew_txt, record.chew_opt),
        },
        {
          align: "center",
          className: collapsedColumns["oralCavity"] ? "collapsed-column" : "",
          title: renderCollapsedTitle(collapsedColumns["oralCavity"], "嚥下"),
          width: collapsedColumns["oralCavity"] ? 5 : undefined,
          render: (_, record) =>
            collapsedColumns["oralCavity"]
              ? null
              : getCellValue(
                  "swallowing",
                  record.swallowing_txt,
                  record.swallowing_opt
                ),
        },
        {
          align: "center",
          className: collapsedColumns["oralCavity"] ? "collapsed-column" : "",
          title: renderCollapsedTitle(collapsedColumns["oralCavity"], "義歯"),
          width: collapsedColumns["oralCavity"] ? 5 : undefined,
          render: (_, record) =>
            collapsedColumns["oralCavity"]
              ? null
              : getCellValue("denture", record.denture_txt, record.denture_opt),
        },
      ],
    },
    {
      align: "center",
      title: (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          精神状態
          <img
            onClick={() => toggleCollapse("mentalState")}
            src={collapsedColumns["mentalState"] ? openHeader : collapseHeader}
            alt=""
            style={{
              marginLeft: "10px",
              width: "20px",
              height: "20px",
              cursor: "pointer",
            }}
          />
        </div>
      ),
      dataIndex: "mentalState",
      children: [
        {
          align: "center",
          className: collapsedColumns["mentalState"] ? "collapsed-column" : "",
          title: renderCollapsedTitle(
            collapsedColumns["mentalState"],
            "認知度"
          ),
          width: collapsedColumns["mentalState"] ? 5 : undefined,
          render: (_, record) =>
            collapsedColumns["mentalState"]
              ? null
              : getCellValue(
                  "understanding",
                  record.understanding_txt,
                  record.understanding_opt
                ),
        },
        {
          align: "center",
          className: collapsedColumns["mentalState"] ? "collapsed-column" : "",
          title: renderCollapsedTitle(collapsedColumns["mentalState"], "意思"),
          width: collapsedColumns["mentalState"] ? 5 : undefined,
          render: (_, record) =>
            collapsedColumns["mentalState"]
              ? null
              : getCellValue(
                  "expression_of_meaning",
                  record.expression_of_meaning_txt,
                  record.expression_of_meaning_opt
                ),
        },
        {
          align: "center",
          className: collapsedColumns["mentalState"] ? "collapsed-column" : "",
          title: renderCollapsedTitle(collapsedColumns["mentalState"], "理解"),
          width: collapsedColumns["mentalState"] ? 5 : undefined,
          render: (_, record) =>
            collapsedColumns["mentalState"]
              ? null
              : getCellValue(
                  "understand",
                  record.understand_txt,
                  record.understand_opt
                ),
        },
        {
          align: "center",
          className: collapsedColumns["mentalState"] ? "collapsed-column" : "",
          title: renderCollapsedTitle(collapsedColumns["mentalState"], "徘徊"),
          width: collapsedColumns["mentalState"] ? 5 : undefined,
          render: (_, record) =>
            collapsedColumns["mentalState"]
              ? null
              : getCellValue("wander", record.wander_txt, record.wander_opt),
        },
        {
          align: "center",
          className: collapsedColumns["mentalState"] ? "collapsed-column" : "",
          title: renderCollapsedTitle(collapsedColumns["mentalState"], "暴力"),
          width: collapsedColumns["mentalState"] ? 5 : undefined,
          render: (_, record) =>
            collapsedColumns["mentalState"]
              ? null
              : getCellValue(
                  "violence",
                  record.violence_txt,
                  record.violence_opt
                ),
        },
        {
          align: "center",
          className: collapsedColumns["mentalState"] ? "collapsed-column" : "",
          title: renderCollapsedTitle(collapsedColumns["mentalState"], "妄"),
          width: collapsedColumns["mentalState"] ? 5 : undefined,
          render: (_, record) =>
            collapsedColumns["mentalState"]
              ? null
              : getCellValue(
                  "delusion",
                  record.delusion_txt,
                  record.delusion_opt
                ),
        },
        {
          align: "center",
          className: collapsedColumns["mentalState"] ? "collapsed-column" : "",
          title: renderCollapsedTitle(collapsedColumns["mentalState"], "拒否"),
          width: collapsedColumns["mentalState"] ? 5 : undefined,
          render: (_, record) =>
            collapsedColumns["mentalState"]
              ? null
              : getCellValue(
                  "rejection",
                  record.rejection_txt,
                  record.rejection_opt
                ),
        },
      ],
    },
    {
      align: "center",
      title: (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          医療
          <img
            onClick={() => toggleCollapse("medicalCare")}
            src={collapsedColumns["medicalCare"] ? openHeader : collapseHeader}
            alt=""
            style={{
              marginLeft: "10px",
              width: "20px",
              height: "20px",
              cursor: "pointer",
            }}
          />
        </div>
      ),
      dataIndex: "medicalCare",
      children: [
        {
          align: "center",
          className: collapsedColumns["medicalCare"] ? "collapsed-column" : "",
          title: renderCollapsedTitle(collapsedColumns["medicalCare"], "処置"),
          width: collapsedColumns["medicalCare"] ? 5 : undefined,
          render: (_, record) =>
            collapsedColumns["medicalCare"]
              ? null
              : getCellValue(
                  "treatment",
                  record.treatment_txt,
                  record.treatment_opt
                ),
        },
        {
          align: "center",
          className: collapsedColumns["medicalCare"] ? "collapsed-column" : "",
          title: renderCollapsedTitle(collapsedColumns["medicalCare"], "投薬"),
          width: collapsedColumns["medicalCare"] ? 5 : undefined,
          render: (_, record) =>
            collapsedColumns["medicalCare"]
              ? null
              : getCellValue("dosage", record.dosage_txt, record.dosage_opt),
        },
      ],
    },
    {
      align: "center",
      title: "操作",
      dataIndex: "action",
      ...{ ...(isStaff ? { fixed: "right" } : {}) }, // if role is staff fixed right , else do not fixed
      render: (_, record) => (
        <div className="wrapper-action">
          {isStaff ? (
            <ButtonSolid width={46} onClick={() => openDetail(record)}>
              詳細
            </ButtonSolid>
          ) : (
            <>
              <div
                className="icon-action"
                onClick={() => handleDuplicateRecord(record)}
              >
                <Copy className="h-6 w-6" />
              </div>
              <div onClick={() => openEdit(record)} className="icon-action">
                <img src={editIcon} alt="" />
              </div>

              <div onClick={() => openDetail(record)} className="icon-action">
                <img src={detailIcon} alt="" />
              </div>

              <div onClick={() => openDelete(record)} className="icon-action">
                <img src={deleteIcon} alt="" />
              </div>
            </>
          )}
        </div>
      ),
    },
  ];

  useEffect(() => {
    getEvalue();
  }, []);

  // handle fetch evalue ones data
  const getEvalue = useCallback(async (args?: any) => {
    setLoading(true);

    try {
      const _data = await evalueTwoApi.getEvalue<IEvalueTwosResponse>({
        page: (args && args.page) || 1,
        per_page: (args && args.per) || 10,
        patient_id: patientId!,
      });

      if (_data) {
        setData(
          _data.evalue_twos.sort((a, b) => {
            return (
              dayjs(b.created_date ? b.created_date : b.created_at).unix() -
              dayjs(a.created_date ? a.created_date : a.created_at).unix()
            );
          })
        );
        setTotal(_data.total_items);
      } else {
        setData([]);
        setTotal(0);
      }
    } catch (error) {}

    setLoading(false);
  }, []);

  // handle DELETE evalue ones
  const handleDeleteEvalue = useCallback(async () => {
    try {
      if (display.delete && typeof display.delete === "string") {
        const res = await apiFactory.adminEvalueTwoApi.deleteEvalue(
          display.delete
        );
        onClose(true);
      }
    } catch (error) {}
  }, [display.delete]);

  return (
    <>
      {(display?.list || display?.delete) && (
        <Styles>
          {!isStaff && (
            <div className="text-end">
              <ButtonSolid
                onClick={() => {
                  openCreate();
                }}
                width={137}
              >
                新しく作る
              </ButtonSolid>
            </div>
          )}
          <Form form={form} onFinish={getEvalue}>
            <TableAdmin
              form={form}
              data={data}
              columns={columns}
              loading={loading}
              total={total}
            />
          </Form>
        </Styles>
      )}

      {display?.create && <AssessmentSheet2Create onClose={onClose} />}

      {(display?.edit || display?.detail) && (
        <AssessmentSheet2Edit
          display={display}
          dataDetail={dataDetail}
          onClose={onClose}
        />
      )}

      <Modal
        width={350}
        open={display?.delete ? true : false}
        onOk={() => onClose()}
        onCancel={() => onClose()}
        footer={null}
      >
        <Styles>
          <div className="container-delete">
            <div className="img-delete">
              <img width={80} height={80} alt="" src={warningIcon} />
            </div>
            <div className="title-delete">削除してよろしいですか。</div>
            <div className="btn-delete">
              <div>
                <Button
                  className="btn"
                  onClick={() => onClose()}
                  style={{ margin: "0 10px" }}
                >
                  いいえ
                </Button>
              </div>
              <div>
                <Button
                  className="btn"
                  onClick={handleDeleteEvalue}
                  type="primary"
                  danger
                  style={{ margin: "0 10px" }}
                >
                  はい
                </Button>
              </div>
            </div>
          </div>
        </Styles>
      </Modal>
    </>
  );
};

export default AssessmentSheet2;
