import {
  Input,
  NumberInput,
  NumberInputField,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from "@chakra-ui/react";
import {
  FlexRow,
  StyleDatePicker,
  StyleGrid,
  StyleGridItem,
  StyleSelect,
  StyleTitle,
} from "component/StyledComponent";
import React, { useState, useEffect } from "react";
import TextForm from "../TextForm";
import RadioForm, { styleRadioProps } from "component/form/RadioForm";
import { DATE, ERAS, FORMAT, HEISEI, MONTH, SHOWA } from "utils";
import styled from "@emotion/styled";
import SelectForm from "component/form/SelectForm";
import locale from "antd/es/date-picker/locale/ja_JP";
import TextAreaForm from "../TextAreaForm";
import { useParams } from "react-router-dom";
import { Form } from "antd";
import REGEX from "constant/regex";
import MESSAGES from "constant/messages";
import "../../dailyform.scss";
import { JP_DATEPICKER_FORMAT } from "constant/date";
import axiosClient from "api/axiosClient";
import useService from "hook/useService";
import { IDistrict } from "constant/interfaces";
import { PatternFormat } from "react-number-format";
import dayjs from "dayjs";
import NumericInput from "component/NumberInput";

const BasicInfomationForm = ({
  value,
  onChangeValue,
  setValue,
  onChangeDateContract,
  dateContract,
  disable,
  form,
}: any) => {
  const [reiwa, setReiwa]: any = useState([]);
  const [dataYear, setDataYear]: any = useState([]);
  const service = useService();
  const { id } = useParams();
  const district = Form.useWatch("district", form);
  const city = Form.useWatch("city", form);
  const [addrOpt, setAddrOpt] = useState([]);
  const [cityOpt, setCityOpt] = useState([]);
  const [districtOpt, setDistrictOpt] = useState([]);
  useEffect(() => {
    const eraYear =
      new Date().getFullYear() - new Date("2019/05/01").getFullYear();
    const era = [];
    for (let index = 0; index <= eraYear; index++) {
      era.push(String(index + 1));
    }
    setReiwa(era);
  }, []);

  const fetchData = async () => {
    try {
      const { data } = await axiosClient({
        method: "get",
        url: `${service.PATIENTS}/${id}`,
      });
      form.setFieldsValue(data);
      data.birth_date &&
        form.setFieldValue("birth_date", dayjs(data.birth_date));
      data.period_contract &&
        form.setFieldValue("period_contract", dayjs(data.period_contract));
    } catch (error) {
      console.error(error);
    }
  };

  const getAdressByZipcode = async (zipcode: string) => {
    try {
      if (zipcode.replace("_", "").length === 8) {
        const { data } = await axiosClient.get(service.INFO_ADDRESS_BY_CODE, {
          params: {
            code: zipcode,
          },
        });
        // console.log('data', data)
        form.setFieldValue("district", data.info_address.district.name);
        form.setFieldValue("city", data.info_address.city.name);
        form.setFieldValue("street", data.info_address.address.name);
      }
    } catch (error) {}
  };

  const getDistrictOptions = async () => {
    try {
      const res: any = await axiosClient.get(service.DISTRICTS);
      if (res) {
        const newRes: any = res?.data?.districts.map((item: any) => ({
          value: item.name,
          content: `${item.name}`,
        }));
        setDistrictOpt(newRes);
      }
    } catch (error) {}
    // (await axiosClient.get(service.DISTRICTS)).data.districts.map(
    //   (item: IDistrict) => ({ label: item.name, value: item.name })
    // );
  };

  const getCityOptions = async () => {
    try {
      const res: any = await axiosClient.get(service.CITIES, {
        params: { district_name: district },
      });
      // console.log('res addres', res)
      const newRes: any = res?.data?.cities.map((item: any) => ({
        value: item.name,
        content: `${item.name}`,
      }));
      setCityOpt(newRes);
    } catch (error) {
      console.log("error", error);
    }

    // (
    //   await axiosClient.get(service.CITIES, {
    //     params: { district_name: district },
    //   })
    // ).data.cities.map((item: IDistrict) => ({
    //   label: item.name,
    //   value: item.name,
    // }));
  };

  const getAddressOptions = async () => {
    // (
    //   await axiosClient.get(service.ADDRESSES, {
    //     params: { city_name: city, district_name: district },
    //   })
    // ).data.addresses.map((item: IDistrict) => ({
    //   label: item.name,
    //   value: item.name,
    // }));
    try {
      const res: any = await axiosClient.get(service.ADDRESSES, {
        params: { city_name: city, district_name: district },
      });
      // console.log('res addres', res)
      const newRes: any = res?.data?.addresses.map((item: any) => ({
        value: item.name,
        content: `${item.name}`,
      }));
      setAddrOpt(newRes);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getAddressOptions();
  }, [city, district, cityOpt]);

  useEffect(() => {
    getCityOptions();
  }, [district, districtOpt]);

  useEffect(() => {
    if (id) {
      fetchData();
    }
  }, [id]);

  useEffect(() => {
    getDistrictOptions();
  }, []);

  const getZipCodeByAddress = async (address: string) => {
    if (city && district && address) {
      const { data } = await axiosClient.get(service.POST_CODE, {
        params: {
          district_name: district,
          city_name: city,
          address_name: address,
        },
      });
      form.setFieldValue("zipcode", data.post_code);
    }
  };
  useEffect(() => {
    switch (value.eras) {
      case "heisei":
        setDataYear(HEISEI);
        break;
      case "showa":
        setDataYear(SHOWA);
        break;
      case "reiwa":
        setDataYear(reiwa);
        break;
      default:
        setDataYear(HEISEI);
        break;
    }
  }, [value.eras, reiwa]);
  return (
    <FlexRow style={{ textAlign: "left", fontSize: "12px" }}>
      <div className="w-1/2 pr-5">
        <Form.Item label="お客様ID" name="patient_code">
          <Input
            fontSize="12px"
            type="text"
            bg="#eeeeee"
            value={id}
            height="40px"
            isReadOnly
            onChange={onChangeValue}
            border="1px"
            borderColor="#000"
            borderRadius="0"
            name="id"
          />
        </Form.Item>
        <Form.Item
          label="パスワード"
          name="password"
          rules={[{ required: !id, message: MESSAGES.requireField }]}
        >
          <InputCustom isReadOnly type="password" />
        </Form.Item>

        <FlexRow>
          <Form.Item
            label="氏名"
            name="last_name"
            rules={[
              { required: true, message: MESSAGES.requireField },
              { max: 50 },
              {
                pattern: REGEX.onlyTextAndNumber,
                message: MESSAGES.invalidCharacter,
              },
            ]}
          >
            <InputCustom isReadOnly placeholder="姓" />
          </Form.Item>

          <Form.Item
            label=""
            name="first_name"
            rules={[
              { required: true, message: MESSAGES.requireField },
              { max: 50 },
              {
                pattern: REGEX.onlyTextAndNumber,
                message: MESSAGES.invalidCharacter,
              },
            ]}
          >
            <InputCustom isReadOnly ml={10} placeholder="名" />
          </Form.Item>
        </FlexRow>

        <FlexRow>
          <Form.Item
            label="氏名(カナ)"
            name="last_name_kana"
            rules={[
              { required: true, message: MESSAGES.requireField },
              { max: 50 },
              {
                pattern: REGEX.onlyKatakanaText,
                message: MESSAGES.invalidCharacter,
              },
            ]}
          >
            <InputCustom isReadOnly placeholder="姓(カナ)" />
          </Form.Item>

          <Form.Item
            label=""
            name="first_name_kana"
            rules={[
              { required: true, message: MESSAGES.requireField },
              { max: 50 },
              {
                pattern: REGEX.onlyTextAndNumber,
                message: MESSAGES.invalidCharacter,
              },
            ]}
          >
            <InputCustom isReadOnly ml={10} placeholder="名(カナ)" />
          </Form.Item>
        </FlexRow>
        <Form.Item
          label="性別"
          name="sex"
          initialValue="男"
          rules={[{ required: true, message: MESSAGES.requireField }]}
        >
          <RadioGroup colorScheme="white">
            <Radio
              _before={styleRadioProps}
              mr="7px"
              isReadOnly
              borderColor={`${value === "男" ? "var(--header-color)" : "#000"}`}
              value="男"
            >
              {" "}
              男
            </Radio>
            <Radio
              _before={styleRadioProps}
              mr="7px"
              isReadOnly
              borderColor={`${value === "女" ? "var(--header-color)" : "#000"}`}
              value="女"
            >
              {" "}
              女{" "}
            </Radio>
          </RadioGroup>
        </Form.Item>

        <Form.Item
          label="生年月日"
          name="birth_date"
          rules={[{ required: true, message: MESSAGES.requireField }]}
        >
          <StyleDatePicker
            inputReadOnly
            // disabled={disable}
            disabledDate={(date) => date.isAfter(Date.now())}
            format={JP_DATEPICKER_FORMAT}
            className="w-full"
            placeholder="選択してください"
            allowClear={false}
            style={{ width: "100%" }}
          />
        </Form.Item>

        {/* <StyleGrid templateColumns='repeat(5, 1fr)' gap={6}>
          <StyleGridItem colSpan={1} >
            <TextP>郵便番号</TextP>
          </StyleGridItem>
          <StyleGridItem colSpan={4} >
            <NumberInput borderRadius='0px'
              borderColor='#000'  >
              <NumberInputField value={value.postCode} borderRadius='0px' fontSize='12px' onChange={onChangeValue} name="postCode" />
            </NumberInput>

          </StyleGridItem>
        </StyleGrid> */}
        {/* 
        <Form.Item
          label="郵便番号"
          name="zipcode"
          rules={[
            { len: 8 },
            { required: true, message: MESSAGES.requireField },
            {
              pattern: REGEX.zipcode,
              message: MESSAGES.requireField,
            },
          ]}
        >
          <PatternFormat
            customInput={Input}
            format="###-####"
            mask="_"
            onChange={(e) => getAdressByZipcode(e.target.value)}
          />
        </Form.Item> */}

        <Form.Item
          label="郵便番号"
          name="zipcode"
          rules={[
            { len: 8 },
            { required: true, message: MESSAGES.requireField },
            {
              pattern: REGEX.zipcode,
              message: MESSAGES.invalidPostCode,
            },
          ]}
        >
          <PatternFormat
            customInput={Input}
            format="###-####"
            mask="_"
            onChange={(e) => getAdressByZipcode(e.target.value)}
          />
        </Form.Item>

        <Form.Item
          label="都道府県"
          name="district"
          rules={[{ required: true, message: MESSAGES.requireField }]}
        >
          <StyleSelect
            onChange={() => {
              form.setFieldValue("city", undefined);
              form.setFieldValue("street", undefined);
            }}
            placeholder="選択してください"
            style={{ pointerEvents: "none" }}
          >
            {districtOpt &&
              districtOpt.map((item: any, index: any) => (
                <option value={item.value} key={index}>
                  {" "}
                  {item.content}
                </option>
              ))}
          </StyleSelect>
        </Form.Item>

        <Form.Item
          label="市区町村"
          name="city"
          rules={[{ required: true, message: MESSAGES.requireField }]}
        >
          <StyleSelect
            oonChange={() => {
              form.setFieldValue("street", undefined);
            }}
            disabled={!district}
            style={{ pointerEvents: "none" }}
            placeholder="選択してください"
          >
            {cityOpt &&
              cityOpt.map((item: any, index: any) => (
                <option value={item.value} key={index}>
                  {" "}
                  {item.content}
                </option>
              ))}
          </StyleSelect>
        </Form.Item>

        <Form.Item
          label="町名・番地"
          name="street"
          rules={[{ required: true, message: MESSAGES.requireField }]}
        >
          <StyleSelect
            style={{ pointerEvents: "none" }}
            onChange={(e) => getZipCodeByAddress(e.target.value)}
            disabled={!district || !city}
            placeholder="選択してください"
          >
            {addrOpt &&
              addrOpt.map((item: any, index: any) => (
                <option value={item.value} key={index}>
                  {" "}
                  {item.content}
                </option>
              ))}
          </StyleSelect>
        </Form.Item>
        <Form.Item
          label="ビル・マンション名"
          name="building_name"
          rules={[
            { max: 255 },
            // { required: true, message: MESSAGES.requireField },
            // {
            //   pattern: REGEX.atLeastOneNonSpaceCharacter,
            //   message: MESSAGES.requireField,
            // },
            {
              pattern: REGEX.onlyTextAndNumber,
              message: MESSAGES.invalidCharacter,
            },
          ]}
        >
          <InputCustom isReadOnly />
        </Form.Item>

        <Form.Item
          label="携帯番号"
          name="cellphone_number"
          rules={[
            {
              min: 10,
              max: 20,
              message: MESSAGES.min10max20number,
            },
            { required: true, message: MESSAGES.requireField },
          ]}
        >
          <NumericInput readOnly maxLength={20} />
        </Form.Item>
        <Form.Item
          label="電話番号"
          name="telephone_number"
          rules={[
            {
              min: 10,
              max: 20,
              message: MESSAGES.min10max20number,
            },
          ]}
        >
          <NumericInput readOnly maxLength={20} />
        </Form.Item>

        <Form.List name="contact_relatives">
          {(fields) =>
            fields.map((field) => (
              <React.Fragment key={field.key}>
                <p className="text-[#000000] text-[16px] leading-[27px] border-b border-solid border-[#000000] w-[101%] mb-4">
                  ■緊急連絡先
                </p>
                <Form.Item
                  label="氏名"
                  name={[field.name, "name"]}
                  rules={[
                    { max: 50 },
                    {
                      pattern: REGEX.onlyText,
                      message: MESSAGES.onlyText,
                    },
                  ]}
                >
                  <InputCustom isReadOnly name="name" />
                </Form.Item>
                <Form.Item
                  label="続柄"
                  name={[field.name, "relationship"]}
                  rules={[
                    { max: 255 },
                    {
                      pattern: REGEX.onlyText,
                      message: MESSAGES.onlyText,
                    },
                  ]}
                >
                  <InputCustom isReadOnly />
                </Form.Item>
                <Form.Item
                  label="住所"
                  name={[field.name, "address"]}
                  rules={[
                    { max: 255 },
                    {
                      pattern: REGEX.address,
                      message: MESSAGES.invalidCharacter,
                    },
                  ]}
                >
                  <InputCustom isReadOnly />
                </Form.Item>
                <Form.Item
                  label="携帯番号"
                  name={[field.name, "cellphone_number"]}
                  rules={[
                    {
                      min: 10,
                      max: 20,
                      message: MESSAGES.min10max20number,
                    },
                    // { required: true, message: MESSAGES.requireField },
                  ]}
                >
                  <NumericInput readOnly maxLength={20} />
                </Form.Item>
                <Form.Item
                  label="電話番号"
                  name={[field.name, "telephone_number"]}
                  rules={[
                    {
                      min: 10,
                      max: 20,
                      message: MESSAGES.min10max20number,
                    },
                    // { required: true, message: MESSAGES.requireField },
                  ]}
                >
                  <NumericInput readOnly maxLength={20} />
                </Form.Item>
              </React.Fragment>
            ))
          }
        </Form.List>
        <Form.Item label="利用状況" name="status" initialValue="using">
          <RadioGroup colorScheme="white">
            <Radio
              _before={styleRadioProps}
              mr="7px"
              isReadOnly
              borderColor={`${
                value === "using" ? "var(--header-color)" : "#000"
              }`}
              value="using"
            >
              利用中
            </Radio>
            <Radio
              _before={styleRadioProps}
              mr="7px"
              isReadOnly
              borderColor={`${
                value === "pause" ? "var(--header-color)" : "#000"
              }`}
              value="pause"
            >
              一時停止
            </Radio>
            <Radio
              _before={styleRadioProps}
              mr="7px"
              isReadOnly
              borderColor={`${
                value === "suspended" ? "var(--header-color)" : "#000"
              }`}
              value="suspended"
            >
              利用停止
            </Radio>
          </RadioGroup>
        </Form.Item>
        <Form.Item label="契約日" name="period_contract" initialValue={dayjs()}>
          <StyleDatePicker
            placeholder="日付を選択"
            format={JP_DATEPICKER_FORMAT}
            allowClear={false}
            className="w-full"
            style={{ width: "100%" }}
          />
        </Form.Item>
      </div>
      <div className="w-1/2 pl-5">
        <StyleTitle style={{ margin: "0pxuseService" }}>■病院</StyleTitle>
        <Form.Item label="病院名" name={["hospital", "name"]}>
          <InputCustom isReadOnly />
        </Form.Item>
        <Form.Item label="診療科" name={["hospital", "clinical_department"]}>
          <InputCustom isReadOnly />
        </Form.Item>
        <Form.Item
          label="電話番号"
          name={["hospital", "phone"]}
          rules={[
            {
              min: 10,
              max: 20,
              message: MESSAGES.min10max20number,
            },
            // { required: true, message: MESSAGES.requireField },
          ]}
        >
          <NumericInput readOnly maxLength={20} />
        </Form.Item>
        <Form.Item label="住所" name={["hospital", "address"]}>
          <InputCustom isReadOnly autoComplete="new-password" />
        </Form.Item>
        <Form.Item label="現病・既往歴" name="current_illness_history">
          <InputCustom isReadOnly />
        </Form.Item>
        <Form.Item label="ご家族聞き取り" name="family_interview">
          <InputCustom isReadOnly />
        </Form.Item>
        <Form.Item label="ご本人聞き取り" name="personal_interview">
          <InputCustom isReadOnly />
        </Form.Item>
      </div>
    </FlexRow>
  );
};

const TextP = styled(Text)({
  fontSize: "12px",
  marginTop: "12px",
});

const InputCustom = styled(Input)({
  fontSize: "12px",
  border: "1px solid #000",
  borderRadius: "0px",
});

export default BasicInfomationForm;
