import styled from "@emotion/styled";
import { Tabs, TabsProps } from "antd";
import { ButtonSolid } from "component/button";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import noticeSlice from "store/slice/noticeSlice";
import PageHeaderTitle from "component/PageHeaderTitle";
import { Box } from "@chakra-ui/react";
import { ItemType } from "antd/es/breadcrumb/Breadcrumb";
import Important from "./Important";
import Conveyed from "./Conveyed";

const StyledBoardTabs = styled(Tabs)`
  .ant-tabs-nav {
    padding: 0;

    @media (max-width: 576px) {
      padding-bottom: 40px;

      .ant-tabs-tab {
        width: calc(50vw - 20px);

        .ant-tabs-tab-btn {
          text-align: center;
          width: 100%;

          &:after {
            content: none;
          }
        }

        &.ant-tabs-tab-active {
          .ant-tabs-tab-btn {
            color: #4759ff !important;
          }
        }
      }

      .ant-tabs-ink-bar {
        background-color: #4759ff;
      }
    }

    &:before {
      content: none;
    }

    @media (min-width: 576px) {
      width: 80%;

      .ant-tabs-tab {
        border: 1px solid #00000026;
        border-radius: 15px;
        .ant-tabs-tab-btn {
          color: #0000008c;
          &:after {
            content: none;
            background: none;
          }
        }

        &.ant-tabs-tab-active {
          border: 1px solid #3492d7;
          background: #cae9ff;
          .ant-tabs-tab-btn {
            color: #0b3f65 !important;
          }
        }
      }

      .ant-tabs-ink-bar {
        display: none;
      }
    }
  }
  .ant-tabs-content-holder {
  }
`;

const breadCrumbItems: ItemType[] = [
  {
    title: "ホーム",
  },
  {
    title: "お知らせ",
  },
];

const tabs: TabsProps["items"] = [
  {
    key: "1",
    label: "重要事項",
    children: <Important />,
  },
  {
    key: "2",
    label: "申し送り事項",
    children: <Conveyed />,
  },
];

export const PostList: React.FC = () => {
  const { boardInnerTab } = useSelector((state: RootState) => state.notice);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleChangeTab: TabsProps["onChange"] = (tabKey) => {
    dispatch(noticeSlice.actions.setBoardInnerTab(tabKey));
  };

  return (
    <Box>
      <PageHeaderTitle
        title="お知らせ"
        className="hidden lg:py-4 px-6 bg-white font-bold lg:block"
       // breadCrumbItems={breadCrumbItems}
      />

      <div className="bg-[#EEEEEE] md:px-[20px] md:py-[12px]">
        <div className="bg-white px-[12px] py-[18px] max-md:pb-[100px] relative">
          <ButtonSolid
            className="!absolute max-md:top-[80px] top-[18px] right-[12px] max-md:w-[calc(100%-24px)] w-[135px] z-50"
            onClick={() =>
              navigate(`/staff/bulletin-board/create?tab=${boardInnerTab}`)
            }
          >
            親スレッド投稿
          </ButtonSolid>
          <StyledBoardTabs
            activeKey={boardInnerTab}
            items={tabs}
            onChange={handleChangeTab}
          />
        </div>
      </div>
    </Box>
  );
};
