import { HomeFilled, ClockCircleOutlined } from "@ant-design/icons";
import MenuItem from "./menuItem";
import ROUTES from "constant/routes";
import { useLocation } from "react-router-dom";
import Logo from "assets/logo_without_text.png";
import { FlexRow } from "component/StyledComponent";
import {SettingClock} from "assets/icons";

export interface ISidebarProps {
  className?: string;
}

export default function SidebarAdmin({ className = "" }: ISidebarProps) {
  const location = useLocation();

  const menuItems = [
    {
      path: `/${ROUTES.ADMIN}`,
      name: "ダッシュボード",
      icon: <HomeFilled />,
      matchAll: true,
    },
    {
      path: `/${ROUTES.ADMIN}/${ROUTES.STAFF}`,
      name: "介護士スタッフ",
      icon: <img src="/images/icon-user.svg" alt="icon-user" />,
      matchAll: false,
    },
    {
      path: `/${ROUTES.ADMIN}/${ROUTES.CUSTOMER}`,
      name: "お客様一覧",
      icon: <img src="/images/icon-user.svg" alt="icon-user" />,
      matchAll: false,
    },
    {
      path: `/${ROUTES.ADMIN}/${ROUTES.SHIFT}/${ROUTES.SHIFT_MANAGEMENT}`,
      name: "介護記録登録",
      icon: <ClockCircleOutlined  />,
      matchAll: false,
    },
    {
      path: `/${ROUTES.ADMIN}/${ROUTES.SHIFT_MANAGEMENT_STAFF}`,
      name: "シフト管理",
       icon: <img src="/images/setting-clock.svg" alt="icon-user" width={19} height={19} />,
      matchAll: false,
    },
    {
      path: `/${ROUTES.ADMIN}/${ROUTES.NURSING_CARE}`,
      name: "介護記録・履歴",
      icon: <img src="/images/icon-file.svg" alt="icon-file" />,
      matchAll: false,
    },
    {
      path: `/${ROUTES.ADMIN}/${ROUTES.PAYMENT}`,
      name: "請求・入金履歴",
      icon: <img src="/images/icon-youtube.svg" alt="icon-youtube" />,
      matchAll: false,
    },
    {
      path: `/${ROUTES.ADMIN}/${ROUTES.NOTICE}`,
      name: "お知らせ",
      icon: <img src="/images/icon-comment.svg" alt="icon-comment" />,
      matchAll: false,
    },
    {
      path: "#",
      name: "管理メニュー",
      icon: <img src="/images/icon-setting.svg" alt="icon-setting" />,
      isSub: true,
      matchAll: true,
      childrens: [
        {
          path: `/${ROUTES.ADMIN}/${ROUTES.MENU_PROFILE}`,
          name: "プロフィール",
          matchAll: false,
        },
        {
          path: `/${ROUTES.ADMIN}/${ROUTES.CARE_SERVICE}`,
          name: "ケアマネプラン登録",
          matchAll: false,
        },
        {
          path: `/${ROUTES.ADMIN}/${ROUTES.FACILITY_REGISTRATION_LIST}`,
          name: "備品・サービス登録",
          matchAll: false,
        },
      ],
    }
  ];

  return (
    <div
      className={`py-[15px] bg-[#FFFFFF] ${className} overflow-auto shadow-pro`}
    >
      <div className="px-[18px] pb-[18px]">
        {/* <p className="bg-[#EEEEEE] text-[#000000] px-[81px] py-[26px] mb-[23px] font-bold">
          ロゴ
        </p> */}
        <FlexRow style={{ justifyContent: "center" }}>
          <img src={Logo} alt="" />
        </FlexRow>
      </div>
      {menuItems?.map((menu, index) => (
        <MenuItem
          key={index}
          item={menu}
          isActive={
            menu.matchAll
              ? location.pathname === menu.path
              : location.pathname.includes(menu.path)
          }
        />
      ))}
    </div>
  );
}
