// page 61, 62 https://xd.adobe.com/view/ef189247-c8fd-4c31-b643-d4151912ea88-ba4b/screen/a183d692-ba46-49d3-a127-507d4b24666b/specs/
import { Form, Input } from "antd";
import axiosClient from "api/axiosClient";
import NumericInput from "component/NumberInput";
import { ButtonOutline, ButtonSolid } from "component/button";
import { MONTH_FORMAT } from "constant/date";
import { IPatient } from "constant/interfaces";
import MESSAGES from "constant/messages";
import REGEX from "constant/regex";
import ROUTES from "constant/routes";
import dayjs from "dayjs";
import useService from "hook/useService";
import React, { useState } from "react";
import { NumericFormat } from "react-number-format";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

export type TPatientType = "nursing_care" | "disability" | undefined;

export default function CareplanBilling() {
  const [form] = Form.useForm();
  const service = useService();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const month = dayjs(searchParams.get("month") || Date.now());
  const patient_id = searchParams.get("patient_id");
  const [patientData, setPatientData] = useState<IPatient>();
  const [submitting, setSubmitting] = useState(false)

  const patientType: TPatientType = React.useMemo(() => {
    switch (searchParams.get("type") as TPatientType) {
      case "disability":
        return "disability";
      default:
        return "nursing_care";
    }
  }, [searchParams]);
  const fetchInitialData = async () => {
    const res = await axiosClient({
      url: service.USAGE_BILLINGS,
      params: {
        patient_id,
        year_month: month.format("YYYY/MM"),
        invoice_type: patientType,
      },
    });
    setPatientData(res.data.patient);
    Object.keys(res.data)
      .filter((key) => res.data[key])
      .forEach((key) => {
        form.setFieldValue(key, res.data[key]);
      });
  };
  const handleSubmit = async (formData: any) => {
    try {
      setSubmitting(true)
      const res = await axiosClient({
        url: service.USAGE_BILLINGS + (formData.id ? `/${formData.id}` : ""),
        method: formData.id ? "put" : "post",
        data: {
          patient_id,
          invoice_type: patientType,
          year_month: month.format("YYYY/MM"),
          ...formData,
        },
      });
      toast.success(res.data.success);
      navigate(`/${ROUTES.ADMIN}/${ROUTES.PAYMENT}`);
    } catch (error) {
      toast.error((error as string).toString());
    } finally {
      setSubmitting(false)
    }
  };

  React.useEffect(() => {
    fetchInitialData();
  }, []);

  return (
    <Form form={form} onFinish={handleSubmit}>
      <div className="page-title">
        {patientType === "nursing_care"
          ? "利用請求書(介護)"
          : "利用請求書(障害)"}
        &nbsp;
        {month.format(MONTH_FORMAT)} {patientData?.family_name || ""} 様
      </div>
      <div className="page-container">
        <Form.Item name="id" hidden>
          <div />
        </Form.Item>
        <div className="max-w-[700px]">
          <Form.Item
            name="subsidy_amount"
            label="助成負担額"
            rules={[
              { required: true, message: MESSAGES.requireField },
              {
                pattern: REGEX.atLeastOneNonSpaceCharacter,
                message: MESSAGES.requireField,
              },
            ]}
            initialValue="0"
          >
            <NumericFormat
              allowNegative={false}
              decimalSeparator=""
              customInput={Input}
              addonAfter="円"
            />
          </Form.Item>
          <Form.Item
            name="exceeding_amount"
            label="限度額超過負担分"
            rules={[
              { required: true, message: MESSAGES.requireField },
              {
                pattern: REGEX.atLeastOneNonSpaceCharacter,
                message: MESSAGES.requireField,
              },
            ]}
            initialValue="0"
          >
            <NumericFormat
              allowNegative={false}
              decimalSeparator=""
              customInput={Input}
              addonAfter="円"
            />
          </Form.Item>
          <Form.Item
            name="other_charge"
            label="その他利用者負担額"
            rules={[
              { required: true, message: MESSAGES.requireField },
              {
                pattern: REGEX.atLeastOneNonSpaceCharacter,
                message: MESSAGES.requireField,
              },
            ]}
            initialValue="0"
          >
            <NumericFormat
              allowNegative={false}
              decimalSeparator=""
              customInput={Input}
              addonAfter="円"
            />
          </Form.Item>
          <Form.Item
            name="billing_amount"
            label="請求済金額"
            rules={[
              { required: true, message: MESSAGES.requireField },
              {
                pattern: REGEX.atLeastOneNonSpaceCharacter,
                message: MESSAGES.requireField,
              },
            ]}
            initialValue="0"
          >
            <NumericFormat
              allowNegative={false}
              decimalSeparator=""
              customInput={Input}
              addonAfter="円"
            />
          </Form.Item>
          <Form.Item
            name="additional"
            label={
              patientType === "nursing_care"
                ? "地域区分(介護)"
                : "地域区分(障害)"
            }
            rules={[
              { required: true, message: MESSAGES.requireField },
              {
                pattern: REGEX.atLeastOneNonSpaceCharacter,
                message: MESSAGES.requireField,
              },
            ]}
            initialValue={patientType === "nursing_care" ? 11.05 : 10.9}
          >
            <NumericFormat
              allowNegative={false}
              customInput={Input}
              addonAfter="円"
            />
          </Form.Item>
        </div>
        <div className="flex justify-end gap-3 mt-10">
          <ButtonOutline onClick={() => navigate(-1)}>キャンセル</ButtonOutline>
          <ButtonSolid w={137} onClick={form.submit} isLoading={submitting}>
            保存
          </ButtonSolid>
        </div>
      </div>
    </Form>
  );
}
