import dayjs, { Dayjs } from "dayjs";
import SearchForm from "./components/SearchForm";
import PrintPdfNursingCareRecord from "./components/PrintPdfNursingCareRecord";
import Styles from "component/admin/table/styled";
import usePagination from "hook/usePagination";
import usePaginationSettings from "hook/usePaginationSettings";
import { createSearchParams, useNavigate } from "react-router-dom";
import { DATE_FORMAT, MONTH_FORMAT } from "constant/date";
import { Nurse, NursingCareHistoryI } from "types/admin";
import { Table } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import MonthChanger, { CURRENT_DATE_KEY } from "component/MonthChanger";
import { useQuery } from "react-query";
import apiFactory from "api";
import { ButtonSolid } from "component/button";
import ROUTES from "constant/routes";
import { MappingNursingStatus } from "enums/nursingCare";
import { SorterResult } from "antd/es/table/interface";
import { isNumber } from "lodash";
function ShiftManagement() {
  const [searchParams] = useSearchParams();
  const [currentDate, setCurrentDate] = useState<Dayjs>(dayjs());
  const navigate = useNavigate();
  const { page, setPage, limit, setLimit } = usePagination();
  const [sorter, setSorter] = useState<SorterResult<NursingCareHistoryI>>();
  const [queryParams, setQueryParams] =
    useState<Pick<NursingCareHistoryI, "family_name" | "address" | "status">>();
  const [idNursePrint, setIdNursePrint] = useState<number | null>(null);
  const [isPrinting, setIsPrinting] = useState(false);
  const { data, isLoading } = useQuery(
    ["nursing-care-history", page, limit, queryParams, currentDate, sorter],
    () =>
      apiFactory.adminNursingCareRecordApi.getNursingCareHistoryChanges({
        year_month: currentDate.format(MONTH_FORMAT),
        page,
        per: limit,
        ...queryParams,
        name: queryParams?.family_name,
        sort_key: sorter?.field,
        order: sorter?.order?.replaceAll("end", ""),
      })
  );

  const handlePrePrint = (id: number) => {
    setIsPrinting(true);
    setIdNursePrint(id);
  };

  const paginationSettings = usePaginationSettings({
    limit,
    page,
    setLimit,
    setPage,
    total: data?.total_items || 0,
  });

  const nursingHistories = useMemo(() => {
    return data?.serialized_nursing_care_histories?.map(
      (item: { nurse: Nurse } & NursingCareHistoryI) => {
        const district = !!item?.nurse.district
          ? `${item?.nurse.district}, `
          : "";
        const city = !!item?.nurse.city ? `${item?.nurse.city}, ` : "";
        const street =
          item?.nurse.street +
          (!!item?.nurse.street && !!item?.nurse?.building_name ? ", " : "");
        const buildingName = item?.nurse.building_name ?? "";

        return {
          address: `${district}${city}${street}${buildingName}`,
          ...item?.nurse,
          family_name: item?.nurse?.family_name,
        };
      }
    );
  }, [data]);

  const columns = useMemo(() => {
    return [
      {
        title: "ID",
        dataIndex: "id",
        key: "id",
      },
      {
        title: "氏名",
        dataIndex: "family_name",
        key: "family_name",
        sorter: true,
      },
      {
        title: "氏名（カナ)",
        dataIndex: "name_kana",
        key: "name_kana",
        sorter: true,
      },
      {
        title: "携帯番号1",
        dataIndex: "telephone_number",
        key: "telephone_number",
        sorter: true,
      },
      {
        title: "郵便番号",
        dataIndex: "zipcode",
        key: "zipcode",
        sorter: true,
      },
      {
        title: "住所",
        dataIndex: "address",
        key: "address",
      },
      {
        title: "在籍状況",
        dataIndex: "status",
        key: "status",
        render: (_: string, record: NursingCareHistoryI) => {
          return MappingNursingStatus[
            record?.status as keyof typeof MappingNursingStatus
          ];
        },
      },
      {
        title: "操作",
        dataIndex: "detail",
        key: "detail",
        render: (_: string, record: NursingCareHistoryI) => {
          return (
            <div>
              <ButtonSolid
                h="26px"
                fontSize="14px"
                w="46px"
                onClick={routeToNursingCareDetail(record)}
              >
                詳細
              </ButtonSolid>
              <ButtonSolid
                className="ml-2"
                h="26px"
                fontSize="14px"
                w="46px"
                isLoading={record.id === idNursePrint && isPrinting}
                onClick={() => {
                  handlePrePrint(record.id);
                }}
              >
                出力
              </ButtonSolid>
            </div>
          );
        },
      },
    ];
  }, [isPrinting, idNursePrint, currentDate]);

  const routeToNursingCareDetail = (record: NursingCareHistoryI) => () => {
    const currentDateFormat = dayjs(currentDate).format(MONTH_FORMAT)
    navigate({
      pathname: `/${ROUTES.ADMIN}/${ROUTES.NURSING_CARE}/${record?.id}`,
      search: createSearchParams({
        nursing_name: record?.family_name,
        currentDate: currentDateFormat
      }).toString(),
    });
  };

  const handleSearch = (
    searchData: Pick<NursingCareHistoryI, "family_name" | "address" | "status">
  ) => {
    setQueryParams(searchData);
  };

  const handleChangeCurrentDate = (date: Dayjs) => {
    setCurrentDate(date);
    navigate({
      pathname: window.location.pathname,
      search: createSearchParams({
        currentDate: date.format(DATE_FORMAT),
      }).toString(),
    });
  };

  const handleSort = (sorter: SorterResult<NursingCareHistoryI>) => {
    setSorter(sorter);
  };

  useEffect(() => {
    const newDate = dayjs(searchParams.get(CURRENT_DATE_KEY)).isValid()
      ? dayjs(searchParams.get(CURRENT_DATE_KEY))
      : dayjs();
    setCurrentDate(newDate);
  }, [searchParams.get(CURRENT_DATE_KEY)]);

  return (
    <Styles>
      {isNumber(idNursePrint) && (
        <PrintPdfNursingCareRecord
          setIdNursePrint={setIdNursePrint}
          id={idNursePrint}
          setIsPrinting={setIsPrinting}
          currentDate={currentDate}
        />
      )}
      <p className="mb-[22px] mt-[10px]">介護記録・履歴</p>

      <div className="bg-white px-[10px] py-[20px]">
        <MonthChanger onChange={handleChangeCurrentDate} />

        <div className="mt-[25px]">
          <SearchForm onSearch={handleSearch} />
        </div>

        <Table
          dataSource={nursingHistories}
          columns={columns}
          pagination={paginationSettings}
          loading={isLoading}
          scroll={{
            x: 800,
          }}
          onChange={(_, __, sorter) =>
            handleSort(sorter as SorterResult<NursingCareHistoryI>)
          }
        />
      </div>
    </Styles>
  );
}

export default ShiftManagement;
