import BaseApi from "api/baseApi";
import { objectToFormData } from "utils/data";
import { STAFF_SHIFT_MANAGEMENTS } from "../apiConstant";
import dayjs from "dayjs";

class ShiftManagementStaffApi extends BaseApi {
  getList<T>(params: { year_month: string }) {
    return this.get<T>(STAFF_SHIFT_MANAGEMENTS, params);
  }

  createOrUpdate<T>(data: any) {
    return this.post<T>(STAFF_SHIFT_MANAGEMENTS, data);
  }
}

export default ShiftManagementStaffApi;
