import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { DatePicker, DatePickerProps, Form, Input, Select } from "antd";
import {
  DATE_FORMAT,
  DATE_FORMAT_2,
  JP_DATEPICKER_FORMAT,
} from "constant/date";
import MESSAGES from "constant/messages";
import { FlexRow } from "component/StyledComponent";
import { ButtonOutline, ButtonSolid } from "component/button";
import { ITabData } from "..";
import REGEX from "constant/regex";
import useService from "hook/useService";
import axiosClient from "api/axiosClient";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
interface IProps {
  onChangeTab: (data: ITabData) => void;
  isActive?: boolean;
  record?: any;
}
const DepositDetail = ({ onChangeTab, record }: IProps) => {
  const [form] = Form.useForm();
  const onFinishFailed = (errorInfo: any) => {};
  const service = useService();
  const location = useLocation();
  const navigate = useNavigate();
  const [submitting, setSubmitting] = useState(false)

  const handleSubmit = async (formData: any) => {
    try {
      setSubmitting(true)
      const res: any = await axiosClient({
        url: service.PAYMENT_HISTRORY,
        method: record?.payment_history?.status === "入金完了" ? "put" : "post",
        data: {
          patient_id: record?.id,
          year_month: record?.month.format("YYYY/MM"),
          expected_date: formData.expected_date.format(DATE_FORMAT_2),
          actual_date: formData.actual_date.format(DATE_FORMAT_2),
        },
      });
      if (res) {
        toast.success(res?.data?.success);
        onChangeTab({ activeKey: "list" });
      }
    } catch (error: any) {
      toast.error(error.toString());
      console.log("error message", error);
    } finally {
      setSubmitting(false)
    }
  };

  const fetchData = async () => {
    try {
      const { data } = await axiosClient({
        url: service.PAYMENT_HISTRORY,
        method: "get",
        params: {
          ...form.getFieldsValue(),
          patient_id: record?.id,
          year_month: record.month.format("YYYY/MM"),
        },
      });
      form.setFieldValue("expected_date", dayjs(data?.expected_date));
      form.setFieldValue("actual_date", dayjs(data?.actual_date));
    } catch (error) {
      console.error(error);
    }
  };

  window.onpopstate = () => {
    navigate(location.pathname);
    onChangeTab({ activeKey: "list" });
  };

  useEffect(() => {
    form.resetFields();
    if (record) {
      if (record?.payment_history?.status === "入金完了") {
        fetchData();
      } else {
        form.setFieldValue(
          "expected_date",
          dayjs(record?.payment_history?.expected_date)
        );
      }
      form.setFieldValue("family_name", record?.family_name);
    }
  }, [record]);
  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <div className="max-w-[700px]">
        <Form.Item
          label="お客様名"
          name="family_name"
          // rules={[{ required: true, message: MESSAGES.requireField }]}
        >
          <Input disabled />
        </Form.Item>

        <Form.Item
          label="入金予定日"
          name="expected_date"
          rules={[
            { required: true, message: MESSAGES.requireField },
            {
              pattern: REGEX.atLeastOneNonSpaceCharacter,
              message: MESSAGES.requireField,
            },
          ]}
        >
          <DatePicker
            placeholder="選択してください"
            // name="date_payment"
            format={JP_DATEPICKER_FORMAT}
            style={{ width: "100%" }}
          />
        </Form.Item>

        <Form.Item
          label="入金日"
          name="actual_date"
          rules={[
            { required: true, message: MESSAGES.requireField },
            {
              pattern: REGEX.atLeastOneNonSpaceCharacter,
              message: MESSAGES.requireField,
            },
          ]}
        >
          <DatePicker
            placeholder="選択してください"
            // name="???"
            format={JP_DATEPICKER_FORMAT}
            style={{ width: "100%" }}
          />
        </Form.Item>
      </div>

      <FlexRow className="justify-end gap-2">
        <ButtonOutline
          width="92px"
          onClick={() => onChangeTab({ activeKey: "list" })}
        >
          キャンセル
        </ButtonOutline>
        <ButtonSolid onClick={form.submit} width="125px" isLoading={submitting}>
          登録
        </ButtonSolid>
      </FlexRow>
    </Form>
  );
};

export default DepositDetail;
