import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { ButtonOutline } from "component/button";
import { Spin } from "antd";
import apiFactory from "api";
import { toast } from "react-toastify";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "store";
import "./style.scss";
import MessageDate from "./MessageDate";
import Message from "./Message";
import ChatBox from "./ChatBox";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from "constant/apiRequest";

const ContactChat = () => {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const [prevScrollHeight, setPrevScrollHeight] = useState(0);
  const [firstTime, setFirstTime] = useState(false);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const ref = useRef<HTMLDivElement>(null);
  const [limit, setLimit] = useState(DEFAULT_PER_PAGE);
  const [page, setPage] = useState(DEFAULT_PAGE);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [content, setContent] = useState("");
  const [messageList, setMessageList] = useState<any>([]);
  const { id: userId } = useSelector((state: RootState) => state.user);

  const renderListMessage = () => {
    return messageList.map((e: any) => {
      const messageProps = {
        key: e?.id,
        content: e?.content,
        hourAt: e?.hour_at,
        isUnread: e?.is_unread,
        readAt: e?.read_at,
      };

      if (e.loading) {
        return (
          <div className="text-center mb-[15px]" key={e.id}>
            <Spin />
          </div>
        );
      }

      if (e?.date !== date) {
        date = e?.date;

        if (e.sender_id !== userId) {
          return <Message isGuest={true} {...messageProps} />;
        }

        return (
          <div key={e.id}>
            <MessageDate date={e?.date} />
            <Message isGuest={false} {...messageProps} />
          </div>
        );
      }

      if (e.sender_id !== userId) {
        return <Message isGuest={true} {...messageProps} />;
      }

      return <Message isGuest={false} {...messageProps} />;
    });
  };

  const fetchData = async () => {
    const data = await apiFactory.staffContactApi.getContactDetail({
      id: Number(params.id),
      per: limit,
      page: page,
    });
    if (data) {
      setMessageList(
        data?.messages.map((e: any) => ({
          ...e,
          date:
            e?.time_send_at.split(" ")[0] + " " + e?.time_send_at.split(" ")[2],
        }))
      );
      setTotalItems(data?.total_items);
      setFirstTime(true);
    }
  };

  const sendMessage = async (setSubmitting: Dispatch<SetStateAction<boolean>>) => {
    if (content.trim()) {
      setSubmitting(true)
      const data = await apiFactory.staffContactApi.createMessage({
        id: Number(params.id),
        content: content,
      });
      if (data) {
        const data = await apiFactory.staffContactApi.getContactDetail({
          id: Number(params.id),
          per: limit,
          page: 1,
        });
        if (data) {
          setMessageList(
            data?.messages.map((e: any) => ({
              ...e,
              date:
                e?.time_send_at.split(" ")[0] +
                " " +
                e?.time_send_at.split(" ")[2],
            }))
          );
          setTotalItems(data?.total_items);
          setPage(DEFAULT_PAGE);
          setFirstTime(true);
          messagesEndRef?.current?.scrollIntoView({ block: "end" });
        }
        setContent("");
      } else {
        toast.error("Can not send message!");
      }
      setSubmitting(false)
    }
  };

  let date = "";

  const handleScroll = async (event: React.UIEvent<HTMLElement>) => {
    if (event.currentTarget.scrollTop === 0 && page * limit < totalItems) {
      messageList.unshift({
        loading: true,
      });
      setMessageList([...messageList]);
      const data = await apiFactory.staffContactApi.getContactDetail({
        id: Number(params.id),
        per: limit,
        page: page + 1,
      });
      if (data) {
        setTimeout(() => {
          messageList.shift();
          const concat = data?.messages
            .map((e: any) => ({
              ...e,
              date:
                e?.time_send_at.split(" ")[0] +
                " " +
                e?.time_send_at.split(" ")[2],
            }))
            .concat(messageList);
          setMessageList(concat);
          setTotalItems(data?.total_items);
          setPage(page + 1);
          setFirstTime(false);
        }, 500);
      }
    }
  };

  const handleBackToContactList = () => {
    navigate("/staff/contact-list");
  };

  useEffect(() => {
    fetchData();
    apiFactory.staffContactApi.readMessage(Number(params.id));
  }, []);

  useEffect(() => {
    if (firstTime) {
      messagesEndRef?.current?.scrollIntoView({ block: "end" });
      if (ref?.current?.scrollHeight) {
        setPrevScrollHeight(ref?.current?.scrollHeight);
      }
    }
  }, [firstTime]);

  useEffect(() => {
    if (!firstTime) {
      const newScrollHeight = ref?.current?.scrollHeight;
      if (newScrollHeight) {
        ref.current?.scrollTo(0, newScrollHeight - prevScrollHeight);
        setPrevScrollHeight(newScrollHeight);
      }
    }
  }, [page]);

  return (
    <div className="md:py-3 md:px-5 page-header-bg after:h-[60px]">
      <div className="md:mb-8 max-md:ml-[16px] max-md:mt-[16px]">
        <ArrowLeftOutlined
          className="mr-[10px] text-[#A2A2A2] md:hidden"
          onClick={handleBackToContactList}
        />
        {location?.state?.title}
      </div>

      <div className="bg-white max-md:h-[calc(100vh-142px)] text-left md:p-[20px]">
        <div className="text-end mb-[30px] max-md:hidden">
          <ButtonOutline
            className="!h-[35px] !px-[16px] !text-[14px] !leading-[24px]"
            onClick={handleBackToContactList}
            leftIcon={<ArrowLeftOutlined />}
          >
            一覧へ戻る
          </ButtonOutline>
        </div>

        <div
          className="overflow-y-auto relative max-md:h-[calc(100vh-212px)] pt-[20px] h-[calc(100vh-340px)] md:max-w-[70%] md:px-[20px] md:pb-[30px] mx-auto"
          ref={ref}
          onScroll={handleScroll}
        >
          {renderListMessage()}
          <div ref={messagesEndRef} />
        </div>

        <ChatBox
          onSendMessge={sendMessage}
          content={content}
          setContent={setContent}
        />
      </div>
    </div>
  );
};
export { ContactChat };
