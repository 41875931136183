import { InputNumber, InputNumberProps } from "antd";
import styled from "@emotion/styled";

const Styled = styled.div`
  .ant-input-number {
    border-color: unset;
    &.ant-input-number-disabled {
      border-color: #d9d9d9;
      .ant-input-number-input-wrap {
        input {
          opacity: 1 
        }
      }
    }
  }
`;

interface IProps extends InputNumberProps {
  onChange?: (value: any) => void;
}

const NumberInputFullWidth = (props: IProps) => {
  // Formatter: Adds dot as thousand separator and comma as decimal separator
  const formatterNumber = (val: any) => {
    if (!val) return "";
    return `${val}`;
  };

  // Handle key press to prevent "e", "+", and "-"
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      event.key === "e" ||
      event.key === "E" ||
      event.key === "+" ||
      event.key === "-"
    ) {
      event.preventDefault();
    }
  };

  return (
    <Styled>
      <InputNumber
        type="number"
        {...props}
        className={`${props.className} disabled:!border-[#d9d9d9]`}
        formatter={formatterNumber}
        onKeyDown={handleKeyDown}
      />
    </Styled>
  );
};

export default NumberInputFullWidth;
